/* eslint-disable no-await-in-loop */
import { Box, Button, Skeleton, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { BigNumber, ethers } from 'ethers';
import fromExponential from 'from-exponential';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { BoosterToken, ERC20Contract, LPTokenContract, MutationABI } from '../../abi';
import nftRarities from '../../data/NFTRarities';
import getFarmPrice from '../../helpers/getFarmPrice';
import ZbetMasterChefHelper from '../../helpers/ZbetMasterChefHelper';
import { useWeb3Context } from '../../hooks';
import { useAsyncRender } from '../../hooks/useApi';
import getApiCanceller from '../../utils/ApiCanceller';
import { convertFromWei } from '../../utils/convert-wei';
import { toFixed2 } from '../../utils/tofixed4';
import NotistakeLoaderButton from '../NotistakeLoaderButton';

import type { MoralisNFTModel } from '../../utils/moralis';
import { getAddresses, Networks } from '../../constants';
import Countdown from 'react-countdown';
import { FaExclamationTriangle } from 'react-icons/fa';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src="./img/close_circle_ppup.svg" alt="" className="close_crcl_img_ppup" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface StakePopUpModelProps {
  tokenAddress;
  userStaked;
  onStake;
  onUnstake;
  onUnstakeNFT;
  contractAddress;
  boosterTokenAddress;
  tokenPrice;
  farmName;
  farmImage;
  rewardToken;
  nftDetailsAPI;
  aprAPI;
  isLP;
  farmData;
  minimumStake?;
  stakingFee?;
  depositLockingPeriod?;
  harvestLockingPeriod?;
  unlockTime?;
  emergencyWithdrawFee?;
  onUnstakeEmergency?;
}

const StakePopUpModel = (
  {
    tokenAddress,
    userStaked,
    onStake,
    onUnstake,
    onUnstakeNFT,
    contractAddress,
    boosterTokenAddress,
    tokenPrice,
    farmName,
    farmImage,
    rewardToken,
    nftDetailsAPI,
    aprAPI,
    isLP,
    farmData,
    minimumStake,
    stakingFee,
    depositLockingPeriod,
    harvestLockingPeriod,
    emergencyWithdrawFee,
    unlockTime,
    onUnstakeEmergency,
  }: StakePopUpModelProps,
  ref
) => {
  const contractAddressValue = farmData.isLocked ? farmData.lockedFarmAddress : contractAddress;

  const { provider, address, chainID } = useWeb3Context();
  const [isApproved, setIsApproved] = React.useState(0);
  const [isNFTApproved, setIsNFTApproved] = React.useState(false);
  const [acceptCondition, setAcceptCondition] = React.useState(true);

  const [value, setValue] = React.useState(0);
  const [token0, setToken0] = React.useState(0);
  const [token1, setToken1] = React.useState(0);
  const [nfts, setNfts] = React.useState<MoralisNFTModel[]>();
  const { MUTATION_ADDRESS, masterchef } = useMemo(() => getAddresses(chainID), [chainID]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState('0');
  const [, setMaxBalance] = React.useState('0');
  const [selectedNft, setSelectedNft] = React.useState<MoralisNFTModel>();
  const [unstakeAmount, setUnstakeAmount] = React.useState<any>(0);
  const [rewardPrice, setRewardPrice] = React.useState(0);
  const [unstakeNft, setUnstakeNft] = React.useState(true);
  const [nftAlreadyStaked, setNftAlreadyStaked] = React.useState(true);
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';
  const currTimeStamp = Date.now();

  const currencyContract = React.useMemo(
    () => new ethers.Contract(tokenAddress, ERC20Contract, provider.getSigner()),
    [tokenAddress, provider]
  );

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();

    verify(getFarmPrice(farmData, provider)).then((price) => setRewardPrice(price), errorHandler);
    const currencyContract = new ethers.Contract(tokenAddress, ERC20Contract, provider);
    if (address) {
      verify(currencyContract.balanceOf(address)).then((bal: BigNumber) => setMaxBalance(convertFromWei(bal.toString())), errorHandler);
    }

    if (isLP) {
      const lpContract = new ethers.Contract(tokenAddress, LPTokenContract, provider);
      lpContract.token0().then((data) => setToken0(data));
      lpContract.token1().then((data) => setToken1(data));
    } else {
      setToken0(tokenAddress);
    }

    return cancel;
  }, [provider, address, farmData, isLP, tokenAddress]);

  const onMaxClick = async () => {
    const bal = await currencyContract.balanceOf(address);
    const decimalValue = await currencyContract.decimals();
    const decimal = decimalValue === 9 ? 'gwei' : decimalValue === 6 ? 'mwei' : 'ether';
    const balance = convertFromWei(bal.toString(), decimal);
    setAmount(balance);
  };

  const onMaxUnstakeClick = async () => {
    const bal = userStaked;
    const decimalValue = await currencyContract.decimals();
    const decimal = decimalValue === 9 ? 'gwei' : decimalValue === 6 ? 'mwei' : 'ether';
    const balance = convertFromWei(bal.toString(), decimal);
    setUnstakeAmount(balance);
  };

  const handleClickOpen = async () => {
    const nft = nftDetailsAPI(<></>, (nft: any) => nft);
    setNftAlreadyStaked(!!nft);
    setSelectedNft(null);
    const allowed = await currencyContract.allowance(address, contractAddressValue);
    setIsApproved(+allowed.toString() / 1e18);
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider);
    const totalNfts = await boosterTokenContract.balanceOf(address);
    const mutationContract = new ethers.Contract(MUTATION_ADDRESS, MutationABI, provider.getSigner());

    const oldNFTs = JSON.parse(JSON.stringify(nfts || []));
    const oldNFTsMap = oldNFTs.reduce((acc, curr) => ({ ...acc, [curr.token_id]: curr }), {});
    setViewAllNFT(false);
    setNfts([]);
    const allPromises = [];
    for (let i = 0; i < Number(totalNfts); i++) {
      const promise = boosterTokenContract
        .tokenOfOwnerByIndex(address, i)
        .then(async (token) => {
          const isMuted = await mutationContract.isMutated(token);
          const id = Number(token);
          if (oldNFTsMap[id]) {
            return oldNFTsMap[id];
          }
          return boosterTokenContract
            .getNftType(id)
            .then((nftType) => nftRarities(nftType))
            .then((nftRarity) => ({
              token_id: id,
              token_address: boosterTokenAddress,
              image: isMuted
                ? 'https://uniwpunk-mutant-nfts.s3.amazonaws.com/' + token + '.png'
                : 'https://uniwpunk-nfts.s3.amazonaws.com/' + token + '.png',
              rarityDetails: nftRarity,
              isMuted: isMuted,
            }));
        })
        .then((item) => {
          setNfts((prev) => [...prev, item]);
        });

      allPromises.push(promise);
    }

    // const nftsFromAPI = await Promise.all(allPromises);
    // setNfts(nftsFromAPI);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openPopup: handleClickOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const stakeClicked = async () => {
    // if (amount > maxBalance) {
    //   dispatch(error({ text: messages.not_enough_balance }));
    // } else {
    const allowed = await currencyContract.allowance(address, contractAddressValue);

    if (Number(allowed) < Number(amount)) {
      onApprove();
    } else {
      await onStake(amount, selectedNft);
      handleClose();
      setAmount('0');
    }

    // }
  };

  const unstakeClicked = async () => {
    const isNftAvailableToStake = nftDetailsAPI(<></>, (data) => !!data);
    if (isNftAvailableToStake && unstakeNft) {
      await onUnstakeNFT();
    }
    if (unstakeAmount > 0) {
      await onUnstake(unstakeAmount);
    }
    setUnstakeAmount(0);
    setOpen(false);
  };

  const emergencyWithdraw = async () => {
    await onUnstakeEmergency();
    setUnstakeAmount(0);
    setOpen(false);
  };

  const onApprove = async () => {
    try {
      const approveTx = await currencyContract.approve(contractAddressValue, ethers.constants.MaxUint256);
      await approveTx.wait();
      const allowed = await currencyContract.allowance(address, contractAddressValue);
      setIsApproved(+allowed.toString() / 1e18);
      return approveTx;
    } catch (e) {
      console.log(e);
    }
  };

  const onNFTSelect = async (nft: MoralisNFTModel) => {
    setSelectedNft(nft);
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider.getSigner());
    const allowed = await boosterTokenContract.getApproved(nft.token_id);
    setIsNFTApproved(allowed.toLowerCase() === masterchef.toLowerCase());
  };

  const onApproveNFT = async () => {
    try {
      const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider.getSigner());
      const approveTx = await boosterTokenContract.approve(contractAddressValue, selectedNft.token_id);
      await approveTx.wait();
      setIsNFTApproved(true);
      return approveTx;
    } catch (e) {
      console.log(e);
    }
  };

  const rewardPerDayOnStakeAmount = useAsyncRender(() => {
    const contract = new ZbetMasterChefHelper(contractAddressValue, provider);
    return contract?.rewardPerDayOnAmount(farmData.pid, +amount);
  }, [address, amount]);

  const rewardPerDayOnUnstakeAmount = useAsyncRender(() => {
    const contract = new ZbetMasterChefHelper(contractAddressValue, provider);
    return contract?.rewardPerDayOnAmount(farmData.pid, unstakeAmount);
  }, [address, unstakeAmount]);

  const [viewAllNFT, setViewAllNFT] = useState(false);

  const nftsToDisplay = viewAllNFT ? nfts : (nfts || []).slice(0, 5);

  return (
    <>
      <Button className="stak_btn manage_btn_new" onClick={handleClickOpen}>
        Manage
      </Button>
      <Box className="modal_boost_frst">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="farm_popup_main respons_btr_dilg"
        >
          <Box className="gamble_ppup gamble_ppup_v2 gamble_ppup_v2a">
            <Box className="farm_txt_bxx">
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Box component="h1" className="farmname">
                  {farmName}
                </Box>
              </BootstrapDialogTitle>
              {/* chainID === Networks.ETHW ?  */}
              <Box
                className="gamble_txt_img no-decoration"
                component="a"
                target="_blank"
                href={
                  isLP
                    ? chainID === Networks.ETHW
                      ? 'https://app.uniwdex.com/#/add/' + token0 + '/' + token1
                      : 'https://arbitrum.uniwswap.com/#/add/' + token0 + '/' + token1
                    : chainID === Networks.ETHW
                    ? 'https://app.uniwdex.com/#/swap?outputCurrency=' + tokenAddress
                    : 'https://arbitrum.uniwswap.com/#/swap?outputCurrency=' + tokenAddress
                }
              >
                <Typography>
                  {isLP ? 'Add liquidity' : 'Buy Token'}
                  <img src="./img/arrow_up_icon.svg" alt="" />
                </Typography>
              </Box>
            </Box>

            <Box className="tab_prnt tab_prnt_va">
              <Box sx={{ width: '100%' }}>
                <Box sx={{}}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabs_btn">
                    <Tab label="Stake" {...a11yProps(0)} className="stake_btn_def" />
                    {/* <Tab label="Unstake" disabled={userStaked === 0} {...a11yProps(1)} className="stake_btn_def" /> */}
                    <Tab label="Unstake" {...a11yProps(1)} className="stake_btn_def" />
                  </Tabs>
                </Box>
                <Box className="tbn_bx">
                  <TabPanel value={value} index={0}>
                    <Box className="stake_min_box">
                      <Box className="imput_prnt">
                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)}></input>
                        <Button onClick={onMaxClick}>MAX</Button>
                      </Box>
                      <Typography className="cost_p">
                        Current interest rate on amount above would yield{' '}
                        {rewardPerDayOnStakeAmount(
                          <>0.0</>,
                          (reward) => (
                            <>{reward ? toFixed2(reward) : 0}</>
                          ),
                          () => (
                            <>0.0</>
                          )
                        )}{' '}
                        {tokenName} per day. Subject to change with market trends.
                      </Typography>
                      <Box>
                        <Box className="slect_borde_bx">
                          <Typography component="h5">Select boosters</Typography>
                          {nftsToDisplay?.length > 0 && (
                            <>
                              <Box className="al_bx_img">
                                <Box className="all_img_five " flexWrap="wrap">
                                  {nftsToDisplay?.map((nft) => (
                                    <Box className="cstm_rd_box" key={nft.token_id}>
                                      <input
                                        type="radio"
                                        name="booster"
                                        disabled={nftAlreadyStaked}
                                        id={nft.token_id}
                                        value={nft.token_id}
                                        onChange={() => {
                                          onNFTSelect(nft);
                                        }}
                                      />
                                      <img src={nft.image} alt="" className="popup_rarity_ic" height={75} width={75} />
                                      <Typography component="h2" className="days_p_10">
                                        {nft?.rarityDetails?.lockedBoost} %
                                      </Typography>
                                    </Box>
                                  ))}
                                  {(nfts?.length ?? 0) > 5 && !viewAllNFT && (
                                    <Box className="cstm_rd_box cstm_rd_box_btn">
                                      <input type="radio" name="booster" id="boster05" />
                                      <Button className="btn_las_2" onClick={() => setViewAllNFT(true)}>
                                        +{nfts.length - 5}
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                                {nftAlreadyStaked && (
                                  <Typography className="one_nft_h5" component="h5">
                                    One NFT is Already staked
                                  </Typography>
                                )}
                              </Box>
                              <Box className="nft_boost nft_boost_btn_spn nft_boost_btn_spn_va">
                                {selectedNft && farmData.isLocked ? (
                                  !nftAlreadyStaked && (
                                    <>
                                      <img src="./img/i_140_img_ppup.svg" alt="" />
                                      Locking period:: {depositLockingPeriod / (3600 * 24)} Days - {selectedNft?.rarityDetails?.lockedBoost}
                                      % = (
                                      {(depositLockingPeriod - (depositLockingPeriod * selectedNft?.rarityDetails?.lockedBoost) / 100) /
                                        (3600 * 24)}
                                      ) Days
                                    </>
                                  )
                                ) : !farmData.isLocked ? (
                                  <Typography component="h5">
                                    {aprAPI(
                                      <Skeleton variant="rectangular" height={34} width={48} />,
                                      (aprValue) => (
                                        <>
                                          <img src="./img/i_140_img_ppup.svg" alt="" />
                                          {aprValue}%
                                          <span className="boost_gri_text"> + {selectedNft?.rarityDetails?.discount ?? 0}% </span> ={' '}
                                          {selectedNft
                                            ? (aprValue + (aprValue * selectedNft?.rarityDetails?.discount) / 100).toFixed(2)
                                            : aprValue}
                                          % APR
                                          <span className="boost_grilr">Boosted</span>
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                ) : (
                                  ''
                                )}
                              </Box>
                            </>
                          )}
                          {nfts?.length === 0 && <Button className="buy_bstr_btn">Buy Booster NFT</Button>}
                        </Box>
                        <Box className="annua_last_bx_prnt">
                          <Box className="annua_bx">
                            <Typography>Annual ROI at current rates:</Typography>
                            <Box className="wale_bx">
                              <img src="/img/vollet.svg" alt="" />
                              {aprAPI(
                                <Skeleton variant="rectangular" height={34} width={48} />,
                                (aprValue) => (
                                  <Typography>&nbsp; ${toFixed2((aprValue * +amount * rewardPrice) / 100)} &nbsp;</Typography>
                                ),
                                (err) => (
                                  <Typography>Error...</Typography>
                                )
                              )}
                            </Box>
                          </Box>
                          {farmData.isLocked && (
                            <>
                              <Box className="annua_bx annual_vele_bx">
                                <Typography component="h4">Minimum stake amount</Typography>
                                <Typography component="h2">
                                  {minimumStake} {farmData.name}
                                </Typography>
                              </Box>
                              <Box className="annua_bx annual_vele_bx">
                                <Box className="boster_in_llok">
                                  <Typography component="h4">Locking period</Typography>
                                </Box>
                                <Typography component="h2" className="clr_blue">
                                  {depositLockingPeriod / (3600 * 24)} Days
                                </Typography>
                              </Box>
                              <Box className="annua_bx annual_vele_bx">
                                <Box className="boster_in_llok">
                                  <Typography component="h4">Harvest Locking period</Typography>
                                </Box>
                                <Typography component="h2" className="clr_blue">
                                  {harvestLockingPeriod / (3600 * 24)} Days
                                </Typography>
                              </Box>
                              <Box className="annua_bx annual_vele_bx">
                                <Typography component="h4">Staking fee</Typography>
                                <Typography component="h2" className="">
                                  {stakingFee} %
                                </Typography>
                              </Box>
                              <Box className="chkbx_pop_up">
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={acceptCondition} onChange={() => setAcceptCondition(!acceptCondition)} />}
                                    label={'I understand staked amount will be locked for ' + depositLockingPeriod / (3600 * 24) + ' Days'}
                                  />
                                </FormGroup>
                              </Box>
                            </>
                          )}

                          <Box className="two_btn_ls">
                            {isApproved >= +amount && (!selectedNft || isNFTApproved) ? (
                              <NotistakeLoaderButton
                                disabled={!(amount || selectedNft) || !acceptCondition || amount < minimumStake}
                                onClick={stakeClicked}
                                style={{ maxWidth: 'none' }}
                              >
                                {amount < minimumStake ? 'Insufficent Minimum Amount' : 'Stake'}
                              </NotistakeLoaderButton>
                            ) : (
                              <>
                                {isApproved >= +amount ? (
                                  <NotistakeLoaderButton msg="Approving NFT" onClick={onApproveNFT} style={{ maxWidth: 'none' }}>
                                    Approve NFT
                                  </NotistakeLoaderButton>
                                ) : (
                                  <NotistakeLoaderButton msg="Approving" onClick={onApprove} style={{ maxWidth: 'none' }}>
                                    Approve
                                  </NotistakeLoaderButton>
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box className='slect_borde_bx_secnd'>
                        <Typography component="h5">Select boosters</Typography>
                        <Box className="cstm_rd_box cstm_rd_box_btn">
                            <input type="radio" name="booster" id="boster05" />
                            <Button className='btn_las_2'>+2</Button>
                        </Box>
                        <Box className='nft_boost nft_boost_btn_spn nft_boost_btn_spn_va'>
                            <img src="./img/i_140_img_ppup.svg" alt='' />
                            <Typography component='h5' className='difult_unlok_h5'>Unlock up to <span className='boost_gri_text'> 160% </span>APR</Typography>
                        </Box>
                        <DialogActions className='stake_btn_box approve_btn'>
                          <Button onClick={handleClose} className="stake_btn">
                            Approve
                            </Button>
                        </DialogActions>
                      </Box> */}
                    </Box>
                  </TabPanel>
                </Box>
                <Box className="unstake_tabs_pnls">
                  <TabPanel value={value} index={1}>
                    <Box className="unstake_min_box">
                      <Box className="neme_box">
                        <Typography className="name_tex">Name</Typography>
                        <Typography component="h2">My Stakes</Typography>
                      </Box>
                      <Box className="ferx_box">
                        <Box className="ferx_img_bx">
                          <img height={40} width={40} src={farmImage || 'img/frax_ic.svg'} alt="" />
                          {/* <img src='./img/rex_set_ic.svg' alt='' /> */}
                          <Typography className="frex_p">{farmName}</Typography>
                        </Box>
                        <Box className="handet_tex">
                          <Typography>
                            {userStaked ? fromExponential(convertFromWei(userStaked.toString())) : 0}
                            <span>${toFixed2((userStaked ? +convertFromWei(userStaked.toString()) : 0) * rewardPrice)}</span>
                          </Typography>
                        </Box>
                      </Box>
                      {unlockTime * 1000 > currTimeStamp && farmData.isLocked && (
                        <Box className="slct_farm_undr">
                          <Button>
                            <img src="/img/Password.svg" alt="" />
                          </Button>
                          <Typography component="h4">Selected farms is currently under locking period</Typography>
                        </Box>
                      )}
                      {nftDetailsAPI(<Skeleton variant="rectangular" height={80} width={80} />, (data) => (
                        <>
                          {data && (
                            <Box className="imag_catri_bx">
                              <Box className="img_flex_bx">
                                {nftDetailsAPI(
                                  <Skeleton variant="rectangular" height={80} width={80} />,
                                  (data) => (
                                    <img src={data.image} alt="" className="popup_rarity_ic" height={80} width={80} />
                                  ),
                                  (err) => (
                                    <Typography>Error...</Typography>
                                  )
                                )}
                                <Box className="span_text">
                                  <Typography>
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Token ID: </span>
                                          {data.id}
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                  <Typography className="text_margin">
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Booster Category:</span>
                                          {data.name}
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                  <Typography>
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Boost:</span>
                                          {data.discount}%
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="chec_bx">
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={unstakeNft} onChange={(e) => setUnstakeNft(e.target.checked)} />}
                                    label="Do you want to Unstake NFT?"
                                  />
                                </FormGroup>
                              </Box>
                            </Box>
                          )}
                        </>
                      ))}
                      <Box className="stake_min_box stake_min_box_marg">
                        <Box className="imput_prnt">
                          <input type="text" value={unstakeAmount} onChange={(e) => setUnstakeAmount(e.target.value)}></input>
                          <Button onClick={() => onMaxUnstakeClick()}>MAX</Button>
                        </Box>
                        {/* #LORENZO */}
                        <Typography className="cost_p">
                          Current interest rate on amount above would yield{' '}
                          {rewardPerDayOnUnstakeAmount(
                            <>0.0</>,
                            (reward) => (
                              <>{reward.toFixed(2)}</>
                            ),
                            () => (
                              <>0.0</>
                            )
                          )}{' '}
                          {tokenName} per day.Subject to change with market trends.
                        </Typography>
                      </Box>
                      {farmData.isLocked ? (
                        <Box className="two_last_brn">
                          <Button className="frst_btnn" onClick={emergencyWithdraw}>
                            EMERGENCY WITHDRAWAL
                          </Button>
                          <Button
                            className={userStaked <= 0 ? 'grey_btn' : 'scnd_btnn'}
                            onClick={unstakeClicked}
                            disabled={userStaked <= 0}
                          >
                            Unstake in <Countdown date={unlockTime * 1000} />
                          </Button>
                        </Box>
                      ) : (
                        <DialogActions className="stake_btn_box">
                          <NotistakeLoaderButton onClick={unstakeClicked} className="stake_btn">
                            Unstake Now
                          </NotistakeLoaderButton>
                        </DialogActions>
                      )}
                      {farmData.isLocked && (farmName === 'OLD UNIA' || farmName === 'OLD UNIW') && (
                        <Box className="last_error_txt">
                          <Typography component="h4" style={{ color: '#25f30f' }}>
                            <FaExclamationTriangle /> &nbsp; The Early Emergency Withdraw tax is disabled from 26/01/2023 18 PM UTC to
                            30/01/2023 14 PM UTC
                          </Typography>
                        </Box>
                      )}
                      {farmData.isLocked && farmName != 'OLD UNIA' && farmName != 'OLD UNIW' && (
                        <Box className="last_error_txt">
                          <Typography component="h4">
                            <FaExclamationTriangle /> &nbsp; Emergency withdraw will result in a {emergencyWithdrawFee}% loss of deposited
                            amount and pending rewards.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </BootstrapDialog>
      </Box>
    </>
  );
};
export default forwardRef(StakePopUpModel);
