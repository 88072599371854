import { LabelImportant } from '@mui/icons-material';
import { AppBar, Box, Button, Link, MenuItem, Toolbar, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';

import ConnectMenu from '../component/connect-button';
import { Networks } from '../constants';
import { useWeb3Context } from '../hooks';

const headersData = [
  {
    label: 'Dashboard',
    href: '/',
    classes: '',
  },
  {
    label: 'Exchange',
    href: 'https://app.uniwdex.com',
    classes: '',
  },
  {
    label: 'Farm',
    href: '/farm',
    classes: '',
  },
  {
    label: 'Staking Pools',
    href: '/stakingPool',
    classes: '',
  },
  {
    label: 'Revenue Share',
    href: '/revenue-sharing',
    classes: '',
  },
  {
    label: 'UniWpunks',
    href: 'https://www.uniwpunks.com',
    classes: '',
  },
  {
    label: 'Games',
    href: '/kingpage',
    classes: '',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
];

const headersDataArb = [
  {
    label: 'Dashboard',
    href: '/',
    classes: '',
  },
  {
    label: 'Exchange',
    href: 'https://arbitrum.uniwdex.com/#/swap?outputCurrency=0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    classes: '',
  },
  {
    label: 'Farm',
    href: '/farm',
    classes: '',
  },
  {
    label: 'Staking Pools',
    href: '/stakingPool',
    classes: '',
  },
  {
    label: 'UniPad',
    href: '/launchpad',
    classes: '',
  },
  {
    label: 'UniSports',
    href: '/',
    classes: 'comingsoon',
  },
  {
    label: 'FAQ',
    href: '/faq',
  },
];

const headersDataTwo = [
  {
    label: 'Docs',
    href: 'https://docs.uniwswap.com/',
  },
];

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const savedChainId = window.localStorage.getItem('chainId');

  const [anchorEltwo, setAnchorEltwo] = React.useState<null | HTMLElement>(null);
  const opentwo = Boolean(anchorEltwo);
  const handleClicktwo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEltwo(event.currentTarget);
  };
  const handleClosetwo = () => {
    setAnchorEltwo(null);
  };

  const [anchorElthree, setAnchorElthree] = React.useState<null | HTMLElement>(null);
  const openthree = Boolean(anchorElthree);
  const handleClickthree = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElthree(event.currentTarget);
  };
  const handleClosethree = () => {
    setAnchorElthree(null);
  };

  const { chainID, changeNetwork } = useWeb3Context();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1199
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className="toolbar">
        <Link
          {...{
            component: RouterLink,
            to: '/',
            color: 'inherit',
            style: { textDecoration: 'none' },
          }}
        >
          {femmecubatorLogo}
        </Link>
        <div className="insdmn">
          {getMenuButtons()}
          {getDrawerChoicesTwo()}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    // const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
    // const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <Link
          {...{
            component: RouterLink,
            to: '/',
            color: 'inherit',
            style: { textDecoration: 'none' },
          }}
        >
          {femmecubatorLogo}
        </Link>
      </Toolbar>
    );
  };

  // const getDrawerChoices = () => {
  //   return headersData.map(({ label, href, classes }) => {
  //     return (
  //       <Link
  //         {...{
  //           component: RouterLink,
  //           to: href,
  //           color: 'inherit',
  //           style: { textDecoration: 'none' },
  //           key: label,
  //           className: classes,
  //         }}
  //       >
  //         <MenuItem>{label}</MenuItem>
  //       </Link>
  //     );
  //   });
  // };
  const getDrawerChoicesTwo = () => {
    return headersDataTwo.map(({ label, href }) => {
      return (
        <a
          {...{
            href,
            key: label,
          }}
          target="_blank"
          className="mbllnks"
        >
          {label}
        </a>
      );
    });
  };

  const femmecubatorLogo = (
    <Box component="img" className="logoheader" src={chainID === Networks.ETHW ? '/img/logo.png' : '/img/logoARB.png'} />
  );

  const getMenuButtons = () => {
    const headerMenu = +savedChainId === Networks.ETHW ? headersData : headersDataArb;
    return headerMenu.map(({ label, href, classes }) => {
      let classValue = window.location.pathname === href ? 'active' : '';
      classValue = classes + classValue;
      if (label === 'UniWpunks' || label === 'Exchange') {
        return (
          <a
            {...{
              href,
              key: label,
            }}
            target="_blank"
            className="mbllnks"
          >
            {label}
          </a>
        );
      } else {
        return (
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: RouterLink,
              className: classValue,
            }}
            sx={{
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 700,
              size: '18px',
              marginLeft: '38px',
            }}
          >
            {label}
          </Button>
        );
      }
    });
  };
  const [chain, setChain] = React.useState(chainID);

  useEffect(() => {
    setTheme(chain);
  }, [chain]);

  const handleChange = (event: SelectChangeEvent) => {
    setChain(+event.target.value);
    changeNetwork(+event.target.value);
  };

  const setTheme = (value: number) => {
    Chains.forEach((item) => {
      document.body.classList.remove(item.theme);
    });
    const current = Chains.find((item) => item.id === value);
    document.body.classList.add(current.theme);
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <>
      <Box className="botum_manu_box">
        <div className="bet_btn_p">
          {+savedChainId === Networks.ETHW ? (
            <>
              <Box
                className={
                  splitLocation[1] === 'dex' || splitLocation[1] === 'active-bets' || splitLocation[1] === 'history-bets'
                    ? 'manu_img_paert_aj active'
                    : 'manu_img_paert_aj'
                }
              >
                <img src="/img/bet_img_aj.svg" alt="" />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Dex
                </Button>
              </Box>

              <Menu
                className="btn_manu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Box className="ul_li_box">
                  <ul>
                    <li>
                      <a href="https://app.uniwdex.com/#/swap" onClick={handleClosetwo}>
                        Swap
                      </a>
                    </li>
                  </ul>
                </Box>
                <Box className="ul_li_box">
                  <ul>
                    <li>
                      <a href="https://app.uniwdex.com/#/pool" onClick={handleClosetwo}>
                        Add Liquidity
                      </a>
                    </li>
                  </ul>
                </Box>
              </Menu>
            </>
          ) : (
            <>
              <Box
                className={
                  splitLocation[1] === 'dex' || splitLocation[1] === 'active-bets' || splitLocation[1] === 'history-bets'
                    ? 'manu_img_paert_aj active'
                    : 'manu_img_paert_aj'
                }
              >
                <img src="/img/bet_img_aj.svg" alt="" />
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  Dex
                </Button>
              </Box>

              <Menu
                className="btn_manu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Box className="ul_li_box">
                  <ul>
                    <li>
                      <a
                        href="https://swapfish.fi/swap?outputCurrency=0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b"
                        onClick={handleClosetwo}
                        target="_blank"
                      >
                        Exchange
                      </a>
                    </li>
                  </ul>
                </Box>
              </Menu>
            </>
          )}
          <Box className={splitLocation[1] === 'farm' || splitLocation[1] === '' ? 'manu_img_paert_aj active' : 'manu_img_paert_aj'}>
            <img src="/img/farm_img_aj.svg" alt="" />
            <Button
              id="basic-button"
              aria-controls={opentwo ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={opentwo ? 'true' : undefined}
              onClick={handleClicktwo}
            >
              Earn
            </Button>
          </Box>
          <Menu
            className="btn_manu"
            id="basic-menu"
            anchorEl={anchorEltwo}
            open={opentwo}
            onClose={handleClosetwo}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink to="/" onClick={handleClosetwo}>
                    Dashboard
                  </NavLink>
                </li>
              </ul>
            </Box>
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink to="/farm" onClick={handleClosetwo}>
                    Farms
                  </NavLink>
                </li>
              </ul>
            </Box>
            {+savedChainId === Networks.ETHW && (
              <>
                <Box className="ul_li_box">
                  <ul>
                    <li>
                      <NavLink to="/revenue-sharing" onClick={handleClosetwo}>
                        Revenue Share
                      </NavLink>
                    </li>
                  </ul>
                </Box>

                <Box className="ul_li_box	">
                  <ul>
                    <li>
                      <NavLink target="_blank" to="https://uniwpunks.com" onClick={handleClosetwo}>
                        UniWpunks
                      </NavLink>
                    </li>
                  </ul>
                </Box>
              </>
            )}
            <Box className="ul_li_box	">
              <ul>
                <li>
                  <NavLink to="/stakingPool" onClick={handleClosetwo}>
                    Pools
                  </NavLink>
                </li>
              </ul>
            </Box>
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink to="/launchpad" onClick={handleClosetwo}>
                    UniWpad
                  </NavLink>
                </li>
              </ul>
            </Box>
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink to="/" onClick={handleClosetwo}>
                    UniWsports
                  </NavLink>
                </li>
              </ul>
            </Box>
            {+savedChainId === Networks.ETHW && (
              <>
                <Box className="ul_li_box	">
                  <ul>
                    <li>
                      <NavLink to="/kingpage" onClick={handleClosetwo}>
                        Games
                      </NavLink>
                    </li>
                  </ul>
                </Box>
              </>
            )}
          </Menu>
          <Box className={splitLocation[1] === 'faq' ? 'manu_img_paert_aj active' : 'manu_img_paert_aj'}>
            <NavLink to="/faq" className="faq_l img_set_parnt_aj">
              <img src="/img/faq_ima_aj.svg" alt="" />
              Faq
            </NavLink>
          </Box>
          <Box className="manu_img_paert_aj">
            <Button
              id="basic-button"
              aria-controls={openthree ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openthree ? 'true' : undefined}
              onClick={handleClickthree}
              className="faq_botn_only"
            >
              {/* <span className='spn_dots'>...</span>  */}
              <img src="/img/dot_img_aj.svg" alt="" />
              <Typography>More</Typography>
            </Button>
          </Box>
          <Menu
            className="btn_manu"
            id="basic-menu"
            anchorEl={anchorElthree}
            open={openthree}
            onClose={handleClosethree}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box className="ul_li_box">
              <ul>
                <li>
                  <a target="_blank" href="https://docs.uniwswap.com" rel="noreferrer" onClick={handleClosetwo}>
                    Documentation
                  </a>
                </li>
              </ul>
            </Box>
            {+savedChainId === Networks.ETHW && (
              <>
                <Box className="ul_li_box	">
                  <ul>
                    <li>
                      <a target="_blank" href="https://uniwpunks.com" onClick={handleClosetwo}>
                        UniWpunks
                      </a>
                    </li>
                  </ul>
                </Box>
                <Box className="ul_li_box">
                  <ul>
                    <li>
                      <a target="_blank" href="https://uniwgrant.com" rel="noreferrer" onClick={handleClosetwo}>
                        UniWgrants
                      </a>
                    </li>
                  </ul>
                </Box>
              </>
            )}
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink
                    target="_blank"
                    to={+savedChainId === Networks.ETHW ? 'https://twitter.com/uniwswap' : 'https://twitter.com/uniwswap'}
                    onClick={handleClosetwo}
                  >
                    Twitter
                  </NavLink>
                </li>
              </ul>
            </Box>
            <Box className="ul_li_box">
              <ul>
                <li>
                  <NavLink
                    target="_blank"
                    to={+savedChainId === Networks.ETHW ? 'https://t.me/uniwswap' : 'https://t.me/uniwswap'}
                    onClick={handleClosetwo}
                  >
                    Telegram
                  </NavLink>
                </li>
              </ul>
            </Box>
          </Menu>
        </div>
      </Box>
      <div className="header">
        <AppBar>
          <Box className="yllw_bnnr">
            <b>
              PHISHING WARNING: please make sure you're visiting{' '}
              <i>{+savedChainId === Networks.ETHW ? 'https://farm.uniwdex.com' : 'https://farm.uniwdex.com'} </i> - check the URL carefully.
            </b>
          </Box>
          {mobileView ? displayMobile() : displayDesktop()}
          <Box className="right_header">
            {/* def_btn_v2 */}
            <Box className="head_select">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={chain.toString()}
                  label="chain"
                  inputProps={{ 'aria-label': 'Without label' }}
                  className="def_btn_v2"
                  id="demo-simple-select-helper"
                  MenuProps={{ className: 'Header_sl_drpdn' }}
                  onChange={handleChange}
                >
                  {Chains.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <Button className='def_btn_v2'>
              15:40&nbsp;<span>(GTM+1)</span>
            </Button> */}
            <Box className="hdrdvdr" />
            <ConnectMenu />
            {/* <Button className="def_btn_gray">
                <span>Connect Wallet</span>
                <Box component="img" src="/img/wallet_ic.svg" />
            </Button> */}
          </Box>
        </AppBar>
      </div>
      {/* <Sidebar /> */}
      {/* <Banner /> */}
    </>
  );
}

const Chains = [
  // {
  //   id: 56,
  //   name: (
  //     <>
  //       Binance Chain&nbsp;<span>BNB</span>
  //     </>
  //   ),
  //   theme: 'binance',
  //   disabled: true,
  // },
  {
    id: 10001,
    name: (
      <>
        ETHW Network&nbsp;<span>ETHW</span>
      </>
    ),
    theme: 'binance',
  },
  // {
  //   id: 42161,
  //   name: (
  //     <>
  //       Arbitrum Network&nbsp;<span>ARB</span>
  //     </>
  //   ),
  //   theme: 'arbitrum',
  // },
  // {
  //   id: 3,
  //   name: (
  //     <>
  //       Shibarium&nbsp;<span>SHIBA</span>
  //     </>
  //   ),
  //   theme: 'polygon',
  //   disabled: true,
  //   },
  // {
  //   id: 56,
  //   name: (
  //     <>
  //       Binance Chain&nbsp;<span>BNB</span>
  //     </>
  //   ),
  //   theme: 'binance',
  //   class: 'Mui-disabled',
  //   disabled: true,
  // },
];
