import React, { useMemo, useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LastEventsCard from '../component/KOF/LastEventsCard';
import ParticipateCard from '../component/KOF/ParticipateCard';
import useRefresh from '../hooks/useRefresh';
import KOFHelper from '../helpers/kofHelper';
import { getAddresses, Networks } from '../constants';
import { useWeb3Context } from '../hooks';
import { ethers } from 'ethers';
import { ERC20Contract } from '../abi';
import TillCollapseCard from '../component/KOF/TillCollapseCard';
import GetTokenPrice from '../utils/get-bork-price';
import ActualKing from '../component/KOF/ActualKing';
import ParticipateCard2 from '../component/KOF/ParticipateCard2';

export default function KingPage() {
  useEffect(() => {
    document.body.classList.add('king_page');
    return () => {
      document.body.classList.remove('king_page');
    };
  }, []);

  const { ultraRefresh } = useRefresh();
  const { provider, chainID, address } = useWeb3Context();
  const addresses = useMemo(() => getAddresses(chainID), [chainID]);
  const [lastBidder, setLastBidder] = useState(null);
  const [bidAmount, setbidAmount] = useState(null);
  const [nextStartTime, setnextStartTime] = useState(null);
  const [lastWinner, setlastWinner] = useState(null);
  const [hasWinner, sethasWinner] = useState(null);
  const [lastBidTime, setlastBidTime] = useState(null);
  const [endDelay, setendDelay] = useState(null);
  const [isBanned, setisBanned] = useState(false);
  const [endOfAuction, setendOfAuction] = useState(null);

  const [kofBalance, setkofBalance] = useState(0);
  const [price, setPrice] = useState(0);

  const kofContract = React.useMemo(() => new KOFHelper(addresses.KOF_ADDRESS, provider.getSigner()), [addresses, provider]);
  const currencyContract = useMemo(
    () => new ethers.Contract(addresses.platformToken, ERC20Contract, provider.getSigner()),
    [addresses.platformToken, provider]
  );


  useEffect(() => {
    const savedChainId = window.localStorage.getItem('chainId');
    if (+savedChainId === Networks.ARBITRUM) window.location.href = '/';
  }, [window]);


  useEffect(() => {
    const status = { cancelled: false };
    /* 
      In Case when new refresh call has started and current call is not yet completed
      Value can show older because of late update
      Following syntax resolves that issue
      if (status.cancelled) return;
    */
    Promise.all([kofContract.lastBidTime(), kofContract._endDelay()]).then(([lastBidTimeVal, _endDelayVal]) => {
      if (status.cancelled) return;
      setlastBidTime(lastBidTimeVal);
      setendDelay(_endDelayVal);
      setendOfAuction((lastBidTimeVal + _endDelayVal) * 1000);
    });

    const currencyContract = new ethers.Contract(addresses.platformToken, ERC20Contract, provider);
    currencyContract.balanceOf(addresses.KOF_ADDRESS).then((bal) => {
      if (status.cancelled) return;
      setkofBalance(Number(bal) / 1e18);
    });

    GetTokenPrice(provider).then((val) => {
      if (status.cancelled) return;
      setPrice(val);
    });
    kofContract.lastBidder().then((val) => {
      if (status.cancelled) return;
      setLastBidder(val);
    });
    kofContract.bidAmount().then((val) => {
      if (status.cancelled) return;
      setbidAmount(val);
    });
    kofContract.nextStartTime().then((val) => {
      if (status.cancelled) return;
      setnextStartTime(val);
    });
    kofContract.lastWinner().then((val) => {
      if (status.cancelled) return;
      setlastWinner(val);
    });
    kofContract.hasWinner().then((val) => {
      if (status.cancelled) return;
      sethasWinner(val);
    });
    kofContract.isBanned(address).then((val) => {
      if (status.cancelled) return;
      setisBanned(val);
    });

    return () => {
      status.cancelled = true;
    };
  }, [addresses.KOF_ADDRESS, address, kofContract, ultraRefresh, addresses.platformToken, provider]);

  return (
    <>
      <div className="kingPage">
        <div className="kingBox">
          <TillCollapseCard
            hasWinner={hasWinner}
            nextStartTime={nextStartTime}
            lastBidTime={lastBidTime}
            endOfAuction={endOfAuction}
            kofBalance={kofBalance}
            price={price}
          />
          <div className="kingBoxBottom">
            <Grid container spacing={10} className="kingcolumn">
              <Grid item xs={12} lg={4}>
                <ParticipateCard bidAmount={bidAmount} currencyContract={currencyContract} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ParticipateCard2
                  hasWinner={hasWinner}
                  nextStartTime={nextStartTime}
                  bidAmount={bidAmount}
                  isBanned={isBanned}
                  lastWinner={lastWinner}
                  currencyContract={currencyContract}
                />
                {/* <h4 className="def_h4">Game rules:</h4>
                  <ul>
                    <li>lorem loremlorem loremlorem lorem</li>
                    <li>lorem loremlorem loremlorem lorem</li>
                    <li>lorem loremlorem loremlore</li>
                    <li>lorem loremlorem lor</li>
                  </ul> */}
              </Grid>
              <Grid item xs={12} lg={4}>
                <ActualKing lastBidder={lastBidder} hasWinner={hasWinner} />
              </Grid>
            </Grid>
          </div>
          {/* <LastEventsCard /> */}
          <Box className="king_foresy_last_bx">
            <Typography component="h2" className="foeget_h">
              The UniW King rules.<span>Important Note: All bids are final. Your UNIW cannot be returned after bidding.</span>
            </Typography>
            <Grid container spacing={1} className="howtoply_inner">
              <Grid item xs={12} lg={6}>
                <Box className="how_to_plybx how_to_plybx_v2">
                  <Typography component="h3">How to Play?</Typography>
                  <Typography>
                    UniW King has a 2.5 minute countdown timer which resets on each bid.
                    <br /> Each Bid is 0.5 $UNIW that goes directly to the pool balance, allowing you to become the current king.
                    <br /> 10% of each bid is burned automatically.
                    <br /> When the timer reaches zero, the current king wins the game.
                    <br /> Before another game can start one of the users has to press the restart game button.
                    <br /> This resets the contract and refreshes the cooldown timer before a new game begins.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className="how_to_plybx how_to_plybx_v2">
                  <Typography component="h3">Winning’s distribution?</Typography>
                  <Typography>
                    When the countdown timer ends, 100% of the pool balance will be given away
                    <br /> 60% credited instantly to winner’s address (last bidder).
                    <br /> 20% carries over to the next round.
                    <br /> 20% is burned automatically.
                    <br /> 10% is burned on every bet transaction.
                    <br /> Note: Anyone can click on the claim button but the winning share will go to the last bidder.
                    <br /> Note: The winner of UniW King can't bid in the next round.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
}
