import Web3 from 'web3';

// export function convertFromWei(value, decimal?) {
//   // return +Web3.utils.fromWei(Number(value).toString, decimal ? decimal : 'ether');

//   const str = Web3.utils.fromWei(Number(value).toLocaleString('fullwide', { useGrouping: false }), decimal ? decimal : 'ether');
//   const valueNum = +str;
//   const floorValue = Math.floor(+str);
//   return valueNum - floorValue > 0 ? +str.substring(0, str.length - 1) : +str;
// }

// export function convertWeiTemp(value, decimal?) {
//   const str = Web3.utils.fromWei(Web3.utils.toBN(value), decimal ? decimal : 'ether');
//   const valueNum = +str;
//   const floorValue = Math.floor(+str);
//   return valueNum - floorValue > 0 ? str.substring(0, str.length - 1) : str;
// }

export function convertToWei(value: string, decimal?) {
  return Web3.utils.toWei(value, decimal ? decimal : 'ether');
}
export function convertFromWei(value: string, decimal?) {
  return Web3.utils.fromWei(value, decimal ? decimal : 'ether');
}
// export function convertWei(value) {
//   return Number(value) / 1e18;
// }
