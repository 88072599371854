/* eslint-disable react-hooks/exhaustive-deps */
import type { JsonRpcProvider } from '@ethersproject/providers';
import { StaticJsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import type { ReactElement } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Web3Modal from 'web3modal';

import { DEFAULD_NETWORK, Networks } from '../../constants';
import { messages } from '../../constants/messages';
import { swithNetwork } from '../../helpers/switch-network';
import { getMainnetURI } from './helpers';

type onChainProvider = {
  connect: () => Promise<Web3Provider>;
  disconnect: () => void;
  checkWrongNetwork: () => Promise<boolean>;
  provider: JsonRpcProvider;
  address: string;
  connected: Boolean;
  web3Modal: Web3Modal;
  chainID: number;
  web3?: any;
  providerChainID: number;
  hasCachedProvider: () => boolean;
  changeNetwork: any;
};

export type Web3ContextData = {
  onChainProvider: onChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    // eslint-disable-next-line no-useless-concat
    throw new Error('useWeb3Context() can only be used inside of <Web3ContextProvider />, ' + 'please declare it at a higher level.');
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => {
    return { ...onChainProvider };
  }, [web3Context]);
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const savedChainId = window.localStorage.getItem('chainId');
  const chainID = +savedChainId || DEFAULD_NETWORK;
  const [providerChainID, setProviderChainID] = useState(DEFAULD_NETWORK);
  const [address, setAddress] = useState('');

  const uri = getMainnetURI(+savedChainId);
  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(uri));

  const [web3Modal] = useState<Web3Modal>(
    new Web3Modal({
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              [Networks.ETHW]: getMainnetURI(10001),
              [Networks.ARBITRUM]: getMainnetURI(42161),
            },
          },
        },
        // 'custom-cdc': {
        //   display: {
        //     logo: `/img/crypto.png`,
        //     name: 'Crypto.com',
        //     description: 'Crypto.com | Wallet Extension',
        //   },
        //   options: {
        //     supportedChainIds: [25],
        //     rpc: {
        //       // 25: 'https://rpc.nebkas.ro/', // cronos mainet
        //       // [Networks.CRONOS]: getMainnetURI(),
        //       [Networks.DOGE]: getMainnetURI(),
        //     },
        //     pollingInterval: 15000,
        //   },
        //   package: DeFiConnector,
        //   connector: async (packageConnector, options) => {
        //     const connector = new packageConnector({
        //       name: 'Cronos',
        //       supprtedChainTypes: ['eth'],
        //       supportedChainTypes: ['eth'],
        //       eth: options,
        //       cosmos: null,
        //     });
        //     await connector.activate();

        //     return connector.getProvider();
        //   },
        // },
      },
    })
  );

  const hasCachedProvider = (): boolean => {
    if (!web3Modal) return false;
    if (!web3Modal.cachedProvider) return false;
    return true;
  };

  const _initListeners = useCallback(
    (rawProvider: JsonRpcProvider) => {
      if (!rawProvider.on) {
        return;
      }

      rawProvider.on('accountsChanged', () => setTimeout(() => window.location.reload(), 1));

      rawProvider.on('chainChanged', async (chain: number) => {
        changeNetwork(chain);
      });

      rawProvider.on('network', (_newNetwork, oldNetwork) => {
        if (!oldNetwork) return;
        window.location.reload();
      });
    },
    [provider]
  );

  const changeNetwork = async (otherChainID: number) => {
    const network = Number(otherChainID);
    setProviderChainID(network);
    if (network === Networks.ETHW || network === Networks.ARBITRUM) {
      window.localStorage.setItem('chainId', network.toString());
      window.location.reload();
    }
  };

  const connect = useCallback(async () => {
    const rawProvider = await web3Modal.connect();

    _initListeners(rawProvider);

    const connectedProvider = new Web3Provider(rawProvider, 'any');

    const chainId = await connectedProvider.getNetwork().then((network) => Number(network.chainId));
    console.log(chainId);
    const connectedAddress = await connectedProvider.getSigner().getAddress();

    setAddress(connectedAddress);

    setProviderChainID(chainId);

    if (chainId === Networks.ETHW) {
      setProvider(connectedProvider);
    }

    if (chainId === Networks.ARBITRUM) {
      setProvider(connectedProvider);
    }

    setConnected(true);

    return connectedProvider;
  }, [provider, web3Modal, connected]);

  const checkWrongNetwork = async (): Promise<boolean> => {
    console.log(providerChainID, chainID);
    if (providerChainID !== chainID) {
      const shouldSwitch = window.confirm(chainID === Networks.ETHW ? messages.switch_to_ethw : messages.switch_to_arb);
      if (shouldSwitch) {
        await swithNetwork(chainID);
        window.location.reload();
      }
      return true;
    }

    return false;
  };

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setConnected(false);

    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [provider, web3Modal, connected]);

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      providerChainID,
      checkWrongNetwork,
      changeNetwork,
    }),
    [connect, disconnect, hasCachedProvider, provider, connected, address, chainID, web3Modal, providerChainID, changeNetwork]
  );
  // @ts-ignore
  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};
