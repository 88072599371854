import { ethers } from 'ethers';

import { ReferralABI } from '../abi';
import BN from 'bn.js';

export default class ReferralHelper {
  public contract;

  constructor(contractAddress: string, public provider) {
    this.contract = new ethers.Contract(contractAddress, ReferralABI, provider);
  }

  public async reffCount(address: string) {
    if (!address) {
      return 0;
    }
    const count = await this.contract.referralsCount(address);
    return Number(count);
  }

  public async reffEarned(address: string) {
    if (!address) {
      return new BN(0);
    }
    // const count = await this.contract.totalReferralCommissions(address);
    return this.contract.totalReferralCommissions(address) as Promise<BN>;
  }
}
