import { ethers } from 'ethers';

import { BoosterToken, MutationABI, ZbetMasterChef } from '../abi';
import type { FarmData } from '../data/FarmData';
import nftRarities from '../data/NFTRarities';
import { convertFromWei } from '../utils/convert-wei';
import GetTokenPrice from '../utils/get-bork-price';
import type { MoralisNFTModel } from '../utils/moralis';
import { toFixed2 } from '../utils/tofixed4';
import getFarmPrice from './getFarmPrice';
import BN from 'bn.js';

function delay(secs) {
  return new Promise((res) => setTimeout(() => res(0), secs * 1000));
}

export default class ZbetMasterChefHelper {
  public contract;
  // public cache = {};

  constructor(public contractAddress: string, public provider) {
    this.contract = new ethers.Contract(contractAddress, ZbetMasterChef, provider);
  }

  public poolInfo(pid: number): Promise<any> {
    // const key = `poolInfo-${pid}`;

    // if (!this.cache[key]) {
    //   this.cache[key] =
    // }

    // return this.cache[key];
    return this.contract.poolInfo(pid).then((info) => ({
      accTokenPerShare: Number(info.accTokenPerShare),
      allocPoint: Number(info.allocPoint),
      isPrivatePool: info.isPrivatePool,
      lastRewardBlock: Number(info.lastRewardBlock),
      lpToken: info.lpToken,
      depositFee: Number(info.depositFee),
    }));
  }

  public lpTokenAmount(pid: number) {
    // const key = `lpTokenAmount-${pid}`;

    // if (!this.cache[key]) {
    //   this.cache[key] = this.contract.lpTokenAmount(pid).then((data) => Number(data));
    // }
    // return this.cache[key];

    return this.contract.lpTokenAmount(pid) as Promise<BN>;
  }

  public async pendingToken(pid: number, address: string) {
    if (!address) {
      return new BN(0);
    }
    // const pendingReward = await this.contract.pendingToken(pid, address);
    return this.contract.pendingToken(pid, address) as Promise<BN>;
  }

  public async userStakedAmount(pid: number, address: string) {
    if (!address) {
      return new BN(0);
    }
    const info = await this.contract.userInfo(pid, address);
    return info.amount as BN;
  }

  public async rewardDebt(pid: number, address: string) {
    if (!address) {
      return 0;
    }
    const info = await this.contract.userInfo(pid, address);
    return Number(info.rewardDebt);
  }

  private async getAnnualReward(pid: number) {
    const tokenPerBlock = await this.contract.tokenPerBlock();
    const totalAllocPoint = await this.contract.totalAllocPoint();
    const poolInfo = await this.poolInfo(pid);
    const poolStakeAmount = await this.lpTokenAmount(pid);

    const annualBlock = (360 * 24 * 60 * 60) / 13;
    const tokenAnnualReward = +convertFromWei(tokenPerBlock.toString()) * annualBlock;
    const annualPoolReward = tokenAnnualReward * (poolInfo.allocPoint / Number(totalAllocPoint));
    const poolStaked = +convertFromWei(poolStakeAmount.toString());

    let userAnnualReward = 0;
    if (poolStaked) {
      userAnnualReward = annualPoolReward / poolStaked;
    }
    return userAnnualReward;

    // return Promise.all([this.contract.tokenPerBlock(), this.contract.totalAllocPoint(), this.poolInfo(pid), this.lpTokenAmount(pid)]).then(
    //   ([tokenPerBlock, totalAllocPoint, poolInfo, poolStakeAmount]) => {
    //     const annualBlock = (360 * 24 * 60 * 60) / 13;
    //     const tokenAnnualReward = +convertFromWei(tokenPerBlock.toString()) * annualBlock;
    //     const annualPoolReward = tokenAnnualReward * (poolInfo.allocPoint / Number(totalAllocPoint));
    //     const poolStaked = +convertFromWei(poolStakeAmount.toString());

    //     let userAnnualReward = 0;
    //     if (poolStaked) {
    //       userAnnualReward = annualPoolReward / poolStaked;
    //     }
    //     return userAnnualReward;
    //   }
    // );
  }

  public rewardPerDayOnAmount(pid: number, amount: number) {
    if (!amount) {
      return Promise.resolve(0);
    }

    return this.getAnnualReward(pid).then((annualPoolReward) => (annualPoolReward * amount) / 360);
  }

  public apr(address: string, farm: FarmData) {
    // if (!address) {
    //   return Promise.resolve(0);
    // }
    return delay(5).then(() =>
      Promise.all([this.getAnnualReward(farm.pid), GetTokenPrice(this.provider), getFarmPrice(farm, this.provider)]).then(
        ([annualPoolReward, rewardTokenPriceUSD, stackTokenPriceUSD]) => {
          let aprValue = annualPoolReward * (rewardTokenPriceUSD / stackTokenPriceUSD);
          return toFixed2(aprValue * 100);
        }
      )
    );
  }

  public async stake(pid: number, amount: number, nft?: MoralisNFTModel, reff?: string) {
    let txStakeNFT;
    if (nft) {
      txStakeNFT = await this.contract.depositNFT(nft.token_address, nft.token_id, pid);
      await txStakeNFT.wait();
    }

    let txStake;
    if (amount > 0) {
      txStake = await this.contract.deposit(
        pid,
        ethers.utils.parseEther(amount.toString()),
        reff || '0x0000000000000000000000000000000000000000'
      );
      await txStake.wait();
    }
    return { txStakeNFT, txStake };
  }

  public async unstake(pid: number, amount: number) {
    const txUnstake = await this.contract.withdraw(pid, ethers.utils.parseEther(amount.toString()));
    return txUnstake;
  }

  public async unstakeNFT(pid: number) {
    const txUnstakeNFT = await this.contract.withdrawNFT(pid);
    return txUnstakeNFT;
  }

  public async getNftDetails(pid: number, address: string, boosterTokenAddress: string, MUTATION_ADDRESS: string) {
    if (!address) {
      return null;
    }
    const value = await this.contract.getStakedNFTDetails(address, pid);
    const nftId = Number(value[1]);

    if (nftId === 0) {
      return null;
    }
    const mutationContract = new ethers.Contract(MUTATION_ADDRESS, MutationABI, this.provider);
    const isMuted = await mutationContract.isMutated(nftId);
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, this.provider);
    const nftType = await boosterTokenContract.getNftType(nftId);

    const nftDetails = nftRarities(nftType);
    const image = isMuted
      ? 'https://uniwpunk-mutant-nfts.s3.amazonaws.com/' + nftId + '.png'
      : 'https://uniwpunk-nfts.s3.amazonaws.com/' + nftId + '.png';
    return { ...nftDetails, id: nftId, image };
  }
}
