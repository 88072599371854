import { ethers } from 'ethers';

import { ERC20Contract, LPTokenContract } from '../abi';
import { DEFAULD_NETWORK } from '../constants';
import { getAddresses } from '../constants/addresses';
import { cacheFn } from './cacheFn';
import { getTokenData } from './token-data';

const addresses = getAddresses(DEFAULD_NETWORK);

export default async function GetTokenPrice(provider?) {
  return cacheFn('TokenPrice', () => tokenPriceFinder(addresses.platformTokenLPAddress, provider), 5000);
}

export async function getTokenPrice(lp_address, provider?) {
  return cacheFn('getTokenPrice' + lp_address, async () => tokenPriceFinder(lp_address, provider), 5000);
}

export async function calculateLpTokenPrice(lp_token_address, provider, debug = false) {
  return cacheFn('calculateLpTokenPrice' + lp_token_address, () => calculateLpTokenPriceOld(lp_token_address, provider, debug), 5000);
}

const WHITELIST_ADDRESS = ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'];

const getLpTokenReserves = async (LpTokenContract) => {
  try {
    const totalReserves = await LpTokenContract.getReserves();

    return [Number(totalReserves[0]), Number(totalReserves[1])];
  } catch (e) {
    console.log(e);
    return [0, 0];
  }
};

const tokenPriceFinder = async (lp_token_address, provider) => {
  const ethwPrice = (await getTokenData(addresses.platformWrappedTokenId)).price;
  const LpTokenContract = new ethers.Contract(lp_token_address, LPTokenContract, provider);

  const token0 = await LpTokenContract.token0();
  const token1 = await LpTokenContract.token1();
  const token0Contract = new ethers.Contract(token0, ERC20Contract, provider);
  const token1Contract = new ethers.Contract(token1, ERC20Contract, provider);
  const totalReserve = await getLpTokenReserves(LpTokenContract);
  const token0decimal = 10 ** (await token0Contract.decimals());
  const token1decimal = 10 ** (await token1Contract.decimals());
  // Total Supply of LP Tokens in the Market
  let stablecoinreserve;
  let tokenReserve;
  if (WHITELIST_ADDRESS.indexOf(token0) > -1) {
    stablecoinreserve = totalReserve[0] / token0decimal;
    tokenReserve = totalReserve[1] / token1decimal;
  } else {
    stablecoinreserve = totalReserve[1] / token1decimal;
    tokenReserve = totalReserve[0] / token0decimal;
  }
  const tokenPrice = (stablecoinreserve * ethwPrice) / tokenReserve;

  // If lpTokenPrice is a valid number return lpTokenPrice or return 0
  return tokenPrice || 0;
};

export const calculateLpTokenPriceOld = async (lp_token_address, provider, debug = false) => {
  const LpTokenContract = new ethers.Contract(lp_token_address, LPTokenContract, provider);
  // Total Supply of LP Tokens in the Market
  const totalSupply = Number(await LpTokenContract.totalSupply());
  const totalReserve = await getLpTokenReserves(LpTokenContract);

  const token0 = await LpTokenContract.token0();
  const token1 = await LpTokenContract.token1();
  const token0Contract = new ethers.Contract(token0, ERC20Contract, provider);
  const token1Contract = new ethers.Contract(token1, ERC20Contract, provider);
  const token0decimal = 10 ** (await token0Contract.decimals());
  const token1decimal = 10 ** (await token1Contract.decimals());

  let stablecoinreserve;
  let lpTokenPrice;
  let tokenPrice;

  if (token0.toLowerCase() === addresses.platformToken.toLowerCase() || token1.toLowerCase() === addresses.platformToken.toLowerCase()) {
    tokenPrice = await GetTokenPrice(provider);
    const reserve = token0.toLowerCase() === addresses.platformToken.toLowerCase() ? totalReserve[0] : totalReserve[1];
    const decimal = token0.toLowerCase() === addresses.platformToken.toLowerCase() ? token0decimal : token1decimal;
    stablecoinreserve = reserve / decimal;
  } else if (
    token0.toLowerCase() === addresses.platformWrappedToken.toLowerCase() ||
    token1.toLowerCase() === addresses.platformWrappedToken.toLowerCase()
  ) {
    tokenPrice = (await getTokenData(addresses.platformWrappedTokenId)).price;
    const reserve = token0.toLowerCase() === addresses.platformWrappedToken.toLowerCase() ? totalReserve[0] : totalReserve[1];
    const decimal = token0.toLowerCase() === addresses.platformWrappedToken.toLowerCase() ? token0decimal : token1decimal;
    stablecoinreserve = reserve / decimal;
  } else if (token0.toLowerCase() === addresses.usdcAddress.toLowerCase() || token1.toLowerCase() === addresses.usdcAddress.toLowerCase()) {
    tokenPrice = 1;
    const reserve = token0.toLowerCase() === addresses.usdcAddress.toLowerCase() ? totalReserve[0] : totalReserve[1];
    const decimal = token0.toLowerCase() === addresses.usdcAddress.toLowerCase() ? token0decimal : token1decimal;
    stablecoinreserve = reserve / decimal;
  }

  lpTokenPrice = (stablecoinreserve * 2 * tokenPrice) / (Number(totalSupply) / 1e18);
  // If lpTokenPrice is a valid number return lpTokenPrice or return 0

  return lpTokenPrice || 0;
};
