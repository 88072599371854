import { JsonRpcProvider } from '@ethersproject/providers';
import { ethers } from 'ethers';

import { ERC20Contract, StakingPoolABI } from '../abi';
import type { StakingData } from '../data/StakingPoolData';
import GetTokenPrice, { getTokenPrice } from '../utils/get-bork-price';
import { toFixed2 } from '../utils/tofixed4';
import { getTokenData } from '../utils/token-data';
import BN from 'bn.js';

export default class StakingPoolHelper {
  public contract;

  public stakingPoolAddress;

  public provider;

  constructor(contractAddress: string, public providerVal) {
    this.provider = providerVal;
    this.stakingPoolAddress = contractAddress;
    this.contract = new ethers.Contract(contractAddress, StakingPoolABI, providerVal);
  }

  public async poolInfo(pid: number) {
    const info = await this.contract.poolInfo(pid);
    const poolInfo = {
      accTokenPerShare: Number(info.accCakePerShare),
      allocPoint: Number(info.allocPoint),
      lastRewardBlock: Number(info.lastRewardBlock),
      lpToken: info.lpToken,
    };
    return poolInfo;
  }

  public async lpTokenAmount(pid: number, stakingTokenAddress: string, provider) {
    const currencyContract = new ethers.Contract(stakingTokenAddress, ERC20Contract, provider);
    return currencyContract.balanceOf(this.stakingPoolAddress) as Promise<BN>;
  }

  public async pendingToken(pid: number, address: string) {
    if (!address) {
      return 0;
    }
    const pendingReward = await this.contract.pendingToken(pid, address);
    return Number(pendingReward);
  }

  public async userStakedAmount(address: string) {
    if (!address) {
      return new BN(0);
    }
    const info = await this.contract.userInfo(address);
    return info.amount as BN;
  }

  public async rewardDebt(address: string) {
    if (!address) {
      return new BN(0);
    }
    // const pendingRewardValue = await this.contract.pendingReward(address);
    return this.contract.pendingReward(address) as Promise<BN>;
  }

  public async apr(farm: StakingData) {
    const { tokenAddress, address } = farm;

    const currencyContract = new ethers.Contract(tokenAddress, ERC20Contract, this.provider);
    const decimal = await currencyContract.decimals();
    let rewardTokenPrice;
    if (farm.rewardToken === 'UNIW') {
      rewardTokenPrice = await GetTokenPrice(this.provider);
    } else if (farm.rewardToken !== 'UNIW' && farm.rewardToken !== '') {
      rewardTokenPrice = (await getTokenData(farm.rewardToken)).price;
    } else if (farm.LPwithEthw !== '') {
      rewardTokenPrice = await getTokenPrice(farm.LPwithEthw, this.provider);
    }

    let stakeTokenPrice;
    if (farm.stakeToken === 'UNIW') {
      stakeTokenPrice = await GetTokenPrice(this.provider);
    } else if (farm.stakeToken !== 'UNIW' && farm.stakeToken !== '') {
      stakeTokenPrice = (await getTokenData(farm.stakeToken)).price;
    } else if (farm.LPwithEthw !== '') {
      stakeTokenPrice = await getTokenPrice(farm.LPwithEthw, this.provider);
    }

    const rewardvalue = farm.farmAmount * rewardTokenPrice;

    const distributionperiod =
      (Number(await this.contract.bonusEndBlock()) - Number(await this.contract.startBlock())) / ((60 / 12) * 60 * 24);

    const dailydistribution = rewardvalue / distributionperiod;

    const annualreward = dailydistribution * 365;

    const stakedvalue = (Number(await currencyContract.balanceOf(address)) / 10 ** decimal) * stakeTokenPrice;

    let aprValue = 0;

    aprValue = (annualreward / stakedvalue) * 100;

    return toFixed2(aprValue);
  }

  public async endInTimeStamp() {
    const endblock = Number(await this.contract.bonusEndBlock());
    const startBlock = Number(await this.contract.startBlock());
    const diff = endblock - startBlock;
    const mills = diff * 13 * 1000;
    let timestamp;
    try {
      const res = await (this.provider as JsonRpcProvider).getBlock(startBlock);
      const res1 = await (this.provider as JsonRpcProvider).getBlockNumber();
      timestamp = res?.timestamp * 1000 + mills;
    } catch (e) {
      console.error(e);
    }
    return timestamp;
  }

  public async startInTimeStamp() {
    const startblock = Number(await this.contract.startBlock());

    let timestamp;
    try {
      const res = await (this.provider as JsonRpcProvider).getBlock(startblock);
      timestamp = (res?.timestamp || 0) * 1000;
    } catch (e) {
      console.error(e);
    }
    return timestamp;
  }

  public async stake(amount: number) {
    let txStake;
    if (amount > 0) {
      txStake = await this.contract.deposit(ethers.utils.parseEther(amount.toString()));
      await txStake.wait();
    }
    return { txStake };
  }

  public async unstake(amount: number) {
    const txUnstake = await this.contract.withdraw(ethers.utils.parseEther(amount.toString()));
    return txUnstake;
  }
}
