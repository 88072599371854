import { JsonRpcProvider } from '@ethersproject/providers';
import { ethers } from 'ethers';

import { BURN_STAKING_ABI } from '../abi';
import { convertFromWei } from '../utils/convert-wei';

export default class BurnStakingHelper {
  public contract;

  public burnStakingAddress;

  public provider;

  constructor(contractAddress: string, public providerVal) {
    this.provider = providerVal;
    this.burnStakingAddress = contractAddress;
    this.contract = new ethers.Contract(contractAddress, BURN_STAKING_ABI, providerVal);
  }

  public async endInTimeStamp() {
    return Number(await this.contract.END_TIME());
  }

  public async startInTimeStamp() {
    return Number(await this.contract.START_TIME());
  }

  public async participateAmount(address) {
    const amount = await this.contract.participateAmount(address);

    return amount > 0 ? convertFromWei(amount.toString(), 'ether') : 0;
  }

  public async participateCount() {
    return Number(await this.contract.participateCount());
  }

  public async totalBurnValue() {
    const burnraw = await this.contract.totalBurnValue();
    return burnraw > 0 ? convertFromWei(burnraw.toString(), 'ether') : 0;
  }

  public async participateList(index) {
    return await this.contract.participateList(index);
  }

  public async stake(amount: number) {
    let txStake;
    if (amount > 0) {
      txStake = await this.contract.deposit(ethers.utils.parseEther(amount.toString()));
      await txStake.wait();
    }
    return txStake;
  }
}
