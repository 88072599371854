import { Networks } from '../constants';

export interface IDODataInterface {
  id: string;
  isActive: boolean;
  address: string;
  name: string;
  description?: string;
  startTimestamp: number;
  endTimestamp: number;
  projectSiteUrl: string;
  tokenDecimals: number;
  boosterNFT: string;
  endBlock: number;
  startBlock: number;
  maxParticipationAmount: number;
  minParticipationAmount: number;
  tokenAddress: string;
  raisingAmount: number;
  offeringAmount: number;
  tokenName: string;
  tokenSymbol: string;
  totalSupply: string;
  idoSupply: string;
  lockedInLiquidity: string;
  telegramUrl: string;
  isKyc: boolean;
  harvestBlock: number;
  isAudited: boolean;
  lpToken: string;
  lpTokenSymbol: string;
  lpTokenDecimal: number;
  auditLink?: string;
  auditUrl?: string;
  docsUrl?: string;
  twitterUrl?: string;
  imagePath: string;
  bannerImgName: string;
  burnedAmount: number;
  teamVesting?: string;
  MUTATION_ADDRESS: string;
  method?: string;
  category?: string;
}

const IDODataETHW = [
  {
    id: 'PaperDAO',
    address: '0xe911335FB13c768Bf8A91c3276631af991672409',
    isActive: false,
    name: 'Paper DAO',
    imagePath: '../img/paper_mony_ic.png',
    bannerImgName: 'paperdao_banner.png',
    description:
      'PaperDAO is PoW s first Lending Market and Stablecoin Minter (USDW). We are PoW maximalists and do not buy into the energy usage rhetoric forwarded by PoS promoters. We have an opportunity to create a healthy DEFI ecosystem from scratch now that we have a clean starting point on an EVM compatible chain which does not have custodial assets such as USDC on it.',
    boosterNFT: '0xe48B4261dCD213603bb4a6b85E200C54510CAf50',
    lpToken: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    MUTATION_ADDRESS: '0xD760b855E3853Ff97746B6fD0F0059aF61DBAd19',
    lpTokenSymbol: 'UNIW',
    lpTokenDecimal: 18,
    tokenAddress: '0xA2D59CdC396574Da0F0Bc97a3e883b9fB72f6b88',
    tokenName: 'PAPERDAO',
    tokenSymbol: 'PPR',
    totalSupply: '1,000,000,000',
    idoSupply: '100,000,000',
    lockedInLiquidity: '30',
    burnedAmount: 10,
    tokenDecimals: 18,
    startBlock: 15725000,
    endBlock: 15731800,
    harvestBlock: 150,
    raisingAmount: 60000,
    offeringAmount: 100000000,
    maxParticipationAmount: 1000,
    minParticipationAmount: 1,
    startTimestamp: 1665428000,
    endTimestamp: 1665429000,
    projectSiteUrl: 'https://paperdao.money/',
    telegramUrl: 'https://t.me/paperdaomoney',
    twitterUrl: 'https://twitter.com/paperdaomoney',
    auditUrl: 'https://contractwolf.io/projects/paperdao',
    docsUrl: 'https://paperdao.gitbook.io/paperdao-documentation/',
    isKyc: true,
    isAudited: true,
    auditLink: 'https://contractwolf.io/projects/paperdao',
  },
  {
    id: 'Solaris Finance',
    address: '0xCf312C9f2e321664D484AB9a6D2c88D142674098',
    isActive: false,
    name: 'Solaris Finance',
    imagePath: '../img/SLR.png',
    bannerImgName: 'solarisbanner.png',
    description:
      'Solaris Finance is a truly deflationary yield-farm system with various features designed to solve sustainability problems of current DeFi protocols.      ',
    boosterNFT: '0xe48B4261dCD213603bb4a6b85E200C54510CAf50',
    lpToken: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    MUTATION_ADDRESS: '0xD760b855E3853Ff97746B6fD0F0059aF61DBAd19',
    lpTokenSymbol: 'UNIW',
    lpTokenDecimal: 18,
    tokenAddress: '0x5Fc51602cE9c329520cb40fd245bFCC786c86E01',
    tokenName: 'SOLARIS',
    tokenSymbol: 'SLR',
    totalSupply: '10,000,000',
    idoSupply: '5,000,000',
    lockedInLiquidity: '40',
    burnedAmount: 30,
    teamVesting: '6 weeks',
    tokenDecimals: 18,
    startBlock: 15768000,
    endBlock: 15771300,
    harvestBlock: 150,
    raisingAmount: 100000,
    offeringAmount: 5000000,
    maxParticipationAmount: 1000,
    minParticipationAmount: 1,
    startTimestamp: 1665428000,
    endTimestamp: 1665429000,
    projectSiteUrl: 'https://solarisfinance.app/',
    telegramUrl: 'https://t.me/solarisfinance',
    twitterUrl: 'https://twitter.com/solarisethw',
    auditUrl: '#',
    docsUrl: 'https://solaris-financee.gitbook.io/solaris-finance-ethw/',
    isKyc: true,
    isAudited: true,
    auditLink: '#',
  },
  {
    id: 'Power Vault',
    address: '0x43Fa53487b86Ecfe7cb33C7708ed3da8c4552B9b',
    isActive: false,
    name: 'Power Vault',
    imagePath: '../img/POWV.png',
    bannerImgName: 'powv-banner.jpg',
    description:
      'PowerVault is built to provide the user-oriented Yield Farming experience to DeFi services in the EthereumPOW(ETHW) ecosystem. Powervault provides maximized returns through convenient auto-farming and auto-compounding features. ',
    boosterNFT: '0xe48B4261dCD213603bb4a6b85E200C54510CAf50',
    lpToken: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    MUTATION_ADDRESS: '0xD760b855E3853Ff97746B6fD0F0059aF61DBAd19',
    lpTokenSymbol: 'UNIW',
    lpTokenDecimal: 18,
    tokenAddress: '0x2fdc2aEC4Bad85a06d87C8248716a5deDD647074',
    tokenName: 'POWERVAULT',
    tokenSymbol: 'POWV',
    totalSupply: '5,000,000',
    idoSupply: '2,500,000',
    lockedInLiquidity: '30',
    burnedAmount: 20,
    teamVesting: '7 weeks',
    tokenDecimals: 18,
    startBlock: 15846350,
    endBlock: 15849650,
    harvestBlock: 150,
    raisingAmount: 100000,
    offeringAmount: 2500000,
    maxParticipationAmount: 1000,
    minParticipationAmount: 1,
    startTimestamp: 1665428000,
    endTimestamp: 1665429000,
    projectSiteUrl: 'https://powervault.one/',
    telegramUrl: 'https://t.me/powervault',
    twitterUrl: 'https://twitter.com/PowerVault_one',
    auditUrl: 'https://contractwolf.io/projects/powervault',
    docsUrl: 'https://powervault.gitbook.io/powervault/',
    isKyc: true,
    isAudited: true,
    auditLink: 'https://contractwolf.io/projects/powervault',
  },
  {
    id: 'POWPAD',
    address: '0x87AF19115D5e140ae95dc3307b25AA25E4964f70',
    isActive: true,
    name: 'POWPAD',
    imagePath: '../img/POWPAD.png',
    bannerImgName: 'powpad_banner.jpg',
    description:
      'Powpad is a community-driven token/NFT tier ecosystem that offers IGO (Initial Game Offering) and INO (Initial NFT Offering) focused on empowering high-quality web3 games, NFT and metaverse projects with gamified use-cases, enriching crypto-user’s experience in the ETHW blockchain.',
    boosterNFT: '0xe48B4261dCD213603bb4a6b85E200C54510CAf50',
    lpToken: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    MUTATION_ADDRESS: '0xD760b855E3853Ff97746B6fD0F0059aF61DBAd19',
    lpTokenSymbol: 'UNIW',
    lpTokenDecimal: 18,
    tokenAddress: '0x552B0c29966A4B90002ffd3DAA8F107439d242E7',
    tokenName: 'POWPAD',
    tokenSymbol: 'PP',
    totalSupply: '1.000,000,000',
    idoSupply: '200,000,000',
    lockedInLiquidity: '40',
    burnedAmount: 30,
    teamVesting: '5 weeks',
    tokenDecimals: 18,
    startBlock: 1667874037,
    endBlock: 1667874941,
    harvestBlock: 1800,
    raisingAmount: 80000,
    offeringAmount: 200000000,
    maxParticipationAmount: 1000,
    minParticipationAmount: 1,
    startTimestamp: 1668070800,
    endTimestamp: 1668114000,
    projectSiteUrl: 'http://powpad.network/',
    telegramUrl: 'http://t.me/powpad',
    twitterUrl: 'https://twitter.com/powlaunchpad',
    auditUrl: 'https://contractwolf.io/projects/powpad',
    docsUrl: 'https://medium.com/@powpadlaunchpad',
    isKyc: true,
    isAudited: true,
    auditLink: 'https://contractwolf.io/projects/powpad',
    method: 'HARDCAP',
    category: 'NFT LAUNCHPAD',
  },
];

const IDODataARB = [
  {
    id: 'UNIA',
    address: '0x90E5701A6426458A35c29123d51e2cA1C5f6f445',
    isActive: true,
    name: 'UNIA',
    imagePath: '../img/UNIA.png',
    bannerImgName: 'unia-banner.jpg',
    description:
      'UNIA Farms is the newest expansion in the Uniw Labs ecosystem, bringing the best of our developments to Arbitrum. The UNIA launch will feature farms, launch pools, locked farms and launchpads for projects looking to expand in the Arbitrum space.',
    boosterNFT: '0x052D5f0C3157719c44d9c3B8CCe87584E0C985C4',
    lpToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    MUTATION_ADDRESS: '',
    lpTokenSymbol: 'WETH',
    lpTokenDecimal: 18,
    tokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    tokenName: 'UNIA',
    tokenSymbol: 'UNIA',
    totalSupply: '5,000,000',
    idoSupply: '50,000',
    lockedInLiquidity: '100',
    burnedAmount: 0,
    teamVesting: 'No Team Tokens',
    tokenDecimals: 18,
    startBlock: 1672765200,
    endBlock: 1672851600,
    harvestBlock: 1800,
    raisingAmount: 20,
    offeringAmount: 50000,
    maxParticipationAmount: 1,
    minParticipationAmount: 0.01,
    startTimestamp: 1672765200,
    endTimestamp: 1672851600, 
    projectSiteUrl: 'http://uniwlabs.com/',
    telegramUrl: 'http://t.me/uniwswap',
    twitterUrl: 'https://twitter.com/uniwswap',
    auditUrl: 'https://contractwolf.io/projects/uniwswap',
    docsUrl: 'https://medium.com/@uniwswap',
    isKyc: true,
    isAudited: true,
    auditLink: 'https://contractwolf.io/projects/uniwswap',
    method: 'OVERFLOW',
    category: ' WEB 3.0 ECOSYSTEM',
  },
];

export const IDOData = () => {
  const savedChainId = window.localStorage.getItem('chainId');
  if (!savedChainId) {
    window.localStorage.setItem('chainId', '10001');
    window.location.reload();
  }
  if (+savedChainId === Networks.ETHW) return IDODataETHW;
  else if (+savedChainId === Networks.ARBITRUM) return IDODataARB;
};
