import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { Message } from '../../hooks/use-notistake';

const SnackMessage = forwardRef<HTMLDivElement, { id: string | number; message: Message }>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [, setIsCopy] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const getIcon = (severity: any) => {
    switch (severity) {
      case 'error':
        return <ErrorIcon color="inherit" />;
      case 'info':
        return <InfoIcon color="inherit" />;
      case 'success':
        return <CheckCircleIcon color="inherit" />;
      case 'warning':
        return <WarningIcon color="inherit" />;
      default:
        return <div />;
    }
  };

  return (
    <SnackbarContent ref={ref} className="as_root">
      <Card className={`as_card as${props.message.severity}`}>
        <CardActions className="asactionRoot">
          {getIcon(props.message.severity)}
          <Typography variant="subtitle2" className="as_pp">
            {props.message.text}
          </Typography>
          <div className="asicons">
            {props.message.error && (
              <IconButton aria-label="Show more" className="asexpand" onClick={handleExpandClick}>
                <ExpandMoreIcon color="inherit" />
              </IconButton>
            )}
            <IconButton className="asexpand" onClick={handleDismiss}>
              <CloseIcon color="inherit" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className="ascollapse">
            <CopyToClipboard text={JSON.stringify(props.message.error)} onCopy={() => setIsCopy(true)}>
              <Button size="small" className="asbutton">
                <CheckCircleIcon className="ascheckIcon" />
                Copy to clipboard
              </Button>
            </CopyToClipboard>
            <div className="aserrorWrap">
              <Typography>Error message: </Typography>
              <Typography className="aserrorText">{JSON.stringify(props.message.error, null, 2)}</Typography>
            </div>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

export default SnackMessage;
