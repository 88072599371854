import { ethers } from 'ethers';

import { BoosterToken, IDOAbi, MutationABI } from '../abi';
import IDOnftRarities from '../data/IDONFTRarities';

export default class IdoHelper {
  public contract;
  public idoAddress;
  public provider;

  constructor(contractAddress: string, public providerVal) {
    this.provider = providerVal;
    this.idoAddress = contractAddress;
    this.contract = new ethers.Contract(contractAddress, IDOAbi, providerVal);
  }

  public async totalAmount() {
    const totalAmount = Number(await this.contract.totalAmount());
    return totalAmount;
  }

  public async userInfo(address) {
    const userInfo = await this.contract.userInfo(address);
    return userInfo;
  }

  public async getUserAllocation(address) {
    const getUserAllocation = Number(await this.contract.getUserAllocation(address)) / 1e6;
    return getUserAllocation;
  }

  public async getUsersRemainingParticipationAmount(address) {
    const getUsersRemainingParticipationAmount = Number(await this.contract.getUsersRemainingParticipationAmount(address));
    return getUsersRemainingParticipationAmount;
  }

  public async getAddressListLength() {
    const getAddressListLength = Number(await this.contract.getAddressListLength());
    return getAddressListLength;
  }

  public async getMaxParticipationAmountOfUser(address) {
    const getMaxParticipationAmountOfUser = Number(await this.contract.getMaxParticipationAmountOfUser(address));
    return getMaxParticipationAmountOfUser;
  }

  public async getOfferingAmount(address) {
    const getOfferingAmount = Number(await this.contract.getOfferingAmount(address));
    return getOfferingAmount;
  }

  public async deposit(amount) {
    const tx = await this.contract.deposit(amount);
    return tx;
  }

  public async unstakeNFT() {
    const tx = await this.contract.unstakeNFT();
    return tx;
  }

  public async stakeNFT(boosterTokenAddress, nftId) {
    const tx = await this.contract.stakeNFT(boosterTokenAddress, nftId);
    return tx;
  }

  public async harvest() {
    const tx = await this.contract.harvest();
    return tx;
  }

  public async startBlock() {
    const startBlock = Number(await this.contract.startBlock());
    return startBlock;
  }

  public async endBlock() {
    const endBlock = Number(await this.contract.endBlock());
    return endBlock;
  }

  public async harvestDelayBlocks() {
    const endBlock = Number(await this.contract.harvestDelayBlocks());
    return endBlock;
  }

  public async getNftDetails(boosterTokenAddress: string, address: string, MUTATION_ADDRESS: string) {
    const getBoost = await this.contract.getBoost(address);
    let nftId = Number(getBoost[1]);

    if (nftId === 0) {
      return null;
    }

    const mutationContract = new ethers.Contract(MUTATION_ADDRESS, MutationABI, this.provider);
    const isMuted = await mutationContract.isMutated(nftId);
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, this.provider);
    // const tx = await this.contract.stakeNFT(boosterTokenAddress, nftId);
    const nftType = await boosterTokenContract.getNftType(nftId);

    const nftRarity = IDOnftRarities(nftType);
    const nftDetails = IDOnftRarities(nftType);
    const image = isMuted
      ? 'https://uniwpunk-mutant-nfts.s3.amazonaws.com/' + nftId + '.png'
      : 'https://uniwpunk-nfts.s3.amazonaws.com/' + nftId + '.png';
    return { ...nftDetails, id: nftId, image, rarityDetails: nftRarity };
  }
}
