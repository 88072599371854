import './assets/css/App.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Footer from './elements/Footer';
import Header from './elements/Header';
import { useWeb3Context } from './hooks';
import FaqPage from './pages/FaqPage';
import TOSPage from './pages/TOS';
import Farm from './pages/Farm';
import StakingPool from './pages/StakingPool';

import KingPage from './pages/KingPage';
import IdoPage from './pages/IdoPage';
import PastIdoPage from './pages/PastIdoPage';
import RevenueSharing from './pages/RevenueSharing';
import Dashboard from './pages/Dashboard';
import Staking from './pages/Staking';

function App() {
  const { connect, hasCachedProvider } = useWeb3Context();

  useEffect(() => {
    if (hasCachedProvider()) {
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/farm" element={<Farm />} />
        <Route path="/stakingPool" element={<StakingPool />} />

        <Route path="/faq" element={<FaqPage />} />
        <Route path="/tos" element={<TOSPage />} />

        <Route path="/kingpage" element={<KingPage />} />
        <Route path="/" element={<Dashboard />} />

        <Route path="/launchpad/:idoaddress" element={<IdoPage />} />
        <Route path="/launchpad" element={<IdoPage />} />
        <Route path="/past-launchpad" element={<PastIdoPage />} />
        <Route path="/revenue-sharing" element={<RevenueSharing />} />
        <Route path="/conversiontool" element={<Staking />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
