import { Networks } from './../constants/blockchain';
const switchRequest = (chainID) => {
  return window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: chainID === Networks.ETHW ? '0x2711' : '0xA4B1' }],
  });
};

const addChainRequest = (chainID) => {
  return window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      chainID === Networks.ETHW
        ? {
            chainId: '10001',
            chainName: 'ETHW Mainnet',
            // rpcUrls: ['https://ethw-mainnet.nodereal.io/v1/c0a62e8fbbb84363bd076093071905ce'],
            // rpcUrls: ['https://ethw-mainnet.nodereal.io/v1/660034ac40d64d7cb4ff4ac32d2758e4'], // public
            rpcUrls: ['https://mainnet.ethereumpow.org'], // public
            blockExplorerUrls: ['https://mainnet.ethwscan.com'],
            nativeCurrency: {
              name: 'ETHW',
              symbol: 'ETHW',
              decimals: 18,
            },
          }
        : {
            chainId: '42161',
            chainName: 'ARBITRUM Mainnet',
            rpcUrls: ['https://arb1.arbitrum.io/rpc'],
            blockExplorerUrls: ['https://arbiscan.io/'],
            nativeCurrency: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: 18,
            },
          },
    ],
  });
};

export const swithNetwork = async (chainID) => {
  if (window.ethereum) {
    try {
      await switchRequest(chainID);
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          await addChainRequest(chainID);
          await switchRequest(chainID);
        } catch (addError) {
          console.log(error);
        }
      }
      console.log(error);
    }
  }
};
