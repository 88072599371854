import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box, Grid } from '@mui/material';
import type { AccordionProps } from '@mui/material/Accordion';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import type { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FaqPage() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className="faq_page_box">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box className="gird_box_one">
              <Typography component="h3">Frequently Asked Questions</Typography>
              <Typography component="p">Find the answers to the most common questions we get asked on a daily basis.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className="faq_page_two">
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>What is UniwDex?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    UniwDex is an improved fork of Uniswap, based on Proof of Work ethereum chain.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography className="ttle_hedng">What are the swapping fees?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    We have the lowest swapping fees on all the ETHW Chain. Pay only a 0.17% fee instead of 0.30%. You can save up to 40% in
                    fees commision in every trade.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>What is UNIW Token?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    Is our native fixed supply, deflationary token. The maximum supply is 50M , with only a 10% premined used to form
                    liquidity and locked for 6 months.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                  <Typography>Why UNIW is deflationary?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    On every swap using UNIW a 3% sell tax is applied in order to reduced the circulating supply to keep the price healthy.
                    Be sure to set the slippage to at least 3%.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography>What are the NFTW Boost?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    The NFTW are our unique collection of 10000 NFT , based on 6 different rarity. You can trade your NFTW or use them in
                    our farm to boost your rewards.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography>What is the ETHW Airdrop?</Typography>
                  <Box className="bg_img_mains" />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="p_color">
                    We monthly distribuite the accumulated fees to all the UNIW stakers in form of ETHW token.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
