import { BigNumber, ethers } from 'ethers';
import { platformTokenToken } from '../abi';
import { getAddresses } from '../constants';
import { useWeb3Context } from '../hooks';
import { cacheFn } from './cacheFn';
import { convertFromWei } from './convert-wei';
import GetTokenPrice from './get-bork-price';
import { useEffect, useState } from 'react';
import getApiCanceller from './ApiCanceller';

export default function getFooterData(provider, chainID) {
  const { platformToken, platformTokenLPAddress, defaultTokenPrice } = getAddresses(chainID);
  const platformTokenContract = new ethers.Contract(platformToken, platformTokenToken, provider);
  const maxSupply = platformTokenContract.MAX_SUPPLY();
  const burnedTokenVal1 = platformTokenContract.balanceOf('0x000000000000000000000000000000000000dEaD');

  const totalSupply = platformTokenContract.totalSupply();
  const price = platformTokenLPAddress === '' ? defaultTokenPrice : GetTokenPrice(provider);

  return {
    price: price,
    maxSupply: maxSupply.then((data) => convertFromWei(data.toString())),
    burnedToken: burnedTokenVal1.then((data: BigNumber) => (+convertFromWei(data.toString())).toFixed(0)),
    totalSupply: totalSupply.then((data) => (+convertFromWei(data.toString())).toFixed(0)),
  };
}

export function getFooterDataCached(provider, chainID) {
  return cacheFn('GetFooterDataCached', async () => getFooterData(provider, chainID), 15000);
}

export function useFooterData() {
  const { provider, chainID } = useWeb3Context();

  const [price, setPrice] = useState(0);
  const [burnedToken, setBurned] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [remainingSupply, setRemainingSupply] = useState(0);

  useEffect(() => {
    const { cancel, verifier, errorHandler } = getApiCanceller();
    getFooterDataCached(provider, chainID).then(async (res) => {
      Promise.all([res.maxSupply, res.burnedToken, res.totalSupply, res.price])
        .then(verifier)
        .then(([maxSupply, burned, totalSupply, price]) => {
          setMaxSupply(maxSupply);
          setPrice(price);
          setBurned(burned);
          setTotalSupply(totalSupply);
          const marketCapValue = (totalSupply - burned) * price;
          setMarketCap(marketCapValue);
          setRemainingSupply(maxSupply - totalSupply);
        })
        .catch(errorHandler);
    });
    return cancel;
  }, [chainID, provider]);

  return {
    price,
    marketCap,
    maxSupply,
    totalSupply,
    burnedToken,
    remainingSupply,
  };
}
