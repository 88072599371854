import { Box, Button, CircularProgress, Grid, LinearProgress, Skeleton, Typography } from '@mui/material';
import { ethers } from 'ethers';
import { useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { ERC20Contract } from '../abi';
import ConnectMenu from '../component/connect-button';
import IDOPopUpModel from '../component/ido/IdoPopUpModel';
import MarketData from '../component/marketData/marketData';
import { Networks } from '../constants';
import { IDOData } from '../data/IdoData';
import IdoHelper from '../helpers/idoHelper';
import { useWeb3Context } from '../hooks';
import { useAsyncFnWithNotistake } from '../hooks/use-notistake';
import { useAsyncRender } from '../hooks/useApi';
import useRefresh from '../hooks/useRefresh';
import { convertToWei } from '../utils/convert-wei';
import { useFooterData } from '../utils/getFooterData';
import { toFixed } from '../utils/tofixed4';

export default function IdoPage() {
  const stakePopupRef = useRef<any>();
  const { idoaddress }: any = useParams();
  const { provider, address, chainID } = useWeb3Context();
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [userContribution, setUserContribution] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [userRemainngParticipationAmount, setUserRemainngParticipationAmount] = useState(0);
  const [totalusers, setTotalUsers] = useState(0);
  const [maxBal, setMaxBal] = useState(0);
  const [amount, setAmount] = useState(0);
  const [allowance, setAllowance] = useState(false);
  const [allowanceLoading, setAllowanceLoading] = useState(true);
  const [claimed, setClaimed] = useState(false);
  // const [startBlock, setStartBlock] = useState(0);
  // const [endBlock, setEndBlock] = useState(0);
  // const [endTimestamp, setEndTimestamp] = useState(0);
  // const [endTimestampClaim, setEndTimestampClaim] = useState(0);
  // const [startTimestamp, setStartTimestamp] = useState(0);
  const [OfferingAmount, setOfferingAmount] = useState(0);

  const [MaxParticipationAmount, setMaxParticipationAmount] = useState(0);

  const withNotistake = useAsyncFnWithNotistake();

  const { ultraRefresh } = useRefresh();

  const { price: tokenPrice } = useFooterData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ido = useMemo(
    () => IDOData().find((ido) => (idoaddress ? ido.address.toLowerCase() === idoaddress.toLowerCase() : ido.isActive)),
    [idoaddress]
  );

  const iofContract = useMemo(() => new IdoHelper(ido.address, provider.getSigner()), [ido.address, provider]);
  const currencyContract = useMemo(() => new ethers.Contract(ido.lpToken, ERC20Contract, provider.getSigner()), [ido.lpToken, provider]);
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';

  useEffect(() => {
    const status = { cancelled: false };
    Promise.all([
      iofContract.totalAmount(),
      iofContract.userInfo(address),
      iofContract.getUserAllocation(address),
      iofContract.getUsersRemainingParticipationAmount(address),
      iofContract.getAddressListLength(),
      iofContract.getMaxParticipationAmountOfUser(address),
      iofContract.getOfferingAmount(address),
    ]).then(
      ([totalAmount, userInfo, userAllocation, remainParticipationAmount, totalParticipated, MaxParticipationAmount, OfferingAmount]) => {
        if (status.cancelled) return;
        setClaimed(userInfo[1]);
        const totalAmountValue = totalAmount / 10 ** ido.lpTokenDecimal;
        setTotalAmount(totalAmountValue);
        setPercent((totalAmountValue / ido.raisingAmount) * 100);
        setUserContribution(Number(userInfo[0]) / 10 ** ido.lpTokenDecimal);

        setTotalUsers(totalParticipated);
        setUserRemainngParticipationAmount(remainParticipationAmount / 10 ** ido.lpTokenDecimal);
        setMaxParticipationAmount(MaxParticipationAmount / 10 ** ido.lpTokenDecimal);
        setOfferingAmount(OfferingAmount / 10 ** ido.tokenDecimals);
      }
    );

    return () => {
      status.cancelled = true;
    };
  }, [address, iofContract, ultraRefresh, ido, currencyContract]);

  // useEffect(() => {
  //   const status = { cancelled: false };
  //   (async () => {
  //     const currBlock = await provider.getBlockNumber();
  //     let startTimestampVal;
  //     if (currBlock < ido.startBlock) {
  //       const diff = ido.startBlock - currBlock;
  //       startTimestampVal = Date.now() + diff * 13 * 1000;
  //     } else if (currBlock >= ido.startBlock) {
  //       const blockinfo = await provider.getBlock(ido.startBlock);
  //       startTimestampVal = blockinfo?.timestamp * 1000;
  //     }
  //     if (status.cancelled) return;
  //     setStartTimestamp(startTimestampVal);
  //   })();
  //   return () => {
  //     status.cancelled = true;
  //   };
  // }, [address, iofContract, ido, provider]);

  const startTimestamp = ido.startTimestamp * 1000;
  const endTimestamp = ido.endTimestamp * 1000;
  const endTimestampClaim = (ido.endTimestamp + ido.harvestBlock) * 1000;

  // useEffect(() => {
  //   const status = { cancelled: false };
  //   (async () => {
  //     const currBlock = await provider.getBlockNumber();
  //     let endTimestampVal;

  //     if (currBlock < ido.endBlock) {
  //       const diff = ido.endBlock - currBlock;
  //       endTimestampVal = Date.now() + diff * 13 * 1000;
  //     } else if (currBlock >= ido.endBlock) {
  //       const blockinfo = await provider.getBlock(ido.endBlock);
  //       endTimestampVal = blockinfo?.timestamp * 1000;
  //     }

  //     if (status.cancelled) return;
  //     setEndTimestamp(endTimestampVal);
  //   })();
  //   return () => {
  //     status.cancelled = true;
  //   };
  // }, [address, iofContract, ido, provider]);

  // useEffect(() => {
  //   const status = { cancelled: false };
  //   (async () => {
  //     const currBlock = await provider.getBlockNumber();
  //     let endTimestampCalimVal;
  //     if (currBlock < ido.endBlock + ido.harvestBlock) {
  //       const diff = ido.endBlock - currBlock + ido.harvestBlock;
  //       endTimestampCalimVal = Date.now() + diff * 13 * 1000;
  //     } else if (currBlock >= ido.endBlock + ido.harvestBlock) {
  //       const blockinfo = await provider.getBlock(ido.endBlock + ido.harvestBlock);
  //       endTimestampCalimVal = blockinfo?.timestamp * 1000;
  //     }

  //     if (status.cancelled) return;
  //     setEndTimestampClaim(endTimestampCalimVal);
  //   })();
  //   return () => {
  //     status.cancelled = true;
  //   };
  // }, [address, iofContract, ido, provider]);

  useEffect(() => {
    (async () => {
      const maxBalance = await currencyContract.balanceOf(address);
      const allowvalue = await currencyContract.allowance(address, ido.address);
      setAllowance(allowvalue > 0 ? true : false);
      setAllowanceLoading(false);
      setMaxBal(Number(maxBalance) / 10 ** ido.lpTokenDecimal);
    })();
  }, [address, currencyContract, ultraRefresh, ido.address, ido.lpTokenDecimal]);

  const onMaxClick = async () => {
    setAmount(maxBal > userRemainngParticipationAmount ? userRemainngParticipationAmount : maxBal);
  };

  const onApprove = async () => {
    try {
      setLoading(true);
      await withNotistake('Approving', async () => {
        const approveTx = await currencyContract.approve(ido.address, ethers.constants.MaxUint256);
        await approveTx.wait();
      });
      setAllowance(true);
      setAllowanceLoading(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onBuy = async () => {
    try {
      setLoading(true);
      await withNotistake('Buying', async () => {
        console.log(convertToWei(amount.toString(), ido.lpTokenDecimal == 6 ? 'MWei' : 'ether'));
        const buyTx = await iofContract.deposit(convertToWei(amount.toString(), ido.lpTokenDecimal == 6 ? 'MWei' : 'ether'));
        await buyTx.wait();
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onHarvest = async () => {
    try {
      setLoading(true);
      await withNotistake('Harvesting', async () => {
        const harvestTx = await iofContract.harvest();
        await harvestTx.wait();
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onUnstakeNFT = async (shouldUpdateData = false) => {
    await withNotistake('Unstaking NFT', async () => {
      const tx = await iofContract.unstakeNFT();
      await tx.wait();
    });
  };

  const onStakeNFT = async (nft) => {
    await withNotistake('Staking NFT', async () => {
      const tx = await iofContract.stakeNFT(ido.boosterNFT, nft.token_id);
      await tx.wait();
    });
  };

  const openStakePopup = () => {
    if (stakePopupRef.current) {
      stakePopupRef.current?.openPopup();
    }
  };

  const nftDetailsAPI = useAsyncRender(
    () => iofContract?.getNftDetails(ido.boosterNFT, address, ido.MUTATION_ADDRESS),
    [iofContract, address]
  );

  
  return (
    <>
      <Box className="tknlnch_main_bx">
        <Box className="tknlnch_innr_bx">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <Box className="hdng_grid">
                <Typography component="h2">
                  <span>{tokenName}</span> Pad
                  <p>
                    Participate in <span>{ido.tokenName}</span> launch with <span>{ido.tokenName === "UNIA" ? "WETH" : "UNIA" }</span> tokens
                  </p>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <MarketData />
            </Grid>
          </Grid>
        </Box>
        {/* 
        #RAVI RETURN THIS CODE ONLY IF IDO.METHOD === HARDCAP
        <Box className="alertbox nftstake">
          <Box className="alertinside">
            <Typography className="alertinfo">
              <FaExclamationTriangle />
              This is a HARDCAP Launchpad. First arrive first served. Transaction fails are due to overfills of the launchpad.
            </Typography>
          </Box>
        </Box> */}
        <Box className="idopage_bx_min">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} className="pading_grid_o">
              <Box className="paper_money_min_bx">
                <Box
                  className="papermoney_dao_bx"
                  style={{
                    backgroundImage: 'url(/img/ido/' + ido.bannerImgName + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Box className="kyc_btn_prnt">
                    {ido.isKyc && <Button className="kyc_btn">KYC</Button>}
                    {ido.isAudited && (
                      <Button className="kyc_btn" href={ido.auditLink} target="_blank">
                        AUDITED
                      </Button>
                    )}
                  </Box>
                  <img src={ido.imagePath} alt="" className="close_crcl_img_ppup" />
                  <Typography component="h3">{ido.name}</Typography>
                  {startTimestamp && endTimestamp ? (
                    startTimestamp > Date.now() ? (
                      <Typography>
                        Starts in <Countdown date={startTimestamp} />
                      </Typography>
                    ) : endTimestamp > Date.now() ? (
                      <Typography>
                        Ends in <Countdown date={endTimestamp} />
                      </Typography>
                    ) : (
                      <Typography>Ended</Typography>
                    )
                  ) : (
                    <Typography>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={210} />
                    </Typography>
                  )}
                </Box>
                <Box className="pogres_bootenm_part">
                  <Box className="pogres_text">
                    <Typography className="Progress_text">Progress</Typography>
                    <Typography className="grn_text">{percent.toFixed(2)}%</Typography>
                  </Box>
                  <Box className="pogre_bx">
                    <LinearProgress variant="determinate" value={percent > 100 ? 100 : percent} />
                    {/* {percent ? <Slider defaultValue={percent} aria-label="Default" valueLabelDisplay="auto" /> : ''} */}
                  </Box>
                  <Box className="pogres_text">
                    <Typography className="Progress_uniw">
                      {totalAmount.toFixed(2)} {ido.lpTokenSymbol}
                    </Typography>
                    <Typography className="Progress_uniw">
                      {ido.raisingAmount} {ido.lpTokenSymbol}
                    </Typography>
                  </Box>
                  <Box className="sos_btn_paart">
                    <Box className="pogres_text">
                      <Typography className="contribution_text">My Contribution</Typography>
                      <Typography className="contribution_text">
                        {userContribution} {ido.lpTokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">My Limit Contribution</Typography>
                      <Typography className="contribution_text">
                        {MaxParticipationAmount} {ido.lpTokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Boost Limit Contribution</Typography>
                      <Button className="Plus_btn">
                        {nftDetailsAPI(
                          <Skeleton variant="rectangular" height={34} width={48} />,
                          (nftData) => (
                            <>
                              <Button onClick={openStakePopup} sx={{ fontSize: 14, color: 'white' }}>
                                + {(nftData && nftData?.rarityDetails?.boostRate - 100 + '%') || '0%'}
                              </Button>
                            </>
                          ),
                          (err) => (
                            <Typography>Error...</Typography>
                          )
                        )}
                      </Button>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">My Reserved Tokens</Typography>
                      <Typography className="contribution_text">
                        {OfferingAmount} {ido.tokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Total Contributors</Typography>
                      <Typography className="contribution_text">{totalusers} Participants</Typography>
                    </Box>
                  </Box>
                  <Box className="imput_btn">
                    {address ? (
                      <>
                        {' '}
                        {endTimestamp > Date.now() &&
                          (!!endTimestamp && !allowanceLoading ? (
                            <>
                              <Box className="input_btn_box">
                                <input type="number" placeholder="0" value={amount} onChange={(e) => setAmount(+e.target.value)} />
                                <Button className="max_btn" onClick={onMaxClick}>
                                  MAX
                                </Button>
                              </Box>
                              {allowance ? (
                                <Button
                                  className={
                                    startTimestamp > Date.now() ||
                                    userRemainngParticipationAmount < amount ||
                                    amount <= 0 ||
                                    maxBal < amount
                                      ? 'alreadyClaimed'
                                      : 'buy'
                                  }
                                  onClick={onBuy}
                                  disabled={
                                    userRemainngParticipationAmount < amount ||
                                    amount <= 0 ||
                                    startTimestamp > Date.now() ||
                                    maxBal < amount
                                  }
                                >
                                  {' '}
                                  {userRemainngParticipationAmount < amount ? (
                                    'User Limit Reached'
                                  ) : loading ? (
                                    <CircularProgress size={24} className="NotistakeLoaderButton_Loader" />
                                  ) : startTimestamp > Date.now() ? (
                                    <>Not Started Yet</>
                                  ) : maxBal < amount ? (
                                    'Not Enough Balance'
                                  ) : (
                                    'Buy'
                                  )}
                                </Button>
                              ) : (
                                <Button className="approve" onClick={onApprove}>
                                  {loading ? <CircularProgress size={24} className="NotistakeLoaderButton_Loader" /> : 'Approve'}
                                </Button>
                              )}
                            </>
                          ) : (
                            <>
                              <Skeleton variant="rectangular" height={40} />
                              <br />
                              <Skeleton variant="rectangular" height={40} />
                            </>
                          ))}
                        {endTimestamp < Date.now() &&
                          (!!endTimestamp ? (
                            claimed ? (
                              <Button className="alreadyClaimed" onClick={onHarvest} disabled={claimed}>
                                Already Claimed
                              </Button>
                            ) : endTimestamp < Date.now() && Date.now() < endTimestampClaim ? (
                              <Button className="alreadyClaimed" onClick={onHarvest} disabled={true}>
                                Claim in &nbsp;
                                <Countdown date={endTimestampClaim} />
                              </Button>
                            ) : (
                              <Button className="approve" onClick={onHarvest} disabled={claimed}>
                                {loading ? <CircularProgress size={24} className="NotistakeLoaderButton_Loader" /> : 'Claim'}
                              </Button>
                            )
                          ) : (
                            <Skeleton variant="rectangular" height={40} />
                          ))}
                      </>
                    ) : (
                      <ConnectMenu />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} className="pading_grid_o">
              <Box className="papermoney_prnt_box">
                <Box className="paper_dao_box_hed">
                  <Typography>{ido.name}</Typography>
                  <Box className="hed_smol_btn">
                    <Button className="right_btn">{ido.category}</Button>
                  </Box>
                </Box>
                <Box className="taeli_ruond_ic">
                  <a href={ido.projectSiteUrl} target="_blank" rel="noreferrer">
                    {' '}
                    <img src="../img/lend_roun_ic.png" alt="" className="close_crcl_img_ppup" />
                  </a>
                  <a href={ido.docsUrl} target="_blank" rel="noreferrer">
                    {' '}
                    <img src="../img/docs_ic.png" alt="" className="close_crcl_img_ppup" />
                  </a>
                  <a href={ido.telegramUrl} target="_blank" rel="noreferrer">
                    {' '}
                    <img src="../img/teligram_ic.png" alt="" className="close_crcl_img_ppup" />
                  </a>
                  <a href={ido.twitterUrl} target="_blank" rel="noreferrer">
                    {' '}
                    <img src="../img/twitter_ic.png" alt="" className="close_crcl_img_ppup" />
                  </a>
                  <a href={ido.auditUrl} target="_blank" rel="noreferrer">
                    {' '}
                    <img src="../img/audit_ic.png" alt="" className="close_crcl_img_ppup" />
                  </a>
                </Box>
                <Box className="right_text_bx_p">
                  <Typography>{ido.description}</Typography>
                </Box>
                <Box className="ido_details_box">
                  <Typography component="h2">IDO Details</Typography>
                  <Box className="sos_btn_paart">
                    {/* <Box className="pogres_text">
                      <Typography className="contribution_text">IDO Contract Address</Typography>
                      <Typography className="contribution_text chenj_color">
                        <a
                          href={'https://www.oklink.com/en/ethw/address/' + ido.address}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'white' }}
                        >
                          {ido.address}
                        </a>
                      </Typography>
                    </Box> */}
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Token Address</Typography>
                      <Typography className="contribution_text chenj_color">
                        <a
                          href={ chainID === Networks.ETHW ? 'https://www.oklink.com/en/ethw/address/'  + ido.tokenAddress : 'https://arbiscan.io/address/' + ido.tokenAddress}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'white' }}
                        >
                          {ido.tokenAddress}
                        </a>
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Token Name</Typography>
                      <Typography className="contribution_text">
                        {ido.tokenName} (${ido.tokenSymbol})
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Total Supply</Typography>
                      <Typography className="contribution_text">
                        {ido.totalSupply} {ido.tokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Token in IDO</Typography>
                      <Typography className="contribution_text">
                        {ido.idoSupply} {ido.tokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Token initial price</Typography>
                      <Typography className="contribution_text">
                        1 {ido.tokenSymbol} = <span> 0.5 $</span>
                        {/* {toFixed(ido.raisingAmount / ido.offeringAmount, 4)} UNIW{' '}
                        <span> ≈ {toFixed((ido.raisingAmount / ido.offeringAmount) * tokenPrice, 4)}$</span> */}
                      </Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Locked in Liquidity</Typography>
                      <Typography className="contribution_text">{ido.lockedInLiquidity}%</Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Burned Raised UNIW</Typography>
                      <Typography className="contribution_text">{ido.burnedAmount}%</Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">Team Vesting Schedule</Typography>
                      <Typography className="contribution_text">{ido.teamVesting}</Typography>
                    </Box>
                    <Box className="pogres_text">
                      <Typography className="contribution_text">IDO Method</Typography>
                      <Typography className="contribution_text">{ido.method}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <a href="/past-launchpad" className="view_past_link">
                View Past IDO {'->'}
              </a>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pading_grid_o">
              <Box className="borde_bx_laft">
                <Box className="borde_bx_laft_iner_bnx">
                  <Box className="head_p_h">
                    <Typography component="h2" className="hed_text">
                      {tokenName} Pad - The {tokenName} Ecosystem Accelerator
                    </Typography>
                    <Typography className="hed_g">Only Trusted and Verified Projects</Typography>
                  </Box>
                  <Box className="last_part_frt"></Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box className="hov_to_plya">
                        <Typography component="h3">What is {tokenName} Pad?</Typography>
                        <Typography>
                          {tokenName} Pad is the native Launchpad of UniwDex, the leading DEX on Ethereum PoW. Our Launchpad is the place where
                          you can discover new high quality projects that have been verified by the Uniw team, with the goal of improving
                          the output quality of projects joining the chain. Put your UniW and UniW Punks to work in order to discover new
                          opportunities and expand the Ethereum PoW Chain.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Box className="hov_to_plya">
                        <Typography component="h3">How to join the launchpad?</Typography>
                        <Typography>
                          Joining our Launchpads is easy. Simply commit your desired amount of UNIW, and at the end of the Launchpad you
                          will be able to claim your new tokens. The amount of tokens you receive will be based on the weight your assets
                          have in the launchpad. If you would like to further increase your maximum allocation you can use your UniW Punks
                          to receive a boost of up to 100% in allocation, based on the rarity of your Punk.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <IDOPopUpModel
            ref={stakePopupRef}
            ido={ido}
            onStakeNFT={onStakeNFT}
            onUnstakeNFT={onUnstakeNFT}
            boosterTokenAddress={ido.boosterNFT}
            nftDetailsAPI={nftDetailsAPI}
          />
        </Box>
      </Box>
    </>
  );
}
