import React, { useState, useEffect } from 'react';
import { getAddresses } from '../../constants';
import { useWeb3Context } from '../../hooks';

const ParticipateCard = ({ bidAmount, currencyContract }) => {
  const { chainID, address } = useWeb3Context();
  const addresses = getAddresses(chainID);

  const [maxBal, setMaxBal] = useState(0);

  useEffect(() => {
    (async () => {
      const maxBalance = await currencyContract.balanceOf(address);
      const decimal = await currencyContract.decimals();

      setMaxBal(Number(maxBalance) / 10 ** decimal);
    })();
  }, [address, currencyContract, addresses.KOF_ADDRESS]);

  return (
    <div className="kngCntrbx">
      <h4 className="def_h4">Become the next King</h4>
      <img src="./img/Grupo10.png" alt="" style={{ width: '75%' }} />
      <div className="blnc_box readBtn">
        <h6> {(Number(bidAmount) / 1e18).toFixed(1)} UNIW</h6>
        <span>Wallet balance {maxBal.toFixed(2)} UNIW</span>
      </div>
    </div>
  );
};

export default ParticipateCard;
