import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useMemo } from 'react';

import { platformTokenToken } from '../../abi';
import { getAddresses, Networks } from '../../constants';
import { useWeb3Context } from '../../hooks';
import { useAsyncRender } from '../../hooks/useApi';
import getApiCanceller from '../../utils/ApiCanceller';
import { convertFromWei } from '../../utils/convert-wei';
import { useFooterData } from '../../utils/getFooterData';
import getTVL from '../../utils/getTVL';
import { numAbbr } from '../../utils/unit-convert-utils';

export default function MarketData() {
  const { provider, chainID, address } = useWeb3Context();

  const [balance, setBalance] = React.useState('0');
  const { platformToken } = useMemo(() => getAddresses(chainID), [chainID]);
  const tvlAPI = useAsyncRender(() => getTVL(provider, chainID).then((tvlPrice) => ({ tvl: numAbbr(tvlPrice) })), [provider]);
  const { burnedToken, marketCap, maxSupply: dmarketCap, totalSupply, price: tokenPrice } = useFooterData();
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    if (address) {
      const platformTokenContract = new ethers.Contract(platformToken, platformTokenToken, provider);
      verify(platformTokenContract.balanceOf(address)).then((bal: BigNumber) => setBalance(convertFromWei(bal.toString())), errorHandler);
    }
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const addToMetaMask = async () => {
    const tokenAddress = chainID === Networks.ETHW ? '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33' : '';
    const tokenSymbol = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';
    const tokenImage =
      chainID === Networks.ETHW ? 'https://farm.uniwdex.com/img/tokens/UNIW.png' : 'https://farm.uniwdex.com/img/tokens/UNIW.png';
    const tokenDecimals = 18;

    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage, // if you have the image, it goes here
          },
        },
      });
    } catch (error) {
      // handle errors
    }
  };

  return (
    <>
      <Box className="detail_bx">
        <Grid container spacing={0} className="tp_main_grid">
          <Grid item xs={12} sm={4}>
            <Box className="same_style">
              <Typography>TVL</Typography>

              {tvlAPI(
                <Skeleton variant="rectangular" height={34} width={148} />,
                (data) => (
                  <Typography component="h6">${data.tvl}</Typography>
                ),
                (err) => (
                  <Typography component="h6">$0</Typography>
                )
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="same_style">
              <Typography>{tokenName} PRICE</Typography>
              <Typography component="h6">${numAbbr(tokenPrice)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="same_style lft_pddng_bx">
              <Typography>MARKETCAP</Typography>
              <Typography component="h6">${numAbbr(marketCap)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="detail_bx detail_bx_v2">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Box className="same_style">
              <Typography>CIRCULATING SUPPLY</Typography>
              <Typography component="h6">
                {numAbbr(totalSupply - (burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0))}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="same_style lft_pddng_bx">
              <Typography>BURNED {tokenName} </Typography>
              <Typography component="h6">
                {numAbbr(burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className="same_style lft_pddng_bx">
              <Typography>
                <span style={{ display: 'inline-flex' }}>
                  YOUR {tokenName}{' '}
                  <img
                    src="/img/metamask.png"
                    alt="metamask"
                    height={24}
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    onClick={addToMetaMask}
                  />
                </span>
              </Typography>
              <Typography component="h6">{numAbbr(balance)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
