import { Box, Button, Skeleton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ethers } from 'ethers';
import fromExponential from 'from-exponential';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ERC20Contract } from '../../abi';
import ZbetMasterChefHelper from '../../helpers/ZbetMasterChefHelper';
import { useWeb3Context } from '../../hooks';
import { useAsyncRender } from '../../hooks/useApi';
import { convertFromWei } from '../../utils/convert-wei';
import GetTokenPrice, { calculateLpTokenPrice } from '../../utils/get-bork-price';
import type { MoralisNFTModel } from '../../utils/moralis';
import { toFixed, toFixed2 } from '../../utils/tofixed4';
import { getTokenData } from '../../utils/token-data';
import NotistakeLoaderButton from '../NotistakeLoaderButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src="./img/close_circle_ppup.svg" alt="" className="close_crcl_img_ppup" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface StakePopUpModelProps {
  tokenAddress;
  userStaked;
  onStake;
  onUnstake;
  contractAddress;
  boosterTokenAddress;
  tokenPrice;
  stakeName;
  stakeImage;
  farmName;
  farmImage;
  rewardToken;
  aprAPI;
  farmData;
  endInAPI;
}

const StakePopUpModel = (
  {
    tokenAddress,
    userStaked,
    onStake,
    onUnstake,
    contractAddress,
    boosterTokenAddress,
    tokenPrice,
    farmName,
    stakeName,
    stakeImage,
    farmImage,
    rewardToken,
    aprAPI,
    farmData,
    endInAPI,
  }: StakePopUpModelProps,
  ref
) => {
  const { provider, address } = useWeb3Context();
  const [isApproved, setIsApproved] = React.useState(0);
  // const [isNFTApproved, setIsNFTApproved] = React.useState(false);
  const [value, setValue] = React.useState(0);
  // const [nfts, setNfts] = React.useState<MoralisNFTModel[]>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState('0');
  const [, setMaxBalance] = React.useState('0');
  const [unstakeAmount, setUnstakeAmount] = React.useState<any>(0);
  const [rewardPrice, setRewardPrice] = React.useState(0);
  // const [unstakeNft, setUnstakeNft] = React.useState(true);
  // const [nftAlreadyStaked, setNftAlreadyStaked] = React.useState(true);

  const currencyContract = React.useMemo(
    () => new ethers.Contract(tokenAddress, ERC20Contract, provider.getSigner()),
    [tokenAddress, provider]
  );

  useEffect(() => {
    const getPrice = async () => {
      let price;
      if (farmData.rewardToken !== '') {
        price = (await getTokenData(farmData.rewardToken)).price;
      } else if (farmData.LPwithEthw !== '') {
        price = await calculateLpTokenPrice(farmData.LPwithEthw, provider);
      }
      const bal = await currencyContract.balanceOf(address);
      const balance = convertFromWei(bal.toString());
      setMaxBalance(balance);
      setRewardPrice(price);
      const allowed = await currencyContract.allowance(address, contractAddress);
      setIsApproved(+allowed.toString() / 1e18);
      // if (Number(await currencyContract.allowance(address, contractAddress)) > 0) {
      //   setIsApproved(true);
      // } else {
      //   setIsApproved(false);
      // }
    };
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyContract, address]);

  const onMaxClick = async () => {
    const bal = await currencyContract.balanceOf(address);
    const decimalValue = await currencyContract.decimals();
    const decimal = decimalValue === 9 ? 'gwei' : decimalValue === 6 ? 'mwei' : 'ether';
    const balance = toFixed(convertFromWei(bal.toString(), decimal), 6);
    setAmount(balance);
  };

  const onMaxUnstakeClick = async () => {
    const bal = userStaked;
    const decimalValue = await currencyContract.decimals();
    const decimal = decimalValue === 9 ? 'gwei' : decimalValue === 6 ? 'mwei' : 'ether';
    const balance = toFixed(convertFromWei(bal.toString(), decimal), 6);
    setUnstakeAmount(balance);
  };

  const handleClickOpen = async () => {
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openPopup: handleClickOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const stakeClicked = async () => {
    // if (amount > maxBalance) {
    //   dispatch(error({ text: messages.not_enough_balance }));
    // } else {
    await onStake(amount);
    handleClose();
    setAmount('0');
    // }
  };

  const unstakeClicked = async () => {
    if (unstakeAmount > 0) {
      await onUnstake(unstakeAmount);
    }
    setUnstakeAmount(0);
    setOpen(false);
  };

  const onApprove = async () => {
    try {
      const approveTx = await currencyContract.approve(contractAddress, ethers.constants.MaxUint256);
      await approveTx.wait();
      const allowed = await currencyContract.allowance(address, contractAddress);
      setIsApproved(+allowed.toString() / 1e18);
      return approveTx;
    } catch (e) {
      console.log(e);
    }
  };

  const rewardPerDayOnStakeAmount = useAsyncRender(() => {
    const contract = new ZbetMasterChefHelper(contractAddress, provider);
    return contract?.rewardPerDayOnAmount(farmData.pid, +amount);
  }, [address, amount]);

  const rewardPerDayOnUnstakeAmount = useAsyncRender(() => {
    const contract = new ZbetMasterChefHelper(contractAddress, provider);
    return contract?.rewardPerDayOnAmount(farmData.pid, unstakeAmount);
  }, [address, unstakeAmount]);

  return (
    <>
      <Button className="stak_btn" onClick={handleClickOpen}>
        Manage
      </Button>
      <Box className="modal_boost_frst">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="farm_popup_main respons_btr_dilg"
        >
          <Box className="gamble_ppup gamble_ppup_v2 gamble_ppup_v2a">
            <Box className="farm_txt_bxx">
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Box component="h1" className="farmname">
                  {farmName}
                </Box>
              </BootstrapDialogTitle>
              <Box
                className="gamble_txt_img no-decoration"
                component="a"
                target="_blank"
                href={'https://app.uniwdex.com/#/swap?outputCurrency=' + tokenAddress}
              >
                <Typography>
                  Buy Token
                  <img src="./img/arrow_up_icon.svg" alt="" />
                </Typography>
              </Box>
            </Box>

            <Box className="tab_prnt tab_prnt_va">
              <Box sx={{ width: '100%' }}>
                <Box sx={{}}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabs_btn">
                    <Tab label="Stake" {...a11yProps(0)} className="stake_btn_def" />
                    {/* <Tab label="Unstake" disabled={userStaked === 0} {...a11yProps(1)} className="stake_btn_def" /> */}
                    <Tab label="Unstake" {...a11yProps(1)} className="stake_btn_def" />
                  </Tabs>
                </Box>
                <Box className="tbn_bx">
                  <TabPanel value={value} index={0}>
                    <Box className="stake_min_box">
                      <Box className="imput_prnt">
                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)}></input>
                        <Button onClick={onMaxClick}>MAX</Button>
                      </Box>

                      <Box>
                        <Box className="annua_last_bx_prnt">
                          <Box className="two_btn_ls">
                            {isApproved >= +amount ? (
                              <NotistakeLoaderButton
                                disabled={!amount || farmData.endInTimeStamp < Date.now()}
                                onClick={stakeClicked}
                                style={{ maxWidth: 'none' }}
                              >
                                Stake
                              </NotistakeLoaderButton>
                            ) : (
                              <NotistakeLoaderButton
                                msg="Approving"
                                disabled={!amount || farmData.endInTimeStamp < Date.now()}
                                onClick={onApprove}
                                style={{ maxWidth: 'none' }}
                              >
                                Approve
                              </NotistakeLoaderButton>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box className='slect_borde_bx_secnd'>
                        <Typography component="h5">Select boosters</Typography>
                        <Box className="cstm_rd_box cstm_rd_box_btn">
                            <input type="radio" name="booster" id="boster05" />
                            <Button className='btn_las_2'>+2</Button>
                        </Box>
                        <Box className='nft_boost nft_boost_btn_spn nft_boost_btn_spn_va'>
                            <img src="./img/i_140_img_ppup.svg" alt='' />
                            <Typography component='h5' className='difult_unlok_h5'>Unlock up to <span className='boost_gri_text'> 160% </span>APR</Typography>
                        </Box>
                        <DialogActions className='stake_btn_box approve_btn'>
                          <Button onClick={handleClose} className="stake_btn">
                            Approve
                            </Button>
                        </DialogActions>
                      </Box> */}
                    </Box>
                  </TabPanel>
                </Box>
                <Box className="unstake_tabs_pnls">
                  <TabPanel value={value} index={1}>
                    <Box className="unstake_min_box">
                      <Box className="neme_box">
                        <Typography className="name_tex">Name</Typography>
                        <Typography component="h2">My Stakes</Typography>
                      </Box>
                      <Box className="ferx_box">
                        <Box className="ferx_img_bx ">
                          <img height={40} width={40} src={stakeImage || 'img/tokens/UNIW.png'} alt="" />
                          {/* <img src='./img/rex_set_ic.svg' alt='' /> */}
                          <Typography className="frex_p">{stakeName}</Typography>
                        </Box>
                        <Box className="handet_tex">
                          <Typography>
                            {userStaked ? fromExponential(convertFromWei(userStaked.toString())) : 0}
                            <span>${toFixed2((userStaked ? +convertFromWei(userStaked.toString()) : 0) * tokenPrice)}</span>
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="stake_min_box stake_min_box_marg">
                        <Box className="imput_prnt">
                          <input type="text" value={unstakeAmount} onChange={(e) => setUnstakeAmount(e.target.value)}></input>
                          <Button onClick={() => onMaxUnstakeClick()}>MAX</Button>
                        </Box>
                        {/* #LORENZO */}
                      </Box>
                      <DialogActions className="stake_btn_box pool_unstake">
                        <NotistakeLoaderButton onClick={unstakeClicked} className="stake_btn">
                          Unstake Now
                        </NotistakeLoaderButton>
                      </DialogActions>
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </BootstrapDialog>
      </Box>
    </>
  );
};
export default forwardRef(StakePopUpModel);
