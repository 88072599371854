import numberAbbreviate from 'number-abbreviate';

import { toFixed2, toFixed4 } from './tofixed4';

export function numAbbr(num: number | string, currency = true) {
  const decimal = +num > 2 ? 2 : 4;
  let formattedValue = num;
  if (currency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimal,
      currencyDisplay: 'code',
    });

    formattedValue = formatter.format(+num);
    formattedValue = formattedValue.replace('USD', '').trim();
  } else {
    if (decimal === 2) {
      formattedValue = toFixed2(+num);
    } else {
      formattedValue = toFixed4(+num);
    }
    formattedValue = numberAbbreviate(formattedValue, decimal);
    formattedValue = formattedValue.toString().toUpperCase();
  }
  return formattedValue;
}
