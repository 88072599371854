import React, { useState, useMemo } from 'react';
import Popover from '@mui/material/Popover';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useWeb3Context } from '../../hooks';
import KOFHelper from '../../helpers/kofHelper';
import { getAddresses } from '../../constants';

const ActualKing = ({ lastBidder, hasWinner }) => {
  // const forestContract = useForest();
  // const [isClaiming, setIsClaiming] = useState(false);
  // const onClaim = async () => {
  //   setIsClaiming(true);
  //   try {
  //     await claimForest(forestContract, account);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   setIsClaiming(false);
  // };
  const { provider, chainID, address } = useWeb3Context();
  const addresses = getAddresses(chainID);
  const kofContract = useMemo(() => new KOFHelper(addresses.KOF_ADDRESS, provider.getSigner()), [addresses, provider]);
  const [isClaiming, setIsClaiming] = useState(false);


  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    // <Box className="current_king_box">
    //   <img src="img/curent_ic_king.png" alt="" />
    //   <Typography component="h4">Current King</Typography>
    //   {lastBidder && (
    //     <Typography>
    //       Last bidder: {lastBidder.slice(0, 8)}...{lastBidder.slice(-8)}
    //     </Typography>
    //   )}
    //   {hasWinner && address && (
    //     <Button onClick={onClaim} disabled={isClaiming} fullWidth className="kof_btn">
    //       {isClaiming ? <CircularProgress size={24} className="NotistakeLoaderButton_Loader" /> : 'Restart Game'}
    //     </Button>
    //   )}

    //   <Box className="poover_bx_ic_bx">
    //     <Typography
    //       aria-owns={open ? 'mouse-over-popover' : undefined}
    //       aria-haspopup="true"
    //       onMouseEnter={handlePopoverOpen}
    //       onMouseLeave={handlePopoverClose}
    //     >
    //       <img src="img/help_outline_blackdp.svg" alt="" />
    //     </Typography>
    //     <Popover
    //       id="mouse-over-popover"
    //       sx={{
    //         pointerEvents: 'none',
    //       }}
    //       open={open}
    //       anchorEl={anchorEl}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'left',
    //       }}
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'left',
    //       }}
    //       onClose={handlePopoverClose}
    //       disableRestoreFocus
    //     >
    //       <Typography sx={{ p: 1 }}>I use Popover.</Typography>
    //     </Popover>
    //   </Box>
    // </Box>
    <div className="kngRghtbx">
      <img src="/img/king.png" alt="" className="king_imgv2" />
      <img src="/img/elert.png" alt="" className="elert_icon" />
      <div className="kngrgh_content">
        <h3>Current King</h3>
        <div className="wallet_bx">
          <img src="/img/walletic.png" alt="" />
          <span>{lastBidder && lastBidder.slice(0, 8) + '...' + lastBidder.slice(-8)}</span>
        </div>
      </div>
    </div>
  );
};

export default ActualKing;
