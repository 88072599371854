import { Box, Button, Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import MarketData from '../component/marketData/marketData';
import PoolRowMobile from '../component/stakingPool/PoolRowMobile';
import { FarmRowSkeleton } from '../component/stakingPool/FarmRowSkeleton';
import PoolRow from '../component/stakingPool/PoolRow';
import { StakingPoolData } from '../data/StakingPoolData';
import StakingPoolHelper from '../helpers/StakingPoolHelper';
import { useWeb3Context } from '../hooks';
import { useAsyncRender } from '../hooks/useApi';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Networks } from '../constants';

export default function StakingPool() {
  const { chainID } = useWeb3Context();
  const [showOnlyEnded, setShowOnlyEnded] = useState(false);
  const { provider } = useWeb3Context();
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getFilterdData = async () => {
    let data = StakingPoolData().filter(({ ended }) => !ended);

    if (showOnlyEnded) {
      data = StakingPoolData().filter(({ ended }) => !!ended);
    }
    return data;
  };

  const rowsRender = useAsyncRender(getFilterdData, [showOnlyEnded]);

  const harvestAll = async () => {
    const data = await getFilterdData();
    for (const farm of data) {
      try {
        const contract = new StakingPoolHelper(farm.address, provider.getSigner());
        // Note: Remove following await to make transactions work parallelly
        const tx = await contract.unstake(0);
        console.log(tx);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Box className="tknlnch_main_bx">
      {/* <Box
        component='img'
        src='img/tokenlaunch_img.png'
        className='tknlnch_img'
        alt=''
      /> */}
      <Box className="tknlnch_innr_bx">
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <Box className="hdng_grid">
              <Typography component="h2">
                <span>{tokenName}</span> Pools
              </Typography>
              <Typography>
                Earn{' '}
                <span>
                  {rowsRender(<></>, (data) => (
                    <>{data.map((farm) => farm.rewardTokenName).join(', ')}</>
                  ))}{' '}
                </span>
                Tokens by staking your <span>${tokenName}</span>
              </Typography>
              {/* <Box component="img" className="poolsimg" src="/img/Revenue-Pools.png" /> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <MarketData />
          </Grid>
        </Grid>
      </Box>
      <Box className="alertbox">
        <Box className="alertinside">
          <Typography className="alertinfo">
            <FaExclamationTriangle />
            {chainID === Networks.ETHW
              ? 'Keep in mind that  UniwDex is not affiliated with any project listed on our DEX or Syrup Pool Page. '
              : "UNIA is not affiliated with and doesn't recommend the purchase of tokens featured in the staking pools. "}
            <b>Always DYOR!</b>
          </Typography>
        </Box>
      </Box>
      <Box className="farms_bx pool_bx">
        <Box className="frm_top_bx frm_top_bx_v2">
          <Box className="tp_lft_bx">
            <Typography component="h4">LaunchPool</Typography>
          </Box>
          <Box className="tp_rght_bx">
            <Button className="hrvstall_btn" onClick={harvestAll}>
              Harvest All
            </Button>
          </Box>
        </Box>
        <Box className="tknlnch_table tknlnch_table_mob">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography className="bstd_btng_pool_prnt">
                      Name
                      {/* <BoostedModal /> */}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="center">APR</TableCell> */}
                  <TableCell align="center">Ends in</TableCell>
                  <TableCell align="center">Total Staked</TableCell>
                  <TableCell align="center">Your Stake</TableCell>
                  <TableCell align="center">Rewards</TableCell>

                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsRender(
                  <>
                    {FarmRowSkeleton}
                    {FarmRowSkeleton}
                    {FarmRowSkeleton}
                  </>,
                  (data) => (
                    <>
                      {data.map((farm, index) => (
                        <PoolRow key={`${index}-${farm.tokenAddress}`} farm={farm} />
                      ))}
                    </>
                  ),
                  (err) => (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rowsRender(
            <>
              {FarmRowSkeleton}
              {FarmRowSkeleton}
              {FarmRowSkeleton}
            </>,
            (data) => (
              <>
                {data.length > 0 ? (
                  data.map((farm, index) => <PoolRowMobile key={`${index}-${farm.tokenAddress}`} farm={farm} />)
                ) : (
                  <Typography className="bstd_btng_pool_prnt">No Any Pool Active Now</Typography>
                )}
              </>
            ),
            (err) => (
              <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
            )
          )}
          <Box mt={2}>
            <Box className="frm_top_bx">
              <Box className="tp_lft_bx"></Box>
              <Box className="tp_rght_bx">
                <Box className="swtch_bx">
                  <span className="lable">Show Ended Pool</span>

                  <Checkbox {...label} className="cheq_box" value={showOnlyEnded} onChange={() => setShowOnlyEnded((prev) => !prev)} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
