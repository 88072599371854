import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, LinearProgress, Skeleton, Typography } from '@mui/material';

import { useWeb3Context } from '../../hooks';
import { useAsyncRender } from '../../hooks/useApi';
import { ERC20Contract } from '../../abi';
import { ethers } from 'ethers';
import Countdown from 'react-countdown';
import IdoHelper from '../../helpers/idoHelper';
import useRefresh from '../../hooks/useRefresh';

export default function IdoCard({ ido }) {
  const { provider, address } = useWeb3Context();
  const [percent, setPercent] = useState(0);
  const [userContribution, setUserContribution] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalusers, setTotalUsers] = useState(0);
  // const [startBlock, setStartBlock] = useState(0);
  // const [endBlock, setEndBlock] = useState(0);
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [OfferingAmount, setOfferingAmount] = useState(0);

  const [MaxParticipationAmount, setMaxParticipationAmount] = useState(0);

  const { ultraRefresh } = useRefresh();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const iofContract = useMemo(() => new IdoHelper(ido.address, provider.getSigner()), [ido.address, provider]);
  const currencyContract = useMemo(() => new ethers.Contract(ido.lpToken, ERC20Contract, provider.getSigner()), [ido.lpToken, provider]);

  // useEffect(() => {
  //   if (ido) {
  //     setStartBlock(ido.startBlock);
  //     setEndBlock(ido.endBlock);
  //   }
  // }, [ido]);

  useEffect(() => {
    const status = { cancelled: false };
    Promise.all([
      iofContract.totalAmount(),
      iofContract.userInfo(address),
      iofContract.getUserAllocation(address),
      iofContract.getAddressListLength(),
      iofContract.getMaxParticipationAmountOfUser(address),
      iofContract.getOfferingAmount(address),
    ]).then(([totalAmount, userInfo, userAllocation, totalParticipated, MaxParticipationAmount, OfferingAmount]) => {
      if (status.cancelled) return;

      const totalAmountValue = totalAmount / 10 ** ido.lpTokenDecimal;
      setTotalAmount(totalAmountValue);
      setPercent((totalAmountValue / ido.raisingAmount) * 100);
      setUserContribution(Number(userInfo[0]) / 10 ** ido.lpTokenDecimal);

      setTotalUsers(totalParticipated);
      setMaxParticipationAmount(MaxParticipationAmount / 10 ** ido.lpTokenDecimal);
      setOfferingAmount(OfferingAmount / 10 ** ido.tokenDecimals);
    });

    return () => {
      status.cancelled = true;
    };
  }, [address, iofContract, ultraRefresh, ido, currencyContract]);

  useEffect(() => {
    const status = { cancelled: false };
    (async () => {
      // const currBlock = await provider.getBlockNumber();
      // let startTimestampVal;
      // if (currBlock < ido.startBlock) {
      //   const diff = ido.startBlock - currBlock;
      //   startTimestampVal = Date.now() + diff * 13 * 1000;
      // } else if (currBlock >= ido.startBlock) {
      //   const blockinfo = await provider.getBlock(ido.startBlock);
      //   startTimestampVal = blockinfo?.timestamp * 1000;
      // }

      if (status.cancelled) return;
      setStartTimestamp(ido.startTimestamp);
    })();
    return () => {
      status.cancelled = true;
    };
  }, [address, iofContract, ido, provider]);

  useEffect(() => {
    const status = { cancelled: false };
    (async () => {
      // const currBlock = await provider.getBlockNumber();
      // let endTimestampVal;

      // if (currBlock < ido.endBlock) {
      //   const diff = ido.endBlock - currBlock;
      //   endTimestampVal = Date.now() + diff * 13 * 1000;
      // } else if (currBlock >= ido.endBlock) {
      //   const blockinfo = await provider.getBlock(ido.endBlock);
      //   endTimestampVal = blockinfo?.timestamp * 1000;
      // }

      if (status.cancelled) return;
      setEndTimestamp(ido.endTimestamp);
    })();
    return () => {
      status.cancelled = true;
    };
  }, [address, iofContract, ido, provider]);

  const nftDetailsAPI = useAsyncRender(
    () => iofContract?.getNftDetails(ido.boosterNFT, address, ido.MUTATION_ADDRESS),
    [iofContract, address]
  );

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={4}
      mt={7}
      component="a"
      href={'/launchpad/' + ido.address}
      className="pading_grid_o no-decoration"
    >
      <Box className="paper_money_min_bx">
        <Box
          className="papermoney_dao_bx"
          style={{
            backgroundImage: 'url(./img/ido/' + ido.bannerImgName + ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box className="kyc_btn_prnt">
            {ido.isKyc && <Button className="kyc_btn">KYC</Button>}
            {ido.isAudited && (
              <Button className="kyc_btn" href={ido.auditLink} target="_blank">
                AUDITED
              </Button>
            )}
          </Box>
          <img src={ido.imagePath} alt="" className="close_crcl_img_ppup" />
          <Typography component="h3">{ido.name}</Typography>
          {startTimestamp && endTimestamp ? (
            startTimestamp*1000 > Date.now() ? (
              <Typography>
                Starts in <Countdown date={startTimestamp} />
              </Typography>
            ) : endTimestamp*1000 > Date.now() ? (
              <Typography>
                Ends in <Countdown date={endTimestamp} />
              </Typography>
            ) : (
              <Typography>Ended</Typography>
            )
          ) : (
            <Typography>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={210} />
            </Typography>
          )}
        </Box>
        <Box className="pogres_bootenm_part">
          <Box className="pogres_text">
            <Typography className="Progress_text">Progress</Typography>
            <Typography className="grn_text">{percent.toFixed(2)}%</Typography>
          </Box>
          <Box className="pogre_bx">
            <LinearProgress variant="determinate" value={percent > 100 ? 100 : percent} />
            {/* {percent ? <Slider defaultValue={percent} aria-label="Default" valueLabelDisplay="auto" /> : ''} */}
          </Box>
          <Box className="pogres_text">
            <Typography className="Progress_uniw">
              {totalAmount.toFixed(2)} {ido.lpTokenSymbol}
            </Typography>
            <Typography className="Progress_uniw">
              {ido.raisingAmount} {ido.lpTokenSymbol}
            </Typography>
          </Box>
          <Box className="sos_btn_paart">
            <Box className="pogres_text">
              <Typography className="contribution_text">My Contribution</Typography>
              <Typography className="contribution_text">
                {userContribution} {ido.lpTokenSymbol}
              </Typography>
            </Box>
            <Box className="pogres_text">
              <Typography className="contribution_text">My Limit Contribution</Typography>
              <Typography className="contribution_text">
                {MaxParticipationAmount} {ido.lpTokenSymbol}
              </Typography>
            </Box>
            <Box className="pogres_text">
              <Typography className="contribution_text">Boost Limit Contribution</Typography>
              <Button className="Plus_btn">
                {nftDetailsAPI(
                  <Skeleton variant="rectangular" height={34} width={48} />,
                  (nftData) => (
                    <>
                      <Button sx={{ fontSize: 14, color: 'white' }}>
                        + {(nftData && nftData?.rarityDetails?.boostRate - 100 + '%') || '0%'}
                      </Button>
                    </>
                  ),
                  (err) => (
                    <Typography>Error...</Typography>
                  )
                )}
              </Button>
            </Box>
            <Box className="pogres_text">
              <Typography className="contribution_text">My Reserved Tokens</Typography>
              <Typography className="contribution_text">
                {OfferingAmount} {ido.tokenSymbol}
              </Typography>
            </Box>
            <Box className="pogres_text">
              <Typography className="contribution_text">Total Contributors</Typography>
              <Typography className="contribution_text">{totalusers} Participants</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
