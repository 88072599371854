import { useSnackbar } from "notistack";

export type Message = IMessage & {
  severity: any;
};

export interface MessagesState {
  message: Message | null;
}

interface IMessage {
  text: string;
  error?: any;
}

export function useNotistack() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showMessage = (severity: string, persist: boolean) => {
    return (msg: string, err?: any) => {
      const message = {
        text: msg,
        severity,
        error: err,
      };
      enqueueSnackbar(JSON.stringify(message));
      if (!persist) {
        setTimeout(() => {
          closeSnackbar();
        }, 4000);
      }
    };
  };

  return {
    error: (msg: string, persist?: boolean, err?: any) => showMessage('error', persist)(msg, err),
    info: (msg: string, persist?: boolean, err?: any) => showMessage('info', persist)(msg, err),
    close: () => closeSnackbar(),
    warning: (msg: string, persist?: boolean, err?: any) => showMessage('warning', persist)(msg, err),
    success: (msg: string, persist?: boolean, err?: any) => showMessage('success', persist)(msg, err),
  };
}

export function useFormattedNotistake() {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { close, error, info, success, warning } = useNotistack();

  return {
    close,
    error,
    info,
    success,
    warning,
    formattedError: (msg: string, persist?: boolean, err?: any) => error(`Error while ${msg}`, persist, err),
    formattedInfo: (msg: string, persist?: boolean, err?: any) => info(`${msg}...`, persist, err),
    formattedSuccess: (msg: string, persist?: boolean, err?: any) => success(`${msg} successful`, persist, err),
  };
}

export function useAsyncFnWithNotistake() {
  const notistack = useFormattedNotistake();

  const withNotistake = async (msg: string, fn: () => Promise<void>) => {
    try {
      notistack.formattedInfo(msg, true);
      await fn();
      notistack.close();
      notistack.formattedSuccess(msg);
    } catch (e) {
      console.log(e);
      notistack.close();
      notistack.formattedError(msg, true, e.message);
    }
  };

  return withNotistake;
}
