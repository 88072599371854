import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getAddresses, Networks } from '../constants';
import { useWeb3Context } from '../hooks';
import { useAsyncRender } from '../hooks/useApi';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RevenueShareHelper from '../helpers/revenueShareHelper';
import getApiCanceller from '../utils/ApiCanceller';
import { BigNumber, ethers } from 'ethers';
import { platformTokenToken } from '../abi';
import { convertFromWei } from '../utils/convert-wei';
import { numAbbr } from '../utils/unit-convert-utils';
import NotistakeLoaderButton from '../component/NotistakeLoaderButton';
import UserData from '../component/revenueShare/userData';
import GetTokenPrice from '../utils/get-bork-price';
import { getTokenData } from '../utils/token-data';
import RevenueSharePopUpModel from '../component/revenueShare/RevenueSharePopUpModel';
import { useAsyncFnWithNotistake } from '../hooks/use-notistake';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RevenueSharing({ props }: any) {
  const stakePopupRef = useRef<any>();
  const withNotistake = useAsyncFnWithNotistake();
  const { provider, address, chainID } = useWeb3Context();
  const [balance, setBalance] = useState('0');
  const [stakedBalance, setStakedBalance] = useState('0');
  const [allowance, setAllowance] = useState(false);
  const [stakeValue, setStakeValue] = useState('0');
  const [unlockTime, setUnlockTime] = useState(0);
  const [rewardRate, setRewardRate] = useState(0);
  const [totalStaked, setTotalStaked] = useState('0');
  const [tokenPrice, setTokenPrice] = useState(0);
  const [ethwPrice, setEthwPrice] = useState(0);
  const [claimValue, setClaimValue] = useState('0');
  const [totalRevenue, setTotalRevenue] = useState('0');

  const [earnedValue, setEarnedValue] = useState('');
  const [value, setValue] = useState(0);

  const { REVENUE_SHARE_ADDRESS, platformToken, boosterTokenAddress } = useMemo(() => getAddresses(chainID), [chainID]);
  const contract = useMemo(() => new RevenueShareHelper(REVENUE_SHARE_ADDRESS, provider), [REVENUE_SHARE_ADDRESS, provider]);
  const platformTokenContract = new ethers.Contract(platformToken, platformTokenToken, provider.getSigner());

  useEffect(() => {
    const savedChainId = window.localStorage.getItem('chainId');
    if (+savedChainId === Networks.ARBITRUM) window.location.href = '/';
  }, [window]);

  useEffect(() => {
    const getPrice = async () => {
      const priceBork = await GetTokenPrice(provider);
      setTokenPrice(priceBork);
      const price = (await getTokenData()).price;
      setEthwPrice(price);
    };
    getPrice();
  }, [provider]);

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    if (address) {
      verify(platformTokenContract.balanceOf(address)).then((bal: BigNumber) => setBalance(convertFromWei(bal.toString())), errorHandler);
      verify(platformTokenContract.balanceOf(REVENUE_SHARE_ADDRESS)).then(
        (bal: BigNumber) => setTotalStaked(convertFromWei(bal.toString())),
        errorHandler
      );
      verify(platformTokenContract.allowance(address, REVENUE_SHARE_ADDRESS)).then((allowance) => {
        setAllowance(+allowance > 0 ? true : false);
      }, errorHandler);
    }
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, platformTokenContract]);

  useEffect(() => {
    const status = { cancelled: false };
    Promise.all([
      contract.balanceOf(address),
      contract.rewardRate(),
      contract.unlockTime(address),
      contract.earned(address),
      contract.getTotalRevenues(),
    ]).then(([stakedBalance, rewardRateValu, unlockTime, earnedAmount, totalRevenueAmount]) => {
      setStakedBalance(stakedBalance);
      setUnlockTime(unlockTime);
      setEarnedValue(earnedAmount);
      setTotalRevenue(totalRevenueAmount);
      setRewardRate(rewardRateValu);
      if (status.cancelled) return;
    });

    return () => {
      status.cancelled = true;
    };
  }, [address, contract]);

  const lockingPeriodAPI = useAsyncRender(() => contract?.lockingPeriod(), [contract]);
  const extensionIntervalAPI = useAsyncRender(() => contract?.extensionInterval(), [contract]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onMax = () => {
    setStakeValue(balance);
  };

  const onMaxUnstake = () => {
    setClaimValue(stakedBalance);
  };

  const stake = async () => {
    try {
      if (allowance) {
        const staketx = await contract.stake(stakeValue.toString());
        await staketx.wait();
      } else {
        const approvetx = await platformTokenContract.approve(REVENUE_SHARE_ADDRESS, ethers.constants.MaxUint256);
        await approvetx.wait();
        setAllowance(true);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const unstake = async () => {
    try {
      const unstaketx = await contract.withdraw(claimValue.toString());
      await unstaketx.wait();
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const onClaim = async () => {
    try {
      const claimtx = await contract.claim();
      await claimtx.wait();
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onUnstakeNFT = async (shouldUpdateData = false) => {
    await withNotistake('Unstaking NFT', async () => {
      const tx = await contract.unstakeNFT();
      await tx.wait();
    });
  };

  const onStakeNFT = async (nft) => {
    await withNotistake('Staking NFT', async () => {
      const tx = await contract.stakeNFT(boosterTokenAddress, nft.token_id);
      await tx.wait();
    });
  };

  const openStakePopup = () => {
    if (stakePopupRef.current) {
      stakePopupRef.current?.openPopup();
    }
  };

  const nftDetailsAPI = useAsyncRender(() => contract?.getNftDetails(boosterTokenAddress, address), [contract, address]);

  return (
    <>
      <Box className="sarning_main_bx">
        <Box className="sarning_innr_bx">
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} lg={4} className="pading_lft">
              <Box className="sarning_grid">
                <Typography component="h2">Revenue sharing</Typography>
                <Typography>Lock your $UNIW to earn ETHW from protocol fees</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8} className="pading_lft">
              <Box className="right_box">
                <Box className="you_wbtex_box">
                  <Typography component="h6">Your Staked UNIW Value</Typography>
                  <Typography>
                    <span>
                      ${' '}
                      {tokenPrice ? (
                        numAbbr(+stakedBalance * tokenPrice)
                      ) : (
                        <Skeleton height={10} width={10} style={{ display: 'inline' }} />
                      )}{' '}
                    </span>{' '}
                  </Typography>
                </Box>
                <Box className="bdr_btm"></Box>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} lg={4}>
                    <Box className="comnid_pay_box">
                      <Typography component="h4">PROTOCOL APR</Typography>
                      <Typography>
                        {totalStaked && ethwPrice && tokenPrice ? (
                          +totalStaked > 0 ? (
                            numAbbr(((200 * ethwPrice * 6) / (+totalStaked * tokenPrice)) * 100) + '%'
                          ) : (
                            0
                          )
                        ) : (
                          <Skeleton variant="rectangular" height={30} width={80} />
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box className="comnid_pay_box">
                      <Typography component="h4">TOTAL DISTRIBUTION</Typography>
                      <Typography>{totalRevenue + ' ETHW'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Box className="comnid_pay_box">
                      <Typography component="h4">TOTAL AMOUNT LOCKED</Typography>
                      <Typography>
                        {+totalStaked ? numAbbr(totalStaked) + ' UNIW' : <Skeleton variant="rectangular" height={30} width={80} />}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="mobileudata">
          {nftDetailsAPI(
            <Skeleton variant="rectangular" height={300} width={350} />,
            (nftData) => (
              <>
                <UserData
                  balance={balance}
                  stakedBalance={stakedBalance}
                  unlockTime={unlockTime}
                  boosterRate={nftData ? nftData.boostRate : 0}
                />
              </>
            ),
            (err) => (
              <></>
            )
          )}
        </Box>
        <Box className="tab_main_lotry">
          <Box sx={{ width: '100%' }} className="tab_in_all">
            <Box className="lorty_tabs_main">
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabs_lorty tabs_lorty_aj">
                <Tab label="STAKE" {...a11yProps(0)} className="tab_widt" />
                <Tab label="CLAIM" {...a11yProps(1)} className="tab_widt_v2" />
                <Tab label="UNSTAKE" {...a11yProps(2)} className="tab_widt_v3" />
              </Tabs>
            </Box>
            <Box className="tabpanel_bx">
              <TabPanel value={value} index={0}>
                <Box className="next_drow_bx">
                  <Grid container spacing={2} className="tabs_panel_main_grid">
                    <Grid item xs={12} lg={4} className="tabs_panel_main dnone1199">
                      {nftDetailsAPI(
                        <Skeleton variant="rectangular" height={300} width={350} />,
                        (nftData) => (
                          <>
                            <UserData
                              balance={balance}
                              stakedBalance={stakedBalance}
                              unlockTime={unlockTime}
                              boosterRate={nftData ? nftData.boostRate : 0}
                            />
                          </>
                        ),
                        (err) => (
                          <></>
                        )
                      )}
                    </Grid>
                    <Grid item xs={13} lg={8} className="tabs_panel_main tabs_panel_mainv2">
                      <Box className="last_grd_right_bx last_grd_right_bxas">
                        <Box className="leftrvnsrng">
                          <Typography>
                            Note: Your <span>$UNIW</span> will be locked in the revenue share pool for{' '}
                            {lockingPeriodAPI(
                              <Skeleton variant="rectangular" height={34} width={48} />,
                              (lockingPeriod) => (
                                <span>{lockingPeriod / 86400}</span>
                              ),
                              (err) => (
                                <span>---</span>
                              )
                            )}{' '}
                            Days , where you will receive a split of the Treasury's monthly earnings.
                          </Typography>
                          <Box className="input_btn_minprnt">
                            <Box className="input_box">
                              <input
                                type="number"
                                placeholder="0"
                                value={stakeValue}
                                onChange={(e) => setStakeValue(e.target.value)}
                              ></input>
                              <Button onClick={onMax}>MAX</Button>
                            </Box>

                            <Box className="btn_bx">
                              {allowance ? (
                                <NotistakeLoaderButton msg="Staking" onClick={stake} disabled={+stakeValue <= 0}>
                                  Stake
                                </NotistakeLoaderButton>
                              ) : (
                                <NotistakeLoaderButton msg="Approving" onClick={stake}>
                                  Approve
                                </NotistakeLoaderButton>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box className="righttrvnsrng">
                          {nftDetailsAPI(
                            <Skeleton variant="rectangular" height={250} width={250} />,
                            (nftData) =>
                              nftData ? (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <img src={nftData.image} alt="" />
                                  </Button>
                                  <Box className="rvnbxbttm">
                                    Token ID <span>{nftData.id}</span>
                                  </Box>
                                  <Box className="rvnbxbttm">
                                    Farm Boost <span>{nftData.boostRate} UNIW</span>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <AddCircleIcon />
                                  </Button>
                                </>
                              ),
                            (err) => (
                              <></>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
            <Box className="tabpanel_bx">
              <TabPanel value={value} index={1}>
                <Box className="next_drow_bx">
                  <Grid container spacing={2} className="tabs_panel_main_grid">
                    <Grid item xs={12} lg={4} className="tabs_panel_main dnone1199">
                      {nftDetailsAPI(
                        <Skeleton variant="rectangular" height={300} width={350} />,
                        (nftData) => (
                          <>
                            <UserData
                              balance={balance}
                              stakedBalance={stakedBalance}
                              unlockTime={unlockTime}
                              boosterRate={nftData ? nftData.boostRate : 0}
                            />
                          </>
                        ),
                        (err) => (
                          <></>
                        )
                      )}
                    </Grid>
                    <Grid item xs={13} lg={8} className="tabs_panel_main tabs_panel_mainv2">
                      <Box className="last_grd_right_bx last_grd_right_bxas">
                        <Box className="leftrvnsrng">
                          <Typography>
                            Claim to get your rewards earned through our revenue redistribution pool.{' '}
                            <span>
                              Claiming Rewards will add{' '}
                              {extensionIntervalAPI(
                                <Skeleton variant="rectangular" height={34} width={48} />,
                                (extensionInterval) => (
                                  <span>{extensionInterval / 86400}</span>
                                ),
                                (err) => (
                                  <span>---</span>
                                )
                              )}{' '}
                              Days on your locking period.
                            </span>
                          </Typography>
                          <Box className="input_btn_minprnt">
                            <Box className="btn_bx">
                              <NotistakeLoaderButton msg="Claiming" onClick={onClaim}>
                                CLAIM {numAbbr(earnedValue)} ETHW
                              </NotistakeLoaderButton>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="righttrvnsrng">
                          {nftDetailsAPI(
                            <Skeleton variant="rectangular" height={250} width={250} />,
                            (nftData) =>
                              nftData ? (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <img src={nftData.image} alt="" />
                                  </Button>
                                  <Box className="rvnbxbttm">
                                    Token ID <span>{nftData.id}</span>
                                  </Box>
                                  <Box className="rvnbxbttm">
                                    Farm Boost <span>{nftData.boostRate} UNIW</span>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <AddCircleIcon />
                                  </Button>
                                </>
                              ),
                            (err) => (
                              <></>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
            <Box className="tabpanel_bx">
              <TabPanel value={value} index={2}>
                <Box className="next_drow_bx">
                  <Grid container spacing={2} className="tabs_panel_main_grid">
                    <Grid item xs={12} lg={4} className="tabs_panel_main dnone1199">
                      {nftDetailsAPI(
                        <Skeleton variant="rectangular" height={300} width={350} />,
                        (nftData) => (
                          <>
                            <UserData
                              balance={balance}
                              stakedBalance={stakedBalance}
                              unlockTime={unlockTime}
                              boosterRate={nftData ? nftData.boostRate : 0}
                            />
                          </>
                        ),
                        (err) => (
                          <></>
                        )
                      )}
                    </Grid>
                    <Grid item xs={13} lg={8} className="tabs_panel_main tabs_panel_mainv2">
                      <Box className="last_grd_right_bx last_grd_right_bxas">
                        <Box className="leftrvnsrng">
                          <Box className="input_btn_minprnt">
                            <Typography className="tab_thre_pr">
                              Unstake & Claim to retrieve your earnings and initial locked <span>$UNIW</span>
                              <br /> from the redistribution pool.
                            </Typography>
                            <Box className="input_box input_boxv2">
                              <input
                                type="number"
                                placeholder="0"
                                value={claimValue}
                                onChange={(e) => setClaimValue(e.target.value)}
                              ></input>
                              <Button onClick={onMaxUnstake}>MAX</Button>
                            </Box>

                            <Box className="btn_bx">
                              <NotistakeLoaderButton msg="Withdrawing" onClick={unstake} disabled={unlockTime * 1000 > Date.now()}>
                                {unlockTime * 1000 > Date.now() ? 'Please wait to finish locking period' : 'UNSTAKE AND CLAIM'}
                              </NotistakeLoaderButton>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="righttrvnsrng">
                          {nftDetailsAPI(
                            <Skeleton variant="rectangular" height={250} width={250} />,
                            (nftData) =>
                              nftData ? (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <img src={nftData.image} alt="" />
                                  </Button>
                                  <Box className="rvnbxbttm">
                                    Token ID <span>{nftData.id}</span>
                                  </Box>
                                  <Box className="rvnbxbttm">
                                    Farm Boost <span>{nftData.boostRate} UNIW</span>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Typography>Boost Pool Participation</Typography>
                                  <Button onClick={openStakePopup}>
                                    <AddCircleIcon />
                                  </Button>
                                </>
                              ),
                            (err) => (
                              <></>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
      <RevenueSharePopUpModel
        ref={stakePopupRef}
        revenueShareAddress={REVENUE_SHARE_ADDRESS}
        onStakeNFT={onStakeNFT}
        onUnstakeNFT={onUnstakeNFT}
        boosterTokenAddress={boosterTokenAddress}
        nftDetailsAPI={nftDetailsAPI}
        unlocktime={unlockTime}
      />
    </>
  );
}
