import { cacheFn } from '../utils/cacheFn';
import GetTokenPrice, { calculateLpTokenPrice } from '../utils/get-bork-price';
import { getTokenData } from '../utils/token-data';

export default function getFarmPrice(farm, provider) {
  return cacheFn(
    `FARM-${farm.isLp}-${farm.name}-${farm.stakeToken}-${farm.tokenAddress}`,
    () => {
      if (!farm.isLp) {
        if (
          farm.tokenAddress === '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33' ||
          farm.tokenAddress === '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b'
        ) {
          return GetTokenPrice(provider);
        } else {
          return getTokenData(farm.stakeToken).then((data) => data.price);
        }
      } else {
        return calculateLpTokenPrice(farm.tokenAddress, provider);
      }
    },
    5000
  ) as Promise<number>;
}
