import { ethers } from 'ethers';

import { KOF } from '../abi';

export default class KOFHelper {
  public contract;
  public kofAddress;
  public provider;

  constructor(contractAddress: string, public providerVal) {
    this.provider = providerVal;
    this.kofAddress = contractAddress;
    this.contract = new ethers.Contract(contractAddress, KOF, providerVal);
  }

  public async approve(pid: number) {
    const txUnstakeNFT = await this.contract.approve(pid);
    return txUnstakeNFT;
  }

  public async lastBidder() {
    const lastBidder = await this.contract.lastBidder();
    return lastBidder;
  }

  public async bidAmount() {
    const bidAmount = await this.contract.bidAmount();
    return bidAmount;
  }

  public async nextStartTime() {
    const nextStartTime = await this.contract.nextStartTime();
    return nextStartTime;
  }

  public async lastWinner() {
    const lastWinner = await this.contract.lastWinner();
    return lastWinner;
  }

  public async hasWinner() {
    const hasWinner = await this.contract.hasWinner();
    return hasWinner;
  }

  public async lastBidTime() {
    const lastBidTime = parseInt(await this.contract.lastBidTime());
    return lastBidTime;
  }

  public async _endDelay() {
    const _endDelay = parseInt(await this.contract.endDelay());
    return _endDelay;
  }

  public async isBanned(address) {
    const isBanned = await this.contract.blacklist(address);
    return isBanned;
  }

  public claimForest = async () => {
    return this.contract.claimReward();
  };

  public participateForest = async (amount) => {
    return await this.contract.participate(amount);
  };
}
