/* eslint-disable no-await-in-loop */
import { Box, Button, Skeleton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ethers } from 'ethers';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import { BoosterToken, MutationABI } from '../../abi';
import { getAddresses } from '../../constants';
import IDOnftRarities from '../../data/IDONFTRarities';
import { useWeb3Context } from '../../hooks';
import type { MoralisNFTModel } from '../../utils/moralis';
import NotistakeLoaderButton from '../NotistakeLoaderButton';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src="./img/close_circle_ppup.svg" alt="" className="close_crcl_img_ppup" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface StakePopUpModelProps {
  revenueShareAddress;
  onStakeNFT;
  onUnstakeNFT;
  boosterTokenAddress;
  nftDetailsAPI;
  unlocktime;
}

const RevenueSharePopUpModel = (
  { revenueShareAddress, onUnstakeNFT, onStakeNFT, boosterTokenAddress, nftDetailsAPI, unlocktime }: StakePopUpModelProps,
  ref
) => {
  const { provider, address, chainID } = useWeb3Context();
  const [isNFTApproved, setIsNFTApproved] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [nfts, setNfts] = React.useState<MoralisNFTModel[]>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { MUTATION_ADDRESS } = useMemo(() => getAddresses(chainID), [chainID]);

  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState('0');
  const [selectedNft, setSelectedNft] = React.useState<MoralisNFTModel>();
  const [nftAlreadyStaked, setNftAlreadyStaked] = React.useState(true);

  const handleClickOpen = async () => {
    const nft = nftDetailsAPI(<></>, (nft: any) => nft);
    setNftAlreadyStaked(!!nft);
    setSelectedNft(null);

    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider);
    const totalNfts = await boosterTokenContract.balanceOf(address);
    const mutationContract = new ethers.Contract(MUTATION_ADDRESS, MutationABI, provider.getSigner());

    const oldNFTs = JSON.parse(JSON.stringify(nfts || []));
    const oldNFTsMap = oldNFTs.reduce((acc, curr) => ({ ...acc, [curr.token_id]: curr }), {});
    setViewAllNFT(false);
    setNfts([]);
    const allPromises = [];
    for (let i = 0; i < Number(totalNfts); i++) {
      const promise = boosterTokenContract
        .tokenOfOwnerByIndex(address, i)
        .then(async (token) => {
          const isMuted = await mutationContract.isMutated(token);
          const id = Number(token);
          if (oldNFTsMap[id]) {
            return oldNFTsMap[id];
          }
          return boosterTokenContract
            .getNftType(id)
            .then((nftType) => IDOnftRarities(nftType))
            .then((nftRarity) => ({
              token_id: id,
              token_address: boosterTokenAddress,
              image: isMuted
                ? 'https://uniwpunk-mutant-nfts.s3.amazonaws.com/' + token + '.png'
                : 'https://uniwpunk-nfts.s3.amazonaws.com/' + token + '.png',
              rarityDetails: nftRarity,
            }));
        })
        .then((item) => {
          setNfts((prev) => [...prev, item]);
        });

      allPromises.push(promise);
    }

    // const nftsFromAPI = await Promise.all(allPromises);
    // setNfts(nftsFromAPI);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openPopup: handleClickOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const stakeClicked = async () => {
    // if (amount > maxBalance) {
    //   dispatch(error({ text: messages.not_enough_balance }));
    // } else {
    await onStakeNFT(selectedNft);
    handleClose();
    setAmount('0');
    // }
  };

  const unstakeClicked = async () => {
    const isNftAvailableToStake = nftDetailsAPI(<></>, (data) => !!data);
    if (isNftAvailableToStake) {
      await onUnstakeNFT();
    }
    setOpen(false);
  };

  const onNFTSelect = async (nft: MoralisNFTModel) => {
    setSelectedNft(nft);
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider.getSigner());
    const allowed = await boosterTokenContract.getApproved(nft.token_id);
    setIsNFTApproved(allowed.toLowerCase() === revenueShareAddress.toLowerCase());
  };

  const onApproveNFT = async () => {
    try {
      const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider.getSigner());
      const approveTx = await boosterTokenContract.approve(revenueShareAddress, selectedNft.token_id);
      await approveTx.wait();
      setIsNFTApproved(true);
      return approveTx;
    } catch (e) {
      console.log(e);
    }
  };

  const [viewAllNFT, setViewAllNFT] = useState(false);

  const nftsToDisplay = viewAllNFT ? nfts : (nfts || []).slice(0, 5);

  return (
    <>
      <Box className="modal_boost_frst">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="farm_popup_main respons_btr_dilg"
        >
          <Box className="gamble_ppup gamble_ppup_v2 gamble_ppup_v2a">
            <Box className="farm_txt_bxx">
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Box component="h1" className="farmname">
                  Revenue Share
                </Box>
              </BootstrapDialogTitle>
            </Box>

            <Box className="tab_prnt tab_prnt_va">
              <Box sx={{ width: '100%' }}>
                <Box sx={{}}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="tabs_btn">
                    <Tab label="Stake" {...a11yProps(0)} className="stake_btn_def" />
                    {/* <Tab label="Unstake" disabled={userStaked === 0} {...a11yProps(1)} className="stake_btn_def" /> */}
                    <Tab label="Unstake" {...a11yProps(1)} className="stake_btn_def" />
                  </Tabs>
                </Box>
                <Box className="tbn_bx">
                  <TabPanel value={value} index={0}>
                    <Box className="stake_min_box">
                      <Box>
                        <Box className="slect_borde_bx">
                          <Typography component="h5">Select boosters</Typography>
                          {nftsToDisplay?.length > 0 && (
                            <>
                              <Box className="al_bx_img">
                                <Box className="all_img_five" flexWrap="wrap">
                                  {nftsToDisplay?.map((nft) => (
                                    <Box className="cstm_rd_box" key={nft.token_id}>
                                      <input
                                        type="radio"
                                        name="booster"
                                        disabled={nftAlreadyStaked}
                                        id={nft.token_id}
                                        value={nft.token_id}
                                        onClick={() => {
                                          onNFTSelect(nft);
                                        }}
                                      />
                                      <img src={nft.image} alt="" className="popup_rarity_ic" height={75} width={75} />
                                    </Box>
                                  ))}
                                  {(nfts?.length ?? 0) > 5 && !viewAllNFT && (
                                    <Box className="cstm_rd_box cstm_rd_box_btn">
                                      <input type="radio" name="booster" id="boster05" />
                                      <Button className="btn_las_2" onClick={() => setViewAllNFT(true)}>
                                        +{nfts.length - 5}
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                                <Box className="alertbox nftstake">
                                  <Box className="alertinside">
                                    <Typography className="alertinfo">
                                      <FaExclamationTriangle />
                                      There is 14 days locking period on NFT Staking
                                    </Typography>
                                  </Box>
                                </Box>
                                {nftAlreadyStaked && (
                                  <>
                                    <Box className="alertbox nftstake">
                                      <Box className="alertinside">
                                        <Typography className="alertinfo">
                                          <FaExclamationTriangle />
                                          One NFT is Already staked
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </>
                          )}
                          {nfts?.length === 0 && <Button className="buy_bstr_btn">Buy Booster NFT</Button>}
                        </Box>
                        <Box className="annua_last_bx_prnt">
                          <Box className="two_btn_ls">
                            {!selectedNft || isNFTApproved ? (
                              <NotistakeLoaderButton disabled={selectedNft === null} onClick={stakeClicked} style={{ maxWidth: 'none' }}>
                                Stake
                              </NotistakeLoaderButton>
                            ) : (
                              <NotistakeLoaderButton msg="Approving NFT" onClick={onApproveNFT} style={{ maxWidth: 'none' }}>
                                Approve NFT
                              </NotistakeLoaderButton>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </TabPanel>
                </Box>
                <Box className="unstake_tabs_pnls">
                  <TabPanel value={value} index={1}>
                    <Box className="unstake_min_box">
                      {nftDetailsAPI(<Skeleton variant="rectangular" height={80} width={80} />, (data) => (
                        <>
                          {data && (
                            <Box className="imag_catri_bx">
                              <Box className="img_flex_bx">
                                {nftDetailsAPI(
                                  <Skeleton variant="rectangular" height={80} width={80} />,
                                  (data) => (
                                    <img src={data.image} alt="" className="popup_rarity_ic" height={80} width={80} />
                                  ),
                                  (err) => (
                                    <Typography>Error...</Typography>
                                  )
                                )}
                                <Box className="span_text">
                                  <Typography>
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Token ID: </span>
                                          {data.id}
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                  <Typography className="text_margin">
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Booster Category:</span>
                                          {data.name}
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                  <Typography>
                                    {nftDetailsAPI(
                                      <Skeleton variant="rectangular" height={34} width={80} />,
                                      (data) => (
                                        <>
                                          <span>Farm Boost:</span>
                                          {data.boostRate} UNIW
                                        </>
                                      ),
                                      (err) => (
                                        <Typography>Error...</Typography>
                                      )
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                      ))}
                      {unlocktime * 1000 > Date.now() && (
                        <Box className="alertbox nftstake">
                          <Box className="alertinside">
                            <Typography className="alertinfo">
                              <FaExclamationTriangle />
                              Please wait to finish locking period
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <DialogActions className="stake_btn_box" style={{ marginTop: '10px' }}>
                        <NotistakeLoaderButton onClick={unstakeClicked} className="stake_btn" disabled={unlocktime * 1000 > Date.now()}>
                          Unstake Now
                        </NotistakeLoaderButton>
                      </DialogActions>
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </BootstrapDialog>
      </Box>
    </>
  );
};
export default forwardRef(RevenueSharePopUpModel);
