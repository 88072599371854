import { DEFAULD_NETWORK, getAddresses, Networks } from '../constants';
import { TokenLaunchPageData } from '../data/FarmData';
import { StakingPoolData } from '../data/StakingPoolData';
import getFarmPrice from '../helpers/getFarmPrice';
import LockedFarmHelper from '../helpers/LockedFarmHelper';
import StakingPoolHelper from '../helpers/StakingPoolHelper';
import ZbetMasterChefHelper from '../helpers/ZbetMasterChefHelper';
import { convertFromWei } from './convert-wei';
import GetTokenPrice, { calculateLpTokenPrice } from './get-bork-price';
import { getTokenData } from './token-data';

function getFarmTVL(provider) {
  if (!TokenLaunchPageData()) return [];

  const farmPromises = TokenLaunchPageData().map((farm) => {
    let pricePromise = getFarmPrice(farm, provider);
    const contract = new ZbetMasterChefHelper(farm.address, provider);

    const finalFarmPromise = Promise.all([pricePromise, contract.lpTokenAmount(farm.pid)]).then(
      ([price, poolStaked]) => price * +convertFromWei(poolStaked.toString())
    );
    return finalFarmPromise;
  });

  return farmPromises;
}

function getPoolTVL(provider) {
  const addresses = getAddresses(DEFAULD_NETWORK);
  if (!StakingPoolData()) return [];

  const poolPromises = StakingPoolData().map((farm) => {
    let pricePromise;
    if (farm.tokenAddress === addresses.platformToken) {
      pricePromise = GetTokenPrice(provider);
    } else if (farm.rewardToken !== '') {
      pricePromise = getTokenData(farm.rewardToken).then((data) => data.price);
    } else if (farm.LPwithEthw !== '') {
      pricePromise = calculateLpTokenPrice(farm.LPwithEthw, provider);
    }

    const contract = new StakingPoolHelper(farm.address, provider);
    const poolStakePromise = contract.lpTokenAmount(farm.pid, farm.tokenAddress, provider);

    const finalPromise = Promise.all([pricePromise, poolStakePromise]).then(
      ([price, poolStaked]) => price * +convertFromWei(poolStaked.toString())
    );
    return finalPromise;
  });

  return poolPromises;
}

function delay(secs) {
  return new Promise((res) => setTimeout(() => res(0), secs * 1000));
}

export default function getTVL(provider, chainID) {
  if (chainID === Networks.ARBITRUM) {
    return delay(9).then(() => {
      const farmPromises = getFarmTVL(provider);

      return Promise.all([...farmPromises]).then((data) => {
        let finalVal = 0;
        for (const entry of data) {
          finalVal += entry;
        }
        return finalVal;
      });
    });
  } else {
    const farmPromises = getFarmTVL(provider);
    const poolPromises = getPoolTVL(provider);
    return Promise.all([...farmPromises, ...poolPromises]).then((data) => {
      let finalVal = 0;
      for (const entry of data) {
        finalVal += entry;
      }
      return finalVal;
    });
  }
}
