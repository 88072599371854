import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { FarmData } from '../../data/FarmData';
import getFarmPrice from '../../helpers/getFarmPrice';
import ZbetMasterChefHelper from '../../helpers/ZbetMasterChefHelper';
import { useWeb3Context } from '../../hooks';
import { useAsyncFnWithNotistake } from '../../hooks/use-notistake';
import useApi, { useAsyncRender, useDataApi } from '../../hooks/useApi';
import getApiCanceller from '../../utils/ApiCanceller';
import { convertFromWei } from '../../utils/convert-wei';
import GetTokenPrice from '../../utils/get-bork-price';
import type { MoralisNFTModel } from '../../utils/moralis';
import { toFixed2 } from '../../utils/tofixed4';
import { getTokenData } from '../../utils/token-data';
import { numAbbr } from '../../utils/unit-convert-utils';
import NotistakeLoaderButton from '../NotistakeLoaderButton';
import RarityBosterModel from '../tokenlaunch/RarityBosterModel';
import StakePopUpModel from '../tokenlaunch/StakePopUpModel';

const FarmRowMobile = (props: { farm: FarmData }) => {
  const stakePopupRef = useRef<any>();
  const { provider, address } = useWeb3Context();
  const [updateRow, setUpdateRow] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const reff = queryParams.get('reff');
  const [rewardPrice, setRewardPrice] = React.useState(0);
  const withNotistake = useAsyncFnWithNotistake();
  const [tokenPrice, setTokenPrice] = useState(0);

  const {
    farm: { address: contractAddress },
  } = props;

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    verify(GetTokenPrice(provider)).then((price) => setTokenPrice(price), errorHandler);
    verify(getFarmPrice(props.farm, provider)).then((price) => setRewardPrice(price), errorHandler);
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  // contract will be generated again when value of updateRow is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contract = useMemo(() => new ZbetMasterChefHelper(contractAddress, provider), [contractAddress, provider, updateRow]);

  const poolInfoAPI = useAsyncRender(() => contract?.poolInfo(props.farm.pid), [contract]);

  const liquidityAPI = useAsyncRender(() => contract?.lpTokenAmount(props.farm.pid), [contract]);

  const {
    data: userStaked,
    // isLoading: isUserInfoLoading,
    render: userStakedAmountAPI,
  } = useDataApi(() => contract?.userStakedAmount(props.farm.pid, address), [contract, address]);

  // const rewardDebtAPI = useAsyncRender(
  //   () => contract?.rewardDebt(0, address),
  //   [contract, address]
  // );
  // const rewardPerDayAPI = useAsyncRender(() => contract?.rewardPerDay(props.farm.pid, address), [contract, address]);

  const rewardPendingAPI = useAsyncRender(() => contract?.pendingToken(props.farm.pid, address), [contract, address]);

  const getTokenDataCall = useCallback(() => getTokenData(props.farm.stakeToken), [props]);
  const { data: token } = useApi(getTokenDataCall);

  const aprAPI = useAsyncRender(() => contract?.apr(address, props.farm), [contract, address]);

  const nftDetailsAPI = useAsyncRender(
    () => contract?.getNftDetails(props.farm.pid, address, props.farm.boosterTokenAddress, props.farm.MUTATION_ADDRESS),
    [contract, address]
  );

  const onStake = async (amount: number, nft: MoralisNFTModel) => {
    const contract = new ZbetMasterChefHelper(contractAddress, provider.getSigner());
    await withNotistake('Staking', async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const tx = await contract.stake(props.farm.pid, amount, nft, reff);
      setUpdateRow((prev) => prev + 1);
    });
  };

  const onUnstake = async (amount: number, msg: string = 'Unstaking') => {
    const contract = new ZbetMasterChefHelper(contractAddress, provider.getSigner());
    await withNotistake(msg, async () => {
      const tx = await contract.unstake(props.farm.pid, amount);
      await tx.wait();
      setUpdateRow((prev) => prev + 1);
    });
  };

  const onUnstakeNFT = async (shouldUpdateData = false) => {
    const contract = new ZbetMasterChefHelper(contractAddress, provider.getSigner());
    await withNotistake('Unstaking NFT', async () => {
      const tx = await contract.unstakeNFT(props.farm.pid);
      await tx.wait();
      if (shouldUpdateData) {
        setUpdateRow((prev) => prev + 1);
      }
    });
  };

  const openStakePopup = () => {
    if (stakePopupRef.current) {
      stakePopupRef.current?.openPopup();
    }
  };

  return (
    <Box className="mobl_frax_box">
      <Box className="frax_bx_mob frax_bx_mob_v2">
        <Box className="d-flex">
          <img height={50} width={50} src={props.farm.image ? props.farm.image : 'img/frax_ic.svg'} alt="" />
          <Typography component="h2">{props.farm.name}</Typography>
        </Box>
        {poolInfoAPI(
          <Skeleton variant="rectangular" height={34} width={48} />,
          (data) => (
            <Typography className="mltplyr_txt">{toFixed2(data.allocPoint / 100)}X</Typography>
          ),
          (err) => (
            <Typography className="mltplyr_txt">Error...</Typography>
          )
        )}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top">
            <Typography component="h3">Liquidity</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="center">
            <Typography component="h3">APR</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="flex-end">
            <Typography component="h3">Boosted NFT</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box" justifyContent="flex-start">
            {liquidityAPI(
              <Skeleton variant="rectangular" height={34} width={48} />,
              (data) => (
                <Typography>${numAbbr(+convertFromWei(data.toString()) * rewardPrice, false)}</Typography>
              ),
              (err) => (
                <Typography>Error...</Typography>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box" justifyContent="center">
            {aprAPI(
              <Skeleton variant="rectangular" height={34} width={48} />,
              (aprValueMain) =>
                nftDetailsAPI(
                  <Skeleton variant="rectangular" height={34} width={48} />,
                  (nftData) => (
                    <>
                      {nftData ? (
                        <Typography className="c_primary">
                          {(aprValueMain + (aprValueMain * nftData.discount) / 100).toFixed(2)}%
                        </Typography>
                      ) : (
                        <Typography className="c_primary">{numAbbr(aprValueMain)}%</Typography>
                      )}
                    </>
                  ),
                  (err) => <Typography>N/A</Typography>
                ),
              (err) => (
                <Typography className="c_primary">N/A</Typography>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box" justifyContent="flex-end">
            <Typography>
              {nftDetailsAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (nftData) => (
                  <>
                    {nftData ? (
                      <RarityBosterModel
                        farmName={props.farm.name}
                        isLocked={props.farm.isLocked ? props.farm.isLocked : false}
                        onUnstakeNFT={onUnstakeNFT}
                        userStaked={userStaked}
                        apr={(valueToDeduct = 0) =>
                          aprAPI(
                            <Skeleton variant="rectangular" height={34} width={48} />,
                            (aprValue) => <>{(aprValue - (aprValue * valueToDeduct) / 100).toFixed(2)}</>,
                            (err) => <Typography>Error...</Typography>
                          )
                        }
                        nftDetailsAPI={nftDetailsAPI}
                      />
                    ) : (
                      <Button onClick={openStakePopup} sx={{ fontSize: 24, color: 'white' }}>
                        +
                      </Button>
                    )}
                  </>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top">
            <Typography component="h3">My Stakes</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="center">
            <Typography component="h3">Rewards</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="flex-end">
            <Typography component="h3">Deposit Fee</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box">
            <Box className="twice_txt">
              {/* <Typography>0.00</Typography> */}
              {userStakedAmountAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>{numAbbr(convertFromWei(data.toString()))}</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
              {userStakedAmountAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography className="lght_txt">${numAbbr(+convertFromWei(data.toString()) * (rewardPrice || 0))}</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
              {/* <Typography className="lght_txt">$0.00</Typography> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box" justifyContent="center">
            <Box className="twice_txt twice_txt_center">
              {rewardPendingAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (rewardDebt) => (
                  <Typography>{numAbbr(convertFromWei(rewardDebt.toString()))}</Typography>
                ),
                (err) => (
                  <Typography>N/A</Typography>
                )
              )}
              {rewardPendingAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (rewardDebt) => (
                  <Typography className="lght_txt">${numAbbr(+convertFromWei(rewardDebt.toString()) * (tokenPrice || 0))}</Typography>
                ),
                (err) => (
                  <Typography>N/A</Typography>
                )
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="mult_h3_p_box" justifyContent="flex-end">
            <Box className="twice_txt">
              {poolInfoAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>{data ? `${toFixed2(data.depositFee / 100)}%` : '-'}</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
              {/* <Typography className="lght_txt">$0.00</Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Box className="two_btn_m">
        <StakePopUpModel
          ref={stakePopupRef}
          tokenAddress={props.farm.tokenAddress}
          farmName={props.farm.name}
          farmImage={props.farm.image}
          userStaked={userStaked}
          onStake={onStake}
          onUnstake={onUnstake}
          onUnstakeNFT={onUnstakeNFT}
          contractAddress={contractAddress}
          boosterTokenAddress={props.farm.boosterTokenAddress}
          tokenPrice={token?.price || 0}
          nftDetailsAPI={nftDetailsAPI}
          rewardToken={props.farm.rewardToken}
          aprAPI={aprAPI}
          isLP={props.farm.isLp}
          farmData={props.farm}
        />
        <NotistakeLoaderButton className="stak_btn stak_btnv2" onClick={() => onUnstake(0, 'Harvesting')}>
          Harvest
        </NotistakeLoaderButton>
      </Box>
    </Box>
  );
};

export default FarmRowMobile;
