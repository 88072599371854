import { Networks } from './blockchain';

const ETHW_MAINNET = {
  boosterTokenAddress: '0xe48B4261dCD213603bb4a6b85E200C54510CAf50', // updated
  platformToken: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33', // updated
  platformWrappedToken: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // updated
  platformWrappedTokenId: 'ethereum-pow-iou',
  referral: '0x295E27eC5cD61b3aFcDC56b8525308D50Ff0030F',
  masterchef: '0xC07707C7AC7E383CE344C090F915F0a083764C94',
  usdcAddress: '0x11bbb41b3e8baf7f75773db7428d5acee25fec75',
  platformTokenLPAddress: '0x7a69ef644af299d60c668a5194ae3ee6c9d1b7e2', // TOKEN-WETH
  WHITELIST_ADDRESS: ['0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33'],
  KOF_ADDRESS: '0x2a414104C1a8F352576e221030478B542d523ff8',
  REVENUE_SHARE_ADDRESS: '0x1C840d83c06be78a0F821F6627fB885C3F4CEF31',
  MUTATION_ADDRESS: '0xD760b855E3853Ff97746B6fD0F0059aF61DBAd19',
  ENS_ADDRESS: '0xd3d3cF6937015593b3424F81e5F44CefB8A90588',
  burnStakingAddress: '0xddE4932a626f19f746e8549343217101FeacAE77',
  defaultTokenPrice: 0.5,
};

const ARBITRUM_MAINNET = {
  boosterTokenAddress: '0x052D5f0C3157719c44d9c3B8CCe87584E0C985C4', // updated
  platformToken: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b', // updated
  platformWrappedToken: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', // updated
  platformWrappedTokenId: 'ethereum',
  referral: '0x1B8e2e8fFB781E6f91DAFb8c4334630d60ddFbD4',
  masterchef: '0x231A584095dbFb73A0201d6573260Bc646566c98',
  usdcAddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  platformTokenLPAddress: '0x00f1a626e112c66adfdc1a7ebe8ad6a1da4e2eb3', // TOKEN-WETH
  WHITELIST_ADDRESS: ['0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b'],
  KOF_ADDRESS: '',
  REVENUE_SHARE_ADDRESS: '',
  MUTATION_ADDRESS: '',
  ENS_ADDRESS: '',
  burnStakingAddress: '',
  defaultTokenPrice: 0.5,
};

//    TEST SC
//   const ARBITRUM_MAINNET = {
//   boosterTokenAddress: '0x374cEeed0409C125A2c2a77d07Cf540a00f2c144', // updated
//   platformToken: '0x50ad6F378AB77842a74B2a2Ae5B18cBF7f4ABE21', // updated
//   platformWrappedToken: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1', // updated
//   platformWrappedTokenId: 'weth',
//   referral: '0xAea307c1a9AeEa3AaB819A79a4Af6b998ea879Fb',
//   masterchef: '0x79c88F040124a4C7F75c04d0Ba15D38d627c20E0',
//   usdcAddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
//   platformTokenLPAddress: '0x45543676375b413965Ddc577C88D6019A559b089', // TOKEN-USDC
//   WHITELIST_ADDRESS: ['0x50ad6F378AB77842a74B2a2Ae5B18cBF7f4ABE21'],
//   KOF_ADDRESS: '',
//   REVENUE_SHARE_ADDRESS: '',
//   MUTATION_ADDRESS: '',
//   ENS_ADDRESS: '',
// };

const TESTNET = {
  boosterTokenAddress: '',
  platformToken: '',
  platformWrappedToken: '',
  platformWrappedTokenId: '',
  referral: '',
  masterchef: '',
  usdcAddress: '',
  platformTokenLPAddress: '',
  WHITELIST_ADDRESS: [''],
  KOF_ADDRESS: '',
  REVENUE_SHARE_ADDRESS: '',
  MUTATION_ADDRESS: '',
  ENS_ADDRESS: '',
};

export const getAddresses = (chaindId?) => {
  const savedChainId = window.localStorage.getItem('chainId');
  if (!savedChainId) {
    window.localStorage.setItem('chainId', '10001');
    window.location.reload();
  }
  if (+savedChainId === Networks.ETHW) return ETHW_MAINNET;
  else if (+savedChainId === Networks.ARBITRUM) return ARBITRUM_MAINNET;
};
