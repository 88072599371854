import { DEFAULD_NETWORK, getAddresses, Networks } from '../constants';

export interface StakingData {
  pid: number;
  address: string;
  stakeName?: string;
  name: string;
  stakeImage?: string;
  image: string;
  tokenAddress: string;
  rewardTokenAddress: string;
  boosterTokenAddress: string;
  stakeToken: string;
  rewardToken: string;
  ended?: boolean;
  blockstart?: number;
  blockend?: number;
  decimalsreward: string;
  LPwithEthw: string;
  rewardTokenName: string;
  farmAmount: number;
  endTimeStamp?: number;
  startTimeStamp?: number;

}
const addresses = getAddresses(DEFAULD_NETWORK);

const StakingPoolDataETHW: StakingData[] = [
  {
    pid: 0,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/dogepow.jpg',
    stakeName: 'UNIW',
    name: 'DogePOW',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x178582531538E781d20E0CdFcE9F4E548B3E7e2B',
    address: '0x1c07E6508B0941f4a512F0eb5232dA485b95745c',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'DogePOW',
    ended: true,
    blockstart: 15613700,
    blockend: 15656900,
    decimalsreward: 'gwei',
    LPwithEthw: '0x8132b0298e74f6654d2dafc2994774c44cfaed54',
    farmAmount: 23000000000,
  },
  {
    pid: 1,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/buniw.jpg',
    stakeName: 'UNIW',
    name: 'BabyUNIW',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0xD4e36a1044F9CA3ac3c598351C68518D1053F932',
    address: '0x6d3c2365170be7b90033d57BFf5Da536320D11BA',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'BUNIW',
    ended: true,
    blockstart: 15620500,
    blockend: 15663700,
    decimalsreward: 'gwei',
    LPwithEthw: '0xA38Afb3974280c654E2deA132ed8f0Dc90BD576c',
    farmAmount: 50000000000000,
  },
  {
    pid: 2,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/powge.jpg',
    stakeName: 'UNIW',
    name: 'Powge',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x172c82bdefec820309ec54debe96c16bd95f257d',
    address: '0x5Be687471726307729aBcFfBdC6e4eA262D64490',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'POWGE',
    ended: true,
    blockstart: 15629000,
    blockend: 15672200,
    decimalsreward: 'ether',
    LPwithEthw: '0x884e0ae338624d18573d06531899b90a545baa98',
    farmAmount: 5300000,
  },

  {
    pid: 3,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/powdle.jpg',
    stakeName: 'UNIW',
    name: 'Powdle',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x7c86937CD91834b1a58a280a0d00B18Db2B11431',
    address: '0x326Bbee8F03d18a04783c1c9a3D6990b768432d7',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'POWDLE',
    ended: true,
    blockstart: 15648300,
    blockend: 15691500,
    decimalsreward: 'gwei',
    LPwithEthw: '0x32dC8c2ce38ecE6dF8FE65E01e4291f4C2a9D841',
    farmAmount: 100000000,
  },

  {
    pid: 4,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/PPR.png',
    stakeName: 'UNIW',
    name: 'PPR',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0xA2D59CdC396574Da0F0Bc97a3e883b9fB72f6b88',
    address: '0x117F168709774809f74A8a81E21E8D61E26738E9',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'PPR',
    ended: false,
    blockstart: 15733000,
    blockend: 15949000,
    decimalsreward: 'ether',
    LPwithEthw: '0xb75EeecF26436d783f93e991963C2DF819530960',
    farmAmount: 50000000,
  },

  {
    pid: 6,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/SLR.png',
    stakeName: 'UNIW',
    name: 'SLR',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x5fc51602ce9c329520cb40fd245bfcc786c86e01',
    address: '0xB785e9766b12987Be4A1C95Bf80346707EB236a6',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'SOLARIS',
    ended: false,
    blockstart: 15771650,
    blockend: 15987650,
    decimalsreward: 'ether',
    LPwithEthw: '0xbf771a0dd5890978a5ed9ddcef25b94818b3e426',
    farmAmount: 1500000,
  },

  {
    pid: 5,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/shiwa.png',
    stakeName: 'UNIW',
    name: 'SHIWA',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x884c700e63f10dffe9ef3f5f6d246448a07321b7',
    address: '0x89DD31eEDFfE232efb5a247E8E822961f0Ae0422',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'SHIWA',
    ended: true,
    blockstart: 15770000,
    blockend: 15820400,
    decimalsreward: 'gwei',
    LPwithEthw: '0xfA0214ADAC969CFd8c18C1F1455A511De0F170C9',
    farmAmount: 17417000000000,
  },

  {
    pid: 6,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/POWV.png',
    stakeName: 'UNIW',
    name: 'POWV',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x2fdc2aEC4Bad85a06d87C8248716a5deDD647074',
    address: '0xdaA4b054ddA07F1dFEB0e96C2A40A6ac9d519561',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'POWV',
    ended: false,
    blockstart: 15850250,
    blockend: 16066250,
    decimalsreward: 'ether',
    LPwithEthw: '0x8a372c4865b22a12534286aeb1dc4856be84f091',
    farmAmount: 500000,
  },

  {
    pid: 7,
    stakeImage: 'img/tokens/UNIW.png',
    image: 'img/tokens/POWPAD.png',
    stakeName: 'UNIW',
    name: 'POWPAD',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    rewardTokenAddress: '0x552B0c29966A4B90002ffd3DAA8F107439d242E7',
    address: '0xb774739E6c20779F5E62FFFd15Ef3cA654639392',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'UNIW',
    rewardToken: '',
    rewardTokenName: 'POWPAD',
    ended: false,
    blockstart: 15907700,
    blockend: 16231700,
    decimalsreward: 'ether',
    LPwithEthw: '0x7af38b2394c2475b49093fc9c0215e6207db0381',
    farmAmount: 50000000,
  },
];

const StakingPoolDataARB: StakingData[] = [
  {
    pid: 1,
    stakeImage: 'img/tokens/RDM.png',
    image: 'img/tokens/UNIA.png',
    stakeName: 'RDM',
    name: 'UNIA',
    tokenAddress: '0x88bC4094F07C3b4ad7c14787Af57cEa6c6F5C291',
    rewardTokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    address: '0x074B47E1F691D90bae6692303aa75995548a7feF',
    boosterTokenAddress: addresses.boosterTokenAddress,
    stakeToken: 'RDM',
    rewardToken: 'UNIA',
    rewardTokenName: 'UNIA',
    ended: false,
    blockstart: 16336000,
    blockend: 17056000,
    decimalsreward: 'ether',
    LPwithEthw: '',
    farmAmount: 500000,
    endTimeStamp: 1685904797000,
    startTimeStamp: 1672866344000,
  },
];

export const StakingPoolData = () => {
  const savedChainId = window.localStorage.getItem('chainId');
  if (+savedChainId === Networks.ETHW) return StakingPoolDataETHW;
  else if (+savedChainId === Networks.ARBITRUM) return StakingPoolDataARB;
};
