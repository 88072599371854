import './index.css';

import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import SnackMessage from './component/Messages/snackbar';
import { Web3ContextProvider } from './hooks';
import { RefreshContextProvider } from './contexts/RefreshContext';

ReactDOM.render(
  <SnackbarProvider
    // maxSnack={4}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    content={(key, message: string) => <SnackMessage id={key} message={JSON.parse(message)} />}
    autoHideDuration={10000}
  >
    <RefreshContextProvider>
      <Web3ContextProvider>
        <App />
      </Web3ContextProvider>
    </RefreshContextProvider>
  </SnackbarProvider>,
  document.getElementById('root')
);
