import { DEFAULD_NETWORK, getAddresses, Networks } from '../constants';
import { useWeb3Context } from '../hooks';

export interface FarmData {
  pid: number;
  address: string;
  name: string;
  image: string;
  tokenAddress: string;
  boosterTokenAddress: string;
  MUTATION_ADDRESS: string;
  stakeToken: string;
  rewardToken: string;
  isPrivate: boolean;
  isLp: boolean;
  isLocked?: boolean;
  ended?: boolean;
  emergencyWithdrawFee?: number;
  lockedFarmAddress?: string;
}
const addresses = getAddresses(DEFAULD_NETWORK);

const commonAddress = {
  address: addresses.masterchef,
  boosterTokenAddress: addresses.boosterTokenAddress,
  MUTATION_ADDRESS: addresses.MUTATION_ADDRESS,
};

const TokenLaunchPageDataETHW: FarmData[] = [
  {
    pid: 0,
    image: 'img/tokens/UNIW.png',
    name: 'UNIW',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    ...commonAddress,
  },
  {
    pid: 1,
    image: 'img/tokens/ethw.png',
    name: 'WETHW',
    tokenAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    ...commonAddress,
  },

  {
    pid: 2,
    image: 'img/tokens/UNIW-ETHW.png',
    name: 'UNIW-ETHW',
    tokenAddress: '0x7a69ef644af299d60c668a5194ae3ee6c9d1b7e2',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 5,
    image: 'img/tokens/UNIW-USDC.png',
    name: 'UNIW-USDC',
    tokenAddress: '0xfd244c99e6da82953c8d0f673aaf9a0755e963c0',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },

  {
    pid: 10,
    image: 'img/tokens/UNIW-PPR.png',
    name: 'UNIW-PPR',
    tokenAddress: '0x3dCD94019ebf97019d81229583Bb3EA5B9f9AfD0',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 12,
    image: 'img/tokens/UNIW-SLR.png',
    name: 'UNIW-SLR',
    tokenAddress: '0x23103F8a0A9B8585762Ab3cD79e1667A1f5C163a',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 14,
    image: 'img/tokens/UNIW-POWV.png',
    name: 'UNIW-POWV',
    tokenAddress: '0xb73612b0C2e1EB804bA53459321eE56Ee20F06F2',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 17,
    image: 'img/tokens/UNIW-PP.png',
    name: 'UNIW-PP',
    tokenAddress: '0xc230f36fa88c963ffe626872348592772d4a0801',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 11,
    image: 'img/tokens/PPR-ETHW.png',
    name: 'PPR-ETHW',
    tokenAddress: '0xb75EeecF26436d783f93e991963C2DF819530960',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },

  {
    pid: 13,
    image: 'img/tokens/SLR-ETHW.png',
    name: 'SLR-ETHW',
    tokenAddress: '0xbf771a0dd5890978a5ed9ddcef25b94818b3e426',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 15,
    image: 'img/tokens/POWV-ETHW.png',
    name: 'POWV-ETHW',
    tokenAddress: '0x8a372c4865b22a12534286aeb1dc4856be84f091',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 16,
    image: 'img/tokens/POWV-USDC.png',
    name: 'POWV-USDC',
    tokenAddress: '0x28f749fd7e0d98e39dca9d0fc215b4f32b8230c2',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 18,
    image: 'img/tokens/PP-ETHW.png',
    name: 'PP-ETHW',
    tokenAddress: '0x7af38b2394c2475b49093fc9c0215e6207db0381',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 6,
    image: 'img/tokens/ETHW-USDC.png',
    name: 'ETHW-USDC',
    tokenAddress: '0xa3730c5a7a599b72fb888c8aa753a7ab9c4fa4b7',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 7,
    image: 'img/tokens/ETH-USDC.png',
    name: 'ETH-USDC',
    tokenAddress: '0x22c87ef36998ccb7d39f34b4c5e5e4fc936587c3',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 8,
    image: 'img/tokens/WBTC-USDC.png',
    name: 'WBTC-USDC',
    tokenAddress: '0x5a6e9ad9e28c6dc9fff70df95e552d3e058c50db',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 9,
    image: 'img/tokens/USDC-USDT.png',
    name: 'USDC-USDT',
    tokenAddress: '0xf3780a9405ae91627e1ca591554ace29662ab891',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 20,
    image: 'img/tokens/UNIW.png',
    name: 'UNIW',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    lockedFarmAddress: '0xd5890fa02b8630ee950b66bf63bcdd7f004c6c6a',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    isLocked: true,
    ended: false,
    emergencyWithdrawFee: 50,
    ...commonAddress,
  },
  {
    pid: 21,
    image: 'img/tokens/UNIW.png',
    name: 'OLD UNIW',
    tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
    lockedFarmAddress: '0x55AE922B921C258ba3a680e500C6C2339dA53e24',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    isLocked: true,
    ended: true,
    emergencyWithdrawFee: 50,
    ...commonAddress,
  },
  // {
  //   pid: 20,
  //   image: 'img/tokens/UNIW.png',
  //   name: 'OLD UNIW',
  //   tokenAddress: '0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33',
  //   lockedFarmAddress: '0x4F0C020D52C815f8D3A914361Dc47a076099653c',
  //   stakeToken: '',
  //   rewardToken: '',
  //   isPrivate: false,
  //   isLp: false,
  //   isLocked: true,
  //   ended: true,
  //   emergencyWithdrawFee: 50,
  //   ...commonAddress,
  // },

  // {
  //   pid: 1,
  //   image: 'img/tokens/DOGECOIN.png',
  //   name: 'WWDOGE',
  //   tokenAddress: '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101',
  //   address: addresses.masterchef,
  //   boosterTokenAddress: addresses.boosterTokenAddress,
  //   stakeToken: 'dogecoin',
  //   rewardToken: 'dogecoin',
  //   isPrivate: false,
  //   isLp: false,
  // },
  // {
  //   pid: 4,
  //   image: 'img/tokens/BORK-WDOGE.svg',
  //   name: 'BORK-WWDOGE',
  //   tokenAddress: '0xF7bf976023Be98c081ea0BD3dBD1eBeeF14939b9',
  //   address: addresses.masterchef,
  //   boosterTokenAddress: addresses.boosterTokenAddress,
  //   stakeToken: '',
  //   rewardToken: '',
  //   isPrivate: false,
  //   isLp: true,
  // },
  // {
  //   pid: 3,
  //   image: 'img/tokens/BORK-USDC.svg',
  //   name: 'BORK-USDC',
  //   tokenAddress: '0x5B3dec97cD5D84762788bBA29515eCb5342024dc',
  //   address: addresses.masterchef,
  //   boosterTokenAddress: addresses.boosterTokenAddress,
  //   stakeToken: '',
  //   rewardToken: '',
  //   isPrivate: false,
  //   isLp: true,
  // },
  // {
  //   pid: 2,
  //   image: 'img/tokens/WDOGE-USDC.svg',
  //   name: 'WWDOGE-USDC',
  //   tokenAddress: '0x4697CE3bB93EFF2D10505A310882c8CfaA6267Fe',
  //   address: addresses.masterchef,
  //   boosterTokenAddress: addresses.boosterTokenAddress,
  //   stakeToken: '',
  //   rewardToken: '',
  //   isPrivate: false,
  //   isLp: true,
  // },
];

const TokenLaunchPageDataARB: FarmData[] = [
  {
    pid: 0,
    image: 'img/tokens/UNIA.png',
    name: 'UNIA',
    tokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: false,
    ...commonAddress,
  },
  {
    pid: 1,
    image: 'img/tokens/UNIA.png',
    name: 'UNIA',
    lockedFarmAddress: '0x0BB439f39BceF78cc4D90C179449f35d9F5449AF',
    tokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    isLocked: true,
    emergencyWithdrawFee: 50,
    ...commonAddress,
  },
  {
    pid: 12,
    image: 'img/tokens/UNIA.png',
    name: 'OLD UNIA',
    lockedFarmAddress: '0x1DCc44172cC56c852b18BBF62a3614e215e944Dd',
    tokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    isLocked: true,
    ended: true,
    emergencyWithdrawFee: 50,
    ...commonAddress,
  },

  // {
  //   pid: 1,
  //   image: 'img/tokens/UNIA.png',
  //   name: 'OLD UNIA',
  //   lockedFarmAddress: '0x3aB7560738Ea7132859981f1004fC16DC79e444A',
  //   tokenAddress: '0xe547FaB4d5ceaFD29E2653CB19e6aE8ed9c8589b',
  //   stakeToken: '',
  //   rewardToken: '',
  //   isPrivate: false,
  //   isLp: false,
  //   isLocked: true,
  //   ended: true,
  //   emergencyWithdrawFee: 50,
  //   ...commonAddress,
  // },
  {
    pid: 9,
    image: 'img/tokens/UNIA-WETH.png',
    name: 'UNIA-ETH',
    tokenAddress: '0x00f1a626e112c66adfdc1a7ebe8ad6a1da4e2eb3',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 13,
    image: 'img/tokens/UNIA-WETH.png',
    name: 'UNIA-ETH',
    tokenAddress: '0x8eca576f6fa15430be697afbd7f6e98cb3daf399',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 14,
    image: 'img/tokens/UNIA-USDC.png',
    name: 'UNIA-USDC',
    tokenAddress: '0xBeA8f68177A584fBcF3A764Bf73003480E109509',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 15,
    image: 'img/tokens/ETH-ARB.png',
    name: 'ETH-ARB',
    tokenAddress: '0xb55467A80B3d2436e850C221613b1B8262410822',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 16,
    image: 'img/tokens/ETH-USDC.png',
    name: 'ETH-USDC',
    tokenAddress: '0x8EC18E07dBE5E4C4FEA95E187F1Eb33b0FD3Ec18',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  
  {
    pid: 17,
    image: 'img/tokens/USDC-USDT.png',
    name: 'USDC-USDT',
    tokenAddress: '0xf20Db555F7be1a3457Ca063E9667aB1f11d4594c',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  
  {
    pid: 10,
    image: 'img/tokens/UNIA-USDC.png',
    name: 'UNIA-USDC',
    tokenAddress: '0xE05151812a51152FDac29423623561C295B9730e',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },
  {
    pid: 11,
    image: 'img/tokens/UNIA-FISH.png',
    name: 'UNIA-FISH',
    tokenAddress: '0xB7462683f6A80AA1462fAE2DD067F0B6aEEb5C25',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ended: false,
    ...commonAddress,
  },
  {
    pid: 2,
    image: 'img/tokens/WETH-USDC.png',
    name: 'ETH-USDC',
    tokenAddress: '0x8Bc2Cd9DAB840231A0Dab5B747b8A6085c4eA459',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },

  {
    pid: 8,
    image: 'img/tokens/ETH.png',
    name: 'WETH',
    lockedFarmAddress: '0xE6F0fe2C7f524AF7d17475Fe04A15485DE206F50',
    tokenAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    stakeToken: 'weth',
    rewardToken: '',
    isPrivate: false,
    isLp: false,
    isLocked: true,
    emergencyWithdrawFee: 50,
    ...commonAddress,
  },

  {
    pid: 3,
    image: 'img/tokens/GMX-ETH.png',
    name: 'GMX-ETH',
    tokenAddress: '0xC6677c165c449DA0e8287F038947CfB5B9dD2F54',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 4,
    image: 'img/tokens/DPX-ETH.png',
    name: 'DPX-ETH',
    tokenAddress: '0x6663e6Beec883F60A240930217cC5fb948F62f16',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 5,
    image: 'img/tokens/USDC-USDT.png',
    name: 'USDC-USDT',
    tokenAddress: '0x106ae154e4c24b6e11e70cfee7e075b14a182244',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ...commonAddress,
  },

  {
    pid: 6,
    image: 'img/tokens/DAI-USDC.png',
    name: 'USDC-DAI',
    tokenAddress: '0x978de910e7645fa7861af89a156d3c86ad09db79',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
  {
    pid: 7,
    image: 'img/tokens/USDC-FRAX.png',
    name: 'USDC-FRAX',
    tokenAddress: '0x85710698eb1fb588af3996a268e73938795bf558',
    stakeToken: '',
    rewardToken: '',
    isPrivate: false,
    isLocked: false,
    isLp: true,
    ended: true,
    ...commonAddress,
  },
];

export const swapfishArray: string[] = [
  '0x00f1a626e112c66adfdc1a7ebe8ad6a1da4e2eb3',
  '0xE05151812a51152FDac29423623561C295B9730e',
  '0xB7462683f6A80AA1462fAE2DD067F0B6aEEb5C25',
  '0x8Bc2Cd9DAB840231A0Dab5B747b8A6085c4eA459',
  '0xC6677c165c449DA0e8287F038947CfB5B9dD2F54',
  '0x6663e6Beec883F60A240930217cC5fb948F62f16',
  '0x106ae154e4c24b6e11e70cfee7e075b14a182244',
  '0x978de910e7645fa7861af89a156d3c86ad09db79',
  '0x85710698eb1fb588af3996a268e73938795bf558',
];

export const TokenLaunchPageData = () => {
  const savedChainId = window.localStorage.getItem('chainId');
  if (+savedChainId === Networks.ETHW) return TokenLaunchPageDataETHW;
  else if (+savedChainId === Networks.ARBITRUM) return TokenLaunchPageDataARB;
};
