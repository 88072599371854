import { ethers } from 'ethers';

import { BoosterToken, LockedFarmABI, MutationABI } from '../abi';
import type { FarmData } from '../data/FarmData';
import nftRarities from '../data/NFTRarities';
import { convertFromWei } from '../utils/convert-wei';
import GetTokenPrice from '../utils/get-bork-price';
import type { MoralisNFTModel } from '../utils/moralis';
import { toFixed2 } from '../utils/tofixed4';
import getFarmPrice from './getFarmPrice';
import BN from 'bn.js';

export default class LockedFarmHelper {
  public contract;
  // public cache = {};

  constructor(public contractAddress: string, public provider) {
    this.contract = new ethers.Contract(contractAddress, LockedFarmABI, provider);
  }

  public async minDepositAllowed() {
    const minDepositAllowedValue = await this.contract.minDepositAllowed();
    return +convertFromWei(minDepositAllowedValue.toString());
  }

  public async lockingPeriod() {
    const minDepositAllowedValue = await this.contract.minDepositAllowed();
    return +convertFromWei(minDepositAllowedValue.toString());
  }

  public async stakingFee() {
    const stakingFeeValue = await this.contract.depositFeeInPer();
    return (Number(stakingFeeValue) / 100).toFixed(2);
  }

  public async emergencyWithdrawFee() {
    const emergencyWithdrawFeeValue = await this.contract.emergencyWithdrawFee();
    return (Number(emergencyWithdrawFeeValue) / 100).toFixed(0);
  }

  public async depositLockingPeriod() {
    const depositLockingPeriodValue = await this.contract.depositLockingPeriod();
    return Number(depositLockingPeriodValue);
  }

  public async harvestLockingPeriod() {
    const harvestLockingPeriodValue = await this.contract.harvestLockingPeriod();
    return Number(harvestLockingPeriodValue);
  }

  public async unlockTime(address) {
    const info = await this.contract.getUserInfo(address);
    return Number(info[2]);
  }

  public async harvestUnlockTime(address) {
    const info = await this.contract.getUserInfo(address);
    return Number(info[3]);
  }

  public async stake(amount: number, nft?: MoralisNFTModel) {
    let txStakeNFT;
    if (nft) {
      txStakeNFT = await this.contract.depositNFT(nft.token_address, nft.token_id);
      await txStakeNFT.wait();
    }

    let txStake;
    if (amount > 0) {
      txStake = await this.contract.deposit(ethers.utils.parseEther(amount.toString()));
      await txStake.wait();
    }
    return { txStakeNFT, txStake };
  }

  public async unstake(pid: number, amount: number) {
    const txUnstake = await this.contract.withdraw(ethers.utils.parseEther(amount.toString()));
    return txUnstake;
  }

  public async onUnstakeEmergency() {
    const txUnstake = await this.contract.emergencyWithdraw();
    return txUnstake;
  }

  public async unstakeNFT() {
    const txUnstakeNFT = await this.contract.withdrawNFT();
    return txUnstakeNFT;
  }

  public async harvestReward() {
    const txharvestReward = await this.contract.harvestReward();
    return txharvestReward;
  }

  public async userStakedAmount(address: string) {
    if (!address) {
      return new BN(0);
    }
    const info = await this.contract.getUserInfo(address);
    return info[0] as BN;
  }

  public async pendingReward(address: string) {
    if (!address) {
      return new BN(0);
    }

    return this.contract.pendingReward(address) as Promise<BN>;
  }

  public async getNftDetails(address: string, boosterTokenAddress: string, MUTATION_ADDRESS: string) {
    if (!address) {
      return null;
    }
    const value = await this.contract.stakedNFTDetails(address);
    const nftId = Number(value[1]);

    if (nftId === 0) {
      return null;
    }

    if (MUTATION_ADDRESS) {
    } else {
    }
    const mutationContract = new ethers.Contract(MUTATION_ADDRESS, MutationABI, this.provider);
    const isMuted = (await mutationContract.isMutated(nftId)) || false;
    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, this.provider);
    const nftType = await boosterTokenContract.getNftType(nftId);

    const nftDetails = nftRarities(nftType);
    const image = isMuted
      ? 'https://uniwpunk-mutant-nfts.s3.amazonaws.com/' + nftId + '.png'
      : 'https://uniwpunk-nfts.s3.amazonaws.com/' + nftId + '.png';
    return { ...nftDetails, id: nftId, image };
  }
}
