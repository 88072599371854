import { ethers } from 'ethers';

import { BoosterToken, revenueShareABI } from '../abi';
import IDOnftRarities from '../data/IDONFTRarities';
import reveneShareNFTRarities from '../data/reveneShareNFTRarities';
import { convertFromWei, convertToWei } from '../utils/convert-wei';

export default class RevenueShareHelper {
  public contract;
  // public cache = {};

  constructor(public contractAddress: string, public provider) {
    this.contract = new ethers.Contract(contractAddress, revenueShareABI, provider.getSigner());
  }

  public async balanceOf(address: string) {
    const stakedBalanceUser = await this.contract.balanceOf(address);
    return convertFromWei(stakedBalanceUser.toString());
  }

  public async unlockTime(address: string) {
    const unlockTime = await this.contract.unlockTime(address);
    return Number(unlockTime);
  }

  public async earned(address: string) {
    const earned = await this.contract.earned(address);
    return convertFromWei(earned.toString());
  }

  public async lockingPeriod() {
    const lockingPeriod = await this.contract.lockingPeriod();
    return Number(lockingPeriod);
  }

  public async extensionInterval() {
    const extensionInterval = await this.contract.extensionInterval();
    return Number(extensionInterval);
  }

  public async stake(stakeValue: string) {
    const tx = await this.contract.stake(convertToWei(stakeValue));
    return tx;
  }

  public async withdraw(unstakeValue: string) {
    const tx = await this.contract.withdraw(convertToWei(unstakeValue));
    return tx;
  }

  public async claim() {
    const tx = await this.contract.getReward();
    return tx;
  }

  public async approve(stakeValue: string) {
    const tx = await this.contract.stake(convertToWei(stakeValue));
    return tx;
  }

  public async getTotalRevenues() {
    const totalRevenues = await this.contract.getTotalRevenues();
    return convertFromWei(totalRevenues.toString());
  }

  public async rewardRate() {
    const rewardRate = await this.contract.rewardRate();
    return Number(rewardRate);
  }

  public async unstakeNFT() {
    const tx = await this.contract.withdrawNFT();
    return tx;
  }

  public async stakeNFT(boosterTokenAddress, nftId) {
    const tx = await this.contract.depositNFT(boosterTokenAddress, nftId);
    return tx;
  }

  public async getNftDetails(boosterTokenAddress: string, address: string) {
    const nftDetailsId = await this.contract.stakedNFTDetails(address);
    let nftId = Number(nftDetailsId[1]);

    if (nftId === 0) {
      return null;
    }

    const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, this.provider);
    // const tx = await this.contract.stakeNFT(boosterTokenAddress, nftId);
    const nftType = await boosterTokenContract.getNftType(nftId);

    const nftRarity = reveneShareNFTRarities(nftType);
    const nftDetails = reveneShareNFTRarities(nftType);
    const image = 'https://uniwpunk-nfts.s3.amazonaws.com/' + nftId + '.png';
    return { ...nftDetails, id: nftId, image, rarityDetails: nftRarity };
  }
}
