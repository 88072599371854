import { Box, Button, Skeleton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React from 'react';

import NotistakeLoaderButton from '../NotistakeLoaderButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src="./img/close_circle_ppup.svg" alt="" className="close_crcl_img_ppup" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface RarityBosterModelProps {
  farmName: string;
  apr;
  onUnstakeNFT;
  userStaked;
  depositLockingPeriod?;
  isLocked;
  nftDetailsAPI: (
    loadingUI: any,
    activeUI: (data: { id: number; name: string; discount: number; percentage: string; image: string; lockedBoost: number }) => JSX.Element,
    errorUI?: (err: any) => JSX.Element
  ) => any;
}

export default function RarityBosterModel({
  farmName,
  apr,
  onUnstakeNFT,
  userStaked,
  nftDetailsAPI,
  isLocked,
  depositLockingPeriod,
}: RarityBosterModelProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUnstakeNowClicked = async () => {
    await onUnstakeNFT(true);
    handleClose();
  };
  return (
    <>
      <Button className="plus_btn" onClick={handleClickOpen}>
        <Box className="bstr_ntp">
          {nftDetailsAPI(
            <Skeleton variant="rectangular" height={34} width={80} />,
            (data) => (
              <Typography>+{data.discount}%</Typography>
            ),
            (err) => (
              <Typography>Error...</Typography>
            )
          )}
          <Button className="rty_bx">Rarity</Button>
        </Box>
      </Button>
      <Box className="modal_boost_frst">
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="farm_popup_main">
          <Box className="gamble_ppup gamble_ppup_v2">
            <Box className="farm_txt_bxx">
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Box component="h1" className="farmname">
                  {farmName}
                </Box>
              </BootstrapDialogTitle>
              <Box className="gamble_txt_img">
                <Typography>
                  UniwDex
                  <img src="./img/arrow_up_icon.svg" alt="" />
                </Typography>
              </Box>
            </Box>
            <DialogContent className="five_img_cntr five_img_cntr_pads">
              <Box component="h3" className="slect_bstext">
                Unstake Booster
              </Box>
              <Box className="imag_catri_bx">
                {nftDetailsAPI(
                  <Skeleton variant="rectangular" height={80} width={80} />,
                  (data) => (
                    <img src={data.image} alt="" className="popup_rarity_ic" height={80} width={80} />
                  ),
                  (err) => (
                    <Typography>Error...</Typography>
                  )
                )}
                <Box className="span_text">
                  <Typography>
                    {nftDetailsAPI(
                      <Skeleton variant="rectangular" height={34} width={80} />,
                      (data) => (
                        <>
                          <span>Token ID: </span>
                          {data.id}
                        </>
                      ),
                      (err) => (
                        <Typography>Error...</Typography>
                      )
                    )}
                  </Typography>
                  <Typography className="text_margin">
                    {nftDetailsAPI(
                      <Skeleton variant="rectangular" height={34} width={80} />,
                      (data) => (
                        <>
                          <span>Booster Category:</span>
                          {data.name}
                        </>
                      ),
                      (err) => (
                        <Typography>Error...</Typography>
                      )
                    )}
                  </Typography>
                  <Typography>
                    {nftDetailsAPI(
                      <Skeleton variant="rectangular" height={34} width={80} />,
                      (data) => (
                        <>
                          <span>Boost:</span>
                          {data.discount}%
                        </>
                      ),
                      (err) => (
                        <Typography>Error...</Typography>
                      )
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box className="nft_boost nft_boost_btn_spn">
                <img src="./img/i_140_img_ppup.svg" alt="" />
                <Typography component="h5">
                  {nftDetailsAPI(
                    <Skeleton variant="rectangular" height={34} width={80} />,
                    (data) =>
                      isLocked ? (
                        <>
                          <span className="boost_green_text">
                            {depositLockingPeriod} - {data.lockedBoost}%{' '}
                          </span>{' '}
                          = {depositLockingPeriod - (depositLockingPeriod * data.lockedBoost) / 100} Locking Period
                        </>
                      ) : (
                        <>
                          <span className="boost_green_text">
                            {apr(-data.discount)}% APR- {data.discount}%{' '}
                          </span>{' '}
                          = {apr()}% APR
                        </>
                      ),
                    (err) => (
                      <Typography>Error...</Typography>
                    )
                  )}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions className="stake_btn_box">
              <NotistakeLoaderButton onClick={onUnstakeNowClicked} className="stake_btn">
                Unstake Now
              </NotistakeLoaderButton>
            </DialogActions>
          </Box>
        </BootstrapDialog>
      </Box>
    </>
  );
}
