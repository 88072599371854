import { Box, Skeleton, TableCell, TableRow } from '@mui/material';

export const FarmRowSkeleton = (
  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell component="th" scope="row">
      <Box display="flex" gap={1}>
        <Skeleton variant="rectangular" height={34} width={34} />
        <Skeleton variant="rectangular" height={34} width={48} />
      </Box>
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="rectangular" height={34} width={48} />
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="rectangular" height={34} width={48} />
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="rectangular" height={34} width={48} />
    </TableCell>
    <TableCell align="center">
      <Box display="flex" gap={1}>
        <Skeleton variant="rectangular" height={34} width={48} />
        <Skeleton variant="rectangular" height={34} width={48} />
      </Box>
    </TableCell>
    <TableCell align="center">
      <Box display="flex" gap={1}>
        <Skeleton variant="rectangular" height={34} width={48} />
        <Skeleton variant="rectangular" height={34} width={48} />
      </Box>
    </TableCell>
    <TableCell align="center">
      <Box display="flex" gap={1}>
        <Skeleton variant="rectangular" height={34} width={48} />
        <Skeleton variant="rectangular" height={34} width={48} />
      </Box>
    </TableCell>
    <TableCell align="center">
      <Box display="flex" gap={1}>
        <Skeleton variant="rectangular" height={34} width={48} />
        <Skeleton variant="rectangular" height={34} width={48} />
      </Box>
    </TableCell>
    <TableCell align="center">
      <Skeleton variant="rectangular" height={44} width={98} sx={{ borderRadius: 4 }} />
    </TableCell>
  </TableRow>
);
