import React, { useState, useMemo } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import Countdown from 'react-countdown';
// import useSound from 'use-sound'

const TillCollapseCard = ({ hasWinner, nextStartTime, lastBidTime, endOfAuction, kofBalance, price }) => {
  const balance = kofBalance;

  const isWaitingForNextGame = nextStartTime * 1000 > Date.now();

  const isRunning = lastBidTime !== 0;

  return (
    <div className="kingBoxTop">
      <img src="/img/king/king_logo.png" className="kinglogo" alt="" />

      {isWaitingForNextGame ? (
        <>
          <div className='statusheader'>
          <h3>NEXT ROUND IN</h3>
          </div>
          <div className="roundStatus">
          <h3 className="fire-text">
            <Countdown date={nextStartTime * 1000} />
          </h3>
          </div>
          <h3>Prepare for battle!</h3>
        </>
      ) : (
        <>
          {' '}
          <div className='statusheader'>
          <h3>ROUND STATUS</h3>
          </div>
          {isRunning ? (
            <div className="roundStatus">
              {hasWinner ? (
                <h3> Waiting For Claim</h3>
              ) : endOfAuction ? (
                <h3 className="fire-text">
                  <Countdown key={endOfAuction} date={endOfAuction} />
                </h3>
              ) : (
                <Skeleton width={300} height={0} />
              )}
            </div>
          ) : (
            <div className="roundStatus">
              <h3>Waiting for Bids</h3>
            </div>
          )}
          <div className="rewardbox">
            <img src="./img/pot-full-of-gold.png" alt="" style={{ height: '15px', width: '15px', marginTop: '0px' }} />
            <p>
              {balance.toFixed(2)} UNIW <br />
            </p>
            <p>{`~$` + (balance * price).toFixed(2)}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default TillCollapseCard;
