import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useMemo, useState } from 'react';

import { ZbetMasterChef } from '../abi';
import FarmRow from '../component/farm/FarmRow';
import FarmRowMobile from '../component/farm/FarmRowMobile';
import { FarmRowSkeleton } from '../component/farm/FarmRowSkeleton';
import MarketData from '../component/marketData/marketData';
import { getAddresses, Networks } from '../constants';
import { swapfishArray, TokenLaunchPageData } from '../data/FarmData';
import ReferralHelper from '../helpers/ReferralHelper';
import ZbetMasterChefHelper from '../helpers/ZbetMasterChefHelper';
import { useWeb3Context } from '../hooks';
import { useAsyncRender } from '../hooks/useApi';
import useWindowSize from '../hooks/useWindowSize';
import getApiCanceller from '../utils/ApiCanceller';
import { convertFromWei } from '../utils/convert-wei';
import { useFooterData } from '../utils/getFooterData';
import { toFixed2 } from '../utils/tofixed4';
import { numAbbr } from '../utils/unit-convert-utils';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FarmRowLocked from '../component/farm/FarmRowLocked';
import FarmRowMobileLocked from '../component/farm/FarmRowMobileLocked';

export default function Farm({ props }: any) {
  const { mobile } = useWindowSize();
  const [showOnlyPrivate] = useState(false);
  const [showOnlyEnded, setShowOnlyEnded] = useState(false);
  const [showLockedFarm, setShowLockedFarm] = useState(false);
  const { provider, address, chainID } = useWeb3Context();
  const [tokenPerDay, setTokenPerDay] = React.useState(0);
  const [tokenPerDayNext, setTokenPerDayNext] = React.useState(0);
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';

  const { referral, masterchef } = useMemo(() => getAddresses(chainID), [chainID]);
  // const [tvl, setTVL] = React.useState(0);
  const reffcontract = useMemo(() => new ReferralHelper(referral, provider.getSigner()), [referral, provider]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { marketCap, maxSupply: dmarketCap, remainingSupply } = useFooterData();

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    const contract = new ethers.Contract(masterchef, ZbetMasterChef, provider);
    verify(contract.tokenPerBlock())
      .then((res: BigNumber) => convertFromWei(res.toString()))
      .then((tokenPerBlock) => {
        const blocksPerDay = (60 * 60 * 24) / 13;
        const borkPerDay = +tokenPerBlock * blocksPerDay;
        const borkPerDayNext = borkPerDay === 0 ? 0 : remainingSupply / borkPerDay;
        setTokenPerDay(borkPerDay);
        setTokenPerDayNext(borkPerDayNext);
      }, errorHandler);
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketCap, dmarketCap, provider, remainingSupply]);

  const reffCount = useAsyncRender(() => reffcontract?.reffCount(address), [reffcontract]);

  const reffEarned = useAsyncRender(() => reffcontract?.reffEarned(address), [reffcontract]);

  const getFilterdData = async () => {
    let data = TokenLaunchPageData();
    if (showOnlyPrivate) {
      data = data.filter(({ isPrivate }) => isPrivate);
    }
    if (showOnlyEnded) {
      data = data.filter(({ ended }) => !!ended);
    } else {
      data = data.filter(({ ended }) => !ended);
    }
    return data;
  };

  const rowsRender = useAsyncRender(getFilterdData, [showOnlyPrivate, showOnlyEnded]);

  const harvestAll = async () => {
    const data = await getFilterdData();
    for (const farm of data) {
      try {
        const contract = new ZbetMasterChefHelper(farm.address, provider.getSigner());
        // Note: Remove following await to make transactions work parallelly
        const tx = await contract.unstake(farm.pid, 0);
        console.log(tx);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Box className="tknlnch_main_bx">
      <Box className="tknlnch_innr_bx">
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <Box className="hdng_grid">
              <Typography component="h2">
                <span>{tokenName} </span>
                {chainID === Networks.ETHW ? 'Swap' : 'Farm'}
              </Typography>
              <Typography>
                Yielding <span>{numAbbr(tokenPerDay)}</span> coins per day to stakers over the next{' '}
                <span>{Math.floor(tokenPerDayNext)} days</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <MarketData />
          </Grid>
        </Grid>
      </Box>

      {/* Locked Farms */}
      <>
        <Box className="frm_top_bx frm_top_bx_v2">
          <Box className="tp_lft_bx">
            <Typography component="h4">Locked Farms</Typography>
          </Box>
          {/* <Box className="tp_rght_bx">
               {+chainID === Networks.ARBITRUM && (
            <Box className="locked_farm_bx">
              <Box component="img" className="" src="/img/locked_farm.svg" />
              <Typography>Locked Farm</Typography>
              <FormGroup className="switch_fram">
                <FormControlLabel
                  control={<Switch onChange={() => setShowLockedFarm(!showLockedFarm)} checked={showLockedFarm} />}
                  label=""
                />
              </FormGroup>
            </Box>
          )} 

              <Button className="hrvstall_btn" onClick={harvestAll}>
                Harvest All
              </Button>
            </Box> */}
        </Box>
        <Box className="farms_bx">
          <Box className="tknlnch_table tknlnch_table_mob tknlnch_table_mob_farm">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" className="farm_tablee">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography className="bstd_btng_pool_prnt">Name</Typography>
                    </TableCell>
                    <TableCell align="center">Multiplier</TableCell>
                    <TableCell align="center">APR</TableCell>
                    <TableCell align="center">Liquidity</TableCell>
                    <TableCell align="center">Staked</TableCell>
                    <TableCell align="center">{tokenName} Boost</TableCell>
                    <TableCell align="center">Locking Time</TableCell>
                    <TableCell align="center">Rewards</TableCell>

                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!mobile &&
                    rowsRender(
                      <>
                        {FarmRowSkeleton}
                        {FarmRowSkeleton}
                        {FarmRowSkeleton}
                      </>,
                      (data) => (
                        <>{data.map((farm, index) => farm.isLocked && <FarmRowLocked key={farm.tokenAddress + index} farm={farm} />)}</>
                      ),
                      (err) => (
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {mobile &&
              rowsRender(
                <>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        {FarmRowSkeleton}
                        {FarmRowSkeleton}
                        {FarmRowSkeleton}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>,
                (data) => (
                  <>{data.map((farm, index) => farm.isLocked && <FarmRowMobileLocked key={farm.tokenAddress + index} farm={farm} />)}</>
                ),
                (err) => <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
              )}
            <Box mt={2}>
              <Box className="frm_top_bx">
                <Box className="tp_lft_bx"></Box>
                <Box className="tp_rght_bx">
                  <Box className="swtch_bx">
                    <span className="lable">Show Ended Farms</span>
                    <Checkbox {...label} className="cheq_box" value={showOnlyPrivate} onChange={() => setShowOnlyEnded((prev) => !prev)} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>{' '}
      </>

      {/* farms */}
      <Box className="frm_top_bx frm_top_bx_v2 mt-2">
        <Box className="tp_lft_bx">
          <Typography component="h4">UniaDex Farms</Typography>
        </Box>
        <Box className="tp_rght_bx">
          {/* {+chainID === Networks.ARBITRUM && (
            <Box className="locked_farm_bx">
              <Box component="img" className="" src="/img/locked_farm.svg" />
              <Typography>Locked Farm</Typography>
              <FormGroup className="switch_fram">
                <FormControlLabel
                  control={<Switch onChange={() => setShowLockedFarm(!showLockedFarm)} checked={showLockedFarm} />}
                  label=""
                />
              </FormGroup>
            </Box>
          )} */}
          {/* {+chainID === Networks.ETHW && (
            <Button className="hrvstall_btn" onClick={harvestAll}>
              Harvest All
            </Button>
          )} */}
        </Box>
      </Box>
      <Box className="farms_bx">
        <Box className="tknlnch_table tknlnch_table_mob tknlnch_table_mob_farm">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="farm_tablee">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography className="bstd_btng_pool_prnt">Name</Typography>
                  </TableCell>
                  <TableCell align="center">Multiplier</TableCell>
                  <TableCell align="center">APR</TableCell>
                  <TableCell align="center">Liquidity</TableCell>
                  <TableCell align="center">Staked</TableCell>
                  <TableCell align="center">{tokenName} Boost</TableCell>
                  <TableCell align="center">{showLockedFarm ? 'Locking Period' : 'Deposit Fee'}</TableCell>
                  <TableCell align="center">Rewards</TableCell>

                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!mobile &&
                  rowsRender(
                    <>
                      {FarmRowSkeleton}
                      {FarmRowSkeleton}
                      {FarmRowSkeleton}
                    </>,
                    (data) => (
                      <>
                        {data
                          .filter((farm) => !swapfishArray.includes(farm.tokenAddress))
                          .map((farm) => !farm.isLocked && <FarmRow key={farm.tokenAddress} farm={farm} />)}
                      </>
                    ),
                    (err) => (
                      <TableRow>
                        <TableCell align="center" colSpan={8}>
                          <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {mobile &&
            rowsRender(
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      {FarmRowSkeleton}
                      {FarmRowSkeleton}
                      {FarmRowSkeleton}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>,
              (data) => (
                <>
                  {data
                    .filter((farm) => !swapfishArray.includes(farm.tokenAddress))
                    .map((farm) => !farm.isLocked && <FarmRowMobile key={farm.tokenAddress} farm={farm} />)}
                </>
              ),
              (err) => <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
            )}
          <Box mt={2}>
            <Box className="frm_top_bx">
              <Box className="tp_lft_bx"></Box>
              <Box className="tp_rght_bx">
                <Box className="swtch_bx">
                  <span className="lable">Show Ended Farms</span>
                  <Checkbox {...label} className="cheq_box" value={showOnlyPrivate} onChange={() => setShowOnlyEnded((prev) => !prev)} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* SwapFish farms */}

      {+chainID === Networks.ARBITRUM && (
        <>
          <Box className="frm_top_bx frm_top_bx_v2 mt-2">
            <Box className="tp_lft_bx">
              <Typography component="h4">SwapFish Farms</Typography>
            </Box>
            <Box className="tp_rght_bx">
              {/* {+chainID === Networks.ARBITRUM && (
<Box className="locked_farm_bx">
  <Box component="img" className="" src="/img/locked_farm.svg" />
  <Typography>Locked Farm</Typography>
  <FormGroup className="switch_fram">
    <FormControlLabel
      control={<Switch onChange={() => setShowLockedFarm(!showLockedFarm)} checked={showLockedFarm} />}
      label=""
    />
  </FormGroup>
</Box>
)} */}
              {/* {+chainID === Networks.ETHW && (
<Button className="hrvstall_btn" onClick={harvestAll}>
  Harvest All
</Button>
)} */}
            </Box>
          </Box>
          <Box className="farms_bx">
            <Box className="tknlnch_table tknlnch_table_mob tknlnch_table_mob_farm">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="farm_tablee">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <Typography className="bstd_btng_pool_prnt">Name</Typography>
                      </TableCell>
                      <TableCell align="center">Multiplier</TableCell>
                      <TableCell align="center">APR</TableCell>
                      <TableCell align="center">Liquidity</TableCell>
                      <TableCell align="center">Staked</TableCell>
                      <TableCell align="center">{tokenName} Boost</TableCell>
                      <TableCell align="center">{showLockedFarm ? 'Locking Period' : 'Deposit Fee'}</TableCell>
                      <TableCell align="center">Rewards</TableCell>

                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!mobile &&
                      rowsRender(
                        <>
                          {FarmRowSkeleton}
                          {FarmRowSkeleton}
                          {FarmRowSkeleton}
                        </>,
                        (data) => (
                          <>
                            {data
                              .filter((farm) => swapfishArray.includes(farm.tokenAddress))
                              .map((farm) => !farm.isLocked && <FarmRow key={farm.tokenAddress} farm={farm} />)}
                          </>
                        ),
                        (err) => (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              {mobile &&
                rowsRender(
                  <>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                          {FarmRowSkeleton}
                          {FarmRowSkeleton}
                          {FarmRowSkeleton}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>,
                  (data) => (
                    <>
                      {data
                        .filter((farm) => swapfishArray.includes(farm.tokenAddress))
                        .map((farm) => !farm.isLocked && <FarmRowMobile key={farm.tokenAddress} farm={farm} />)}
                    </>
                  ),
                  (err) => <Typography className="bstd_btng_pool_prnt">Can not fetch data...</Typography>
                )}
              <Box mt={2}>
                <Box className="frm_top_bx">
                  <Box className="tp_lft_bx"></Box>
                  <Box className="tp_rght_bx">
                    <Box className="swtch_bx">
                      <span className="lable">Show Ended Farms</span>
                      <Checkbox
                        {...label}
                        className="cheq_box"
                        value={showOnlyPrivate}
                        onChange={() => setShowOnlyEnded((prev) => !prev)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Box className="farm_bttm_cntnt">
        <Grid container spacing={2} alignItems={'start'}>
          <Grid item xs={12} md={7}>
            <Box className="grid_spac_bx">
              <Box className="two_same_bx as_d_flx">
                <Typography sx={{ whiteSpace: 'nowrap' }}>
                  Refer a friend{' '}
                  <a href="https://docs.uniwswap.com/features/referral/" target="_blank" rel="noreferrer">
                    <HelpIcon />
                  </a>
                </Typography>
                <Box className="inpt_bx inpt_bx_v2" sx={{ width: '85%', marginLeft: '' }}>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      display: 'inline-block',
                      textOverflow: 'ellipsis',
                    }}
                    className="frbrdrrndd"
                  >
                    {`${window.location.href}?reff=${address}`}
                  </span>
                  <Button
                    onClick={() => {
                      window.navigator.clipboard.writeText(`${window.location.href}?reff=${address}`);
                    }}
                  >
                    COPY
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className="grid_spac_bx">
              <Box className="two_same_bx">
                <Box className="totle_rfle_box totle_rfle_box_v2">
                  <Typography>Total referral</Typography>
                  <>
                    {reffCount(
                      <Skeleton variant="rectangular" height={34} width={48} />,
                      (data) => (
                        <Typography component="h6">{data} Users</Typography>
                      ),
                      (err) => (
                        <Typography component="h6">Error...</Typography>
                      )
                    )}
                  </>
                </Box>
                <Box className="totle_rfle_box totle_rfle_box_v2">
                  <Typography>Total Earned</Typography>
                  <>
                    {reffEarned(
                      <Skeleton variant="rectangular" height={34} width={48} />,
                      (data) => (
                        <Typography component="h6">
                          {toFixed2(+convertFromWei(data.toString()))} {tokenName}
                        </Typography>
                      ),
                      (err) => (
                        <Typography component="h6">Error...</Typography>
                      )
                    )}
                  </>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
