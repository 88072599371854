import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';

import StakingPoolHelper from '../../helpers/StakingPoolHelper';
import { useWeb3Context } from '../../hooks';
import { useAsyncFnWithNotistake } from '../../hooks/use-notistake';
import useApi, { useAsyncRender, useDataApi } from '../../hooks/useApi';
import { convertFromWei } from '../../utils/convert-wei';
import GetTokenPrice, { getTokenPrice } from '../../utils/get-bork-price';
import { getTokenData } from '../../utils/token-data';
import { numAbbr } from '../../utils/unit-convert-utils';
import NotistakeLoaderButton from '../NotistakeLoaderButton';
import StakePopUpModel from '../tokenlaunch/StakingPoolPopUpModel';

import type { StakingData } from '../../data/StakingPoolData';
import type { MoralisNFTModel } from '../../utils/moralis';
const PoolRowMobile = (props: { farm: StakingData }) => {
  const stakePopupRef = useRef<any>();
  const { provider, address } = useWeb3Context();
  const [updateRow, setUpdateRow] = useState(0);
  const [rewardPrice, setRewardPrice] = React.useState(0);
  const withNotistake = useAsyncFnWithNotistake();
  const [tokenPrice, setTokenPrice] = useState(0);

  const {
    farm: { address: contractAddress, endTimeStamp, startTimeStamp },
  } = props;

  useEffect(() => {
    const getPrice = async () => {
      let price;
      const priceBork = await GetTokenPrice(provider);
      setTokenPrice(priceBork);
      if (props.farm.rewardToken !== '') {
        price = (await getTokenData(props.farm.rewardToken)).price;
      } else if (props.farm.LPwithEthw !== '') {
        price = await getTokenPrice(props.farm.LPwithEthw, provider);
      }
      setRewardPrice(price);
    };
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  // contract will be generated again when value of updateRow is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contract = useMemo(() => new StakingPoolHelper(contractAddress, provider), [contractAddress, provider, updateRow]);

  const liquidityAPI = useAsyncRender(() => contract?.lpTokenAmount(props.farm.pid, props.farm.tokenAddress, provider), [contract]);

  const { data: userStaked, render: userStakedAmountAPI } = useDataApi(() => contract?.userStakedAmount(address), [contract, address]);

  const rewardDebtAPI = useAsyncRender(() => contract?.rewardDebt(address), [contract, address]);

  const getTokenDataCall = useCallback(() => getTokenData(props.farm.stakeToken), [props]);
  const { data: token } = useApi(getTokenDataCall);

  const aprAPI = useAsyncRender(() => contract?.apr(props.farm), [contract, address]);
  const endInAPI = useAsyncRender(() => contract?.endInTimeStamp(), [contract, address]);
  const startinAPI = useAsyncRender(() => contract?.startInTimeStamp(), [contract, address]);

  const onStake = async (amount: number, nft: MoralisNFTModel) => {
    const contract = new StakingPoolHelper(contractAddress, provider.getSigner());
    await withNotistake('Staking', async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const tx = await contract.stake(amount);
      setUpdateRow((prev) => prev + 1);
    });
  };

  const onUnstake = async (amount: number, msg: string = 'Unstaking') => {
    const contract = new StakingPoolHelper(contractAddress, provider.getSigner());
    await withNotistake(msg, async () => {
      const tx = await contract.unstake(amount);
      await tx.wait();
      setUpdateRow((prev) => prev + 1);
    });
  };

  // const openStakePopup = () => {
  //   if (stakePopupRef.current) {
  //     stakePopupRef.current?.openPopup();
  //   }
  // };

  return (
    <Box className="mobl_frax_box">
      <Box className="frax_bx_mob frax_bx_mob_v2">
        <Box className="d-flex">
          <img height={50} width={50} src={props.farm.image ? props.farm.image : 'img/frax_ic.svg'} alt="" />
          <Box className="h-flex">
            <Typography component="h2">Earn {props.farm.name}</Typography>
            <Typography className="subtitle" ml="10px">
              Stake {props.farm.stakeToken}
            </Typography>
          </Box>
        </Box>
        {/* {aprAPI(
          <Skeleton variant="rectangular" height={34} width={48} />,
          (aprValue) => (
            <Typography className="green_txt">
              <b>{numAbbr(aprValue)}%</b>
            </Typography>
          ),
          (err) => (
            <Typography>Error...</Typography>
          )
        )} */}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box mult_h3_p_box_top">
            <Typography component="h3">Total Staked</Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="center">
            <Typography component="h3">Ends in</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box" justifyContent="flex-start">
            <Typography>
              {liquidityAPI(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>
                    {numAbbr(+convertFromWei(data.toString()), false)} <span className="lght_txt">{props.farm.stakeToken}</span>{' '}
                  </Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box" justifyContent="center">
            <Typography>
              {startinAPI(<></>, (startDate: any) => (
                <>
                  <Typography className="c_primary">
                    {startTimeStamp > Date.now() ? (
                      'Not Yet Started'
                    ) : endTimeStamp < Date.now() ? (
                      'Ended'
                    ) : (
                      <Countdown date={endTimeStamp} />
                    )}
                  </Typography>
                  {/* {endInAPI(
                    <Skeleton variant="rectangular" height={34} width={48} />,
                    (data) => (
                      <Typography className="c_primary">
                        {startDate === 0 ? 'Not Yet Started' : data < Date.now() ? 'Ended' : <Countdown date={data} />}
                      </Typography>
                    ),
                    (err) => (
                      <Typography>Error...</Typography>
                    )
                  )} */}
                </>
              ))}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={'20px'}>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box mult_h3_p_box_top">
            <Typography component="h3">My Stakes</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box mult_h3_p_box_top" justifyContent="center">
            <Typography component="h3">Rewards</Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box className="mult_h3_p_box">
            <Typography>
              <Box className="twice_txt">
                {/* <Typography>0.00</Typography> */}
                {userStakedAmountAPI(
                  <Skeleton variant="rectangular" height={34} width={48} />,
                  (data) => (
                    <Typography>{numAbbr(convertFromWei(data.toString()))}</Typography>
                  ),
                  (err) => (
                    <Typography>Error...</Typography>
                  )
                )}
                {/* {userStakedAmountAPI(
                  <Skeleton variant="rectangular" height={34} width={48} />,
                  (data) => (
                    <Typography className="lght_txt">${numAbbr(convertWei(data) * (tokenPrice || 0))}</Typography>
                  ),
                  (err) => (
                    <Typography>Error...</Typography>
                  )
                )} */}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="mult_h3_p_box" justifyContent="center">
            <Typography>
              <Box className="twice_txt twice_txt_center">
                {rewardDebtAPI(
                  <Skeleton variant="rectangular" height={34} width={48} />,
                  (rewardDebt) => (
                    <Typography>{numAbbr(convertFromWei(rewardDebt.toString(), props.farm.decimalsreward))}</Typography>
                  ),
                  (err) => (
                    <Typography>Error...</Typography>
                  )
                )}
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Box className="two_btn_m">
        <StakePopUpModel
          ref={stakePopupRef}
          tokenAddress={props.farm.tokenAddress}
          farmName={props.farm.name}
          farmImage={props.farm.image}
          stakeName={props.farm.stakeName}
          stakeImage={props.farm.stakeImage}
          userStaked={userStaked}
          onStake={onStake}
          onUnstake={onUnstake}
          contractAddress={contractAddress}
          boosterTokenAddress={props.farm.boosterTokenAddress}
          tokenPrice={token?.price || 0}
          rewardToken={props.farm.rewardToken}
          aprAPI={aprAPI}
          farmData={props.farm}
          endInAPI={endInAPI}
        />
        <NotistakeLoaderButton className="stak_btn stak_btnv2" onClick={() => onUnstake(0, 'Harvesting')}>
          Harvest
        </NotistakeLoaderButton>
      </Box>
    </Box>
  );
};

export default PoolRowMobile;
