const CACHE: Record<string, any> = {};
const DEFAULT_CACHE_TIME = 2 * 1000;
export function cacheFn<T>(key: string, fn: () => T, time = DEFAULT_CACHE_TIME): T {
  if (CACHE[key] !== undefined) {
    return CACHE[key];
  }

  if (!CACHE[key]) {
    CACHE[key] = fn();
  }

  setTimeout(() => {
    CACHE[key] = undefined;
  }, time);

  return CACHE[key];
}
