export const getMainnetURI = (chainId) => {
  switch (chainId) {
    case 10001:
      return 'https://mainnet.ethereumpow.org';
    case 42161:
      return 'https://arb1.arbitrum.io/rpc';
    default:
      return 'https://mainnet.ethereumpow.org';
  }
};
