const CACHED_PRICES = {};
const CACHED_PROMISES = {};

export async function getTokenData(id_token = 'ethereum-pow-iou') {
  const id = id_token === '' ? 'ethereum-pow-iou' : id_token;
  if (CACHED_PRICES[id]) {
    return CACHED_PRICES[id];
  }

  if (!CACHED_PROMISES[id]) {
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`;
    CACHED_PROMISES[id] = fetch(url).then((res) => res.json());
  }

  const res = await CACHED_PROMISES[id];
  CACHED_PRICES[id] = {
    price: res[id].usd,
  };

  return CACHED_PRICES[id];
}
