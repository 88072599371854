import { useState, useMemo, useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import { getAddresses } from '../../constants';
import { useWeb3Context } from '../../hooks';
import KOFHelper from '../../helpers/kofHelper';
import { ethers } from 'ethers';
import useRefresh from '../../hooks/useRefresh';

const ParticipateCard2 = ({ hasWinner, nextStartTime, bidAmount, lastWinner, isBanned, currencyContract }) => {
  const { provider, chainID, address } = useWeb3Context();
  const addresses = getAddresses(chainID);
  const kofContract = useMemo(() => new KOFHelper(addresses.KOF_ADDRESS, provider.getSigner()), [addresses, provider]);
  const [maxBal, setMaxBal] = useState(0);
  const [allowance, setAllowance] = useState(false);
  const [pendingTx, setPendingTx] = useState(false);
  const bidAmountVal = Number(bidAmount) / 1e18;
  const { ultraRefresh } = useRefresh();
  const [isClaiming, setIsClaiming] = useState(false);
  const onClaim = async () => {
    setIsClaiming(true);
    try {
      const tx = await kofContract.claimForest();
      await tx.wait();
    } catch (e) {
      console.error(e);
    }
    setIsClaiming(false);
  };

  useEffect(() => {
    (async () => {
      const maxBalance = await currencyContract.balanceOf(address);
      const decimal = await currencyContract.decimals();
      const allowvalue = await currencyContract.allowance(address, addresses.KOF_ADDRESS);
      setAllowance(allowvalue > 0 ? true : false);
      setMaxBal(Number(maxBalance) / 10 ** decimal);
    })();
  }, [address, currencyContract, ultraRefresh, addresses.KOF_ADDRESS, pendingTx]);

  const handleApprove = async () => {
    try {
      setPendingTx(true);
      const approveTx = await currencyContract.approve(addresses.KOF_ADDRESS, ethers.constants.MaxUint256);
      await approveTx.wait();
      setAllowance(true);
      setPendingTx(false);
    } catch (e) {
      console.error(e);
      setPendingTx(false);
    }
  };

  const onSubmit = async () => {
    console.log('heheh');
    try {
      setPendingTx(true);
      const tx = await kofContract.participateForest(bidAmount);
      await tx.wait();
      setPendingTx(false);
    } catch (e) {
      console.error(e);
      setPendingTx(false);
    }
  };

  const isWaitingForNextGame = nextStartTime * 1000 > Date.now();

  const BidButton = () => {
    if (isBanned) return <h4 style={{ color: 'red' }}>Your account is banned.</h4>;

    if (lastWinner === address) return <h4 style={{ color: 'red' }}>Last round winner can not bid.</h4>;

    if (hasWinner)
      return (
        <button onClick={onClaim} className="mainBtn">
          {isClaiming ? <CircularProgress size={24} className="NotistakeLoaderButton_Loader" /> : 'Restart Game'}
        </button>
      );

    return (
      <button
        onClick={() => onSubmit()}
        disabled={pendingTx || isWaitingForNextGame || (bidAmountVal && maxBal < bidAmountVal)}
        className="mainBtn"
      >
        {pendingTx ? (
          <CircularProgress size={24} className="NotistakeLoaderButton_Loader" />
        ) : bidAmount && maxBal < bidAmountVal ? (
          'Not Enough Balance'
        ) : (
          'Bid Now'
        )}
      </button>
    );
  };

  return (
    <>
      {!allowance ? (
        <button onClick={handleApprove} disabled={pendingTx} className="mainBtn">
          {pendingTx ? <CircularProgress size={24} className="NotistakeLoaderButton_Loader" /> : 'Approve contract'}
        </button>
      ) : (
        <BidButton />
      )}
    </>
  );
};

export default ParticipateCard2;
