import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { fontSize } from '@mui/system';
import { ethers } from 'ethers';
import React, { useEffect, useMemo } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { platformTokenToken } from '../abi';
import NotistakeLoaderButton from '../component/NotistakeLoaderButton';
import { getAddresses, Networks } from '../constants';
import BurnStakingHelper from '../helpers/BurnStakingHelper';
import { accountEllipsis } from '../helpers/get-account-ellipsis';
import { useWeb3Context } from '../hooks';
import { useAsyncRender } from '../hooks/useApi';
import { convertFromWei } from '../utils/convert-wei';

export default function Staking() {
  const [isApproved, setIsApproved] = React.useState(0);
  const [amount, setAmount] = React.useState('0');
  const { provider, address, chainID } = useWeb3Context();
  const { burnStakingAddress, platformToken } = useMemo(() => getAddresses(chainID), [chainID]);
  const contract = useMemo(() => new BurnStakingHelper(burnStakingAddress, provider.getSigner()), [burnStakingAddress, provider]);
  const platformTokenContract = new ethers.Contract(platformToken, platformTokenToken, provider.getSigner());
  const startTimeStamp = useAsyncRender(() => contract?.startInTimeStamp(), [contract]);
  const endInTimeStamp = useAsyncRender(() => contract?.endInTimeStamp(), [contract]);
  const participateCount = useAsyncRender(() => contract?.participateCount(), [contract]);
  const participateAmount = useAsyncRender(() => contract?.participateAmount(address), [contract, address]);
  const totalBurned = useAsyncRender(() => contract?.totalBurnValue(), [contract, address]);

  useEffect(() => {
    const savedChainId = window.localStorage.getItem('chainId');
    if (+savedChainId === Networks.ARBITRUM) window.location.href = '/';
  }, [window]);

  useEffect(() => {
    const getAllowance = async () => {
      const allowed = await platformTokenContract.allowance(address, burnStakingAddress);
      setIsApproved(+allowed.toString() / 1e18);
      // if (Number(await platformTokenContract.allowance(address, burnStakingAddress)) > 0) {
      //   setIsApproved(true);
      // } else {
      //   setIsApproved(false);
      // }
    };
    getAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformTokenContract, address]);

  const onApprove = async () => {
    const approvetx = await platformTokenContract.approve(burnStakingAddress, ethers.constants.MaxUint256);
    await approvetx.wait();
    const allowed = await platformTokenContract.allowance(address, burnStakingAddress);
    setIsApproved(+allowed.toString() / 1e18);
  };

  const generateData = async () => {
    const numberofParticipant = Number(await contract.participateCount());
    let a = [];
    for (let i = 0; i < numberofParticipant; i++) {
      const address = await contract.participateList(i);
      const value = await contract.participateAmount(address);
      a.push(address + ':' + value);
    }
    console.log(a);
  };

  const onMaxClick = async () => {
    const bal = await platformTokenContract.balanceOf(address);
    console.log(Number(bal));
    const decimalValue = await platformTokenContract.decimals();
    const decimal = decimalValue === 9 ? 'gwei' : decimalValue === 6 ? 'mwei' : 'ether';
    const balance = convertFromWei(bal.toString(), decimal);
    setAmount(balance);
  };

  const onStake = async () => {
    const staketx = await contract.stake(+amount);
    await staketx.wait();
    window.location.reload();
  };

  return (
    <>
      <Box className="tknlnch_main_bx tknlnch_main_bxas">
        <Box display="flex" gap={1} justifyContent="center" margin={5}>
          <Typography style={{ fontSize: '32px', fontWeight: 'bold' }} component="h1" className="hed_text">
            UNIW to UNIA Conversion Tool
          </Typography>
        </Box>
        <Box className="innnstakingbx">
          <Typography component="h6">Your address</Typography>
          <Typography className="wltaddrss">{accountEllipsis(address)}</Typography>
          <Typography component="h6">Deposit Amount</Typography>
          <Box className="stake_min_box stake_min_boxas">
            <Box className="imput_prnt">
              <input type="number" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
              <Button onClick={onMaxClick}>MAX</Button>
            </Box>
          </Box>
          {isApproved >= +amount ? (
            <NotistakeLoaderButton msg="Staking Uniw" onClick={onStake} className="sbmtbtn" disabled={+amount <= 0}>
              Submit
            </NotistakeLoaderButton>
          ) : (
            <NotistakeLoaderButton msg="Approving Uniw" onClick={onApprove} className="sbmtbtn">
              Approve
            </NotistakeLoaderButton>
          )}
          {/* <Button onClick={generateData}>Generate</Button> */}
          <Box className="maininfrmnt">
            <Typography component="h6">Information</Typography>
            <Typography className="infrmtn_p">
              <span>Your Investment Amount :&nbsp;</span>
              {participateAmount(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>{data} UNIW</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
            <Typography className="infrmtn_p">
              <span>Total Participant :&nbsp;</span>
              {participateCount(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>{data}</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
            <Typography className="infrmtn_p">
              <span>Total Burned :&nbsp;</span>
              {totalBurned(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => (
                  <Typography>{(+data).toFixed(0)}</Typography>
                ),
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
            <Typography className="infrmtn_p">
              <span>Start Time :&nbsp;</span>

              {startTimeStamp(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => {
                  const date = new Date(data * 1000);
                  return (
                    <Typography>
                      {date.getDate() +
                        '/' +
                        (+date.getMonth() + 1) +
                        '/' +
                        date.getFullYear() +
                        ' ' +
                        date.getHours() +
                        ':' +
                        date.getMinutes() +
                        ':' +
                        date.getSeconds()}
                    </Typography>
                  );
                },
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
            <Typography className="infrmtn_p">
              <span>End Time :&nbsp;</span>
              {endInTimeStamp(
                <Skeleton variant="rectangular" height={34} width={48} />,
                (data) => {
                  const date = new Date(data * 1000);
                  return (
                    <Typography>
                      {date.getDate() +
                        '/' +
                        (+date.getMonth() + 1) +
                        '/' +
                        date.getFullYear() +
                        ' ' +
                        date.getHours() +
                        ':' +
                        date.getMinutes() +
                        ':' +
                        date.getSeconds()}
                    </Typography>
                  );
                },
                (err) => (
                  <Typography>Error...</Typography>
                )
              )}
            </Typography>
          </Box>
        </Box>
        <Box className="alertbox nftstake">
          <Box className="alertinside">
            <Typography className="alertinfo">
              <FaExclamationTriangle />
              The conversion tool will not refund your UNIW tokens once utilized. It should also be noted that the UNIA conversion is not a
              certified profit opportunity, does not indicate positive market results, or the return of the initially invested capital, as
              markets are unpredictable.
            </Typography>
          </Box>
        </Box>
        <Box className="borde_bx_laft">
          <Box className="borde_bx_laft_iner_bnx">
            <Box className="head_p_h">
              <Typography component="h2" className="hed_text">
                Things to keep in mind
              </Typography>
            </Box>
            <Box className="last_part_frt last_part_frt_as"></Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box className="hov_to_plya">
                  <p>
                    The UNIW to UNIA conversion tool is a way for users to join the new Arbitrum launch without having to purchase tokens
                    again. Your UNIW tokens will grant you the chance to enter a pool delivering 10% of the UNIA supply over a 2 month
                    period. Simply allocate your desired UNIW amount before January 4th 2023, and you will receive RDM tokens on Arbitrum
                    that will allow you to enter the UNIA pool. Keep in mind that once you allocate your UNIW tokens you will not be able to
                    get them back and they will be burned out of circulation.
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box className="hov_to_plya">
                  <p>
                    The RDM tokens can be claimed on ARB after the end of the allocation period on January 4th, and they will be at a 1:1
                    ratio with UNIW, allowing us to calculate your pool weight accurately on Arbitrum. After receiving your RDM tokens, all
                    you have to do is farm UNIA in peace for the next 2 months. You can claim rewards at any moment and use them on UNIA
                    farms, or sell them on the market, but there is no way to speed up the redeemal process, and you will have to go through
                    the 2 month period to receive your full UNIA allocation.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
