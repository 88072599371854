import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { IDOData } from '../data/IdoData';
import { FaExclamationTriangle } from 'react-icons/fa';
import IdoCard from '../component/ido/IdoCard';
import { Networks } from '../constants';

export default function PastIdoPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const idos = IDOData().filter((ido) => !ido.isActive);
  return (
    <Box className="tknlnch_main_bx">
      <Box className="idopage_bx_min">
        <Grid container spacing={4}>
          {idos.length > 0 ? (
            idos.map((ido, index) => <IdoCard ido={ido} key={index} />)
          ) : (
            <Box className="alertbox">
              <Box className="alertinside">
                <Typography className="alertinfo">
                  <FaExclamationTriangle />
                  <>No Past IDO Available</>
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
