const nftRarities = (id: number) => {
  switch (id) {
    case 0:
      return {
        name: 'Common',
        discount: 1,
        percentage: '81%',
        lockedBoost: 1,
      };
    case 1:
      return {
        name: 'Uncommon',
        discount: 2,
        percentage: '9.5%',
        lockedBoost: 10,
      };
    case 2:
      return {
        name: 'Rare',
        discount: 3,
        percentage: '5%',
        lockedBoost: 20,
      };
    case 3:
      return {
        name: 'Mitic',
        discount: 4,
        percentage: '3%',
        lockedBoost: 30,
      };
    case 4:
      return {
        name: 'Epic',
        discount: 5,
        percentage: '1%',
        lockedBoost: 40,
      };
    case 5:
      return {
        name: 'Legendary',
        discount: 10,
        percentage: '0.5%',
        lockedBoost: 50,
      };
    default:
      return null;
  }
};

export default nftRarities;
