/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';

import type { StakingData } from '../../data/StakingPoolData';
import StakingPoolHelper from '../../helpers/StakingPoolHelper';
import { useWeb3Context } from '../../hooks';
import { useAsyncFnWithNotistake } from '../../hooks/use-notistake';
import useApi, { useAsyncRender, useDataApi } from '../../hooks/useApi';
import { convertFromWei } from '../../utils/convert-wei';
import GetTokenPrice, { getTokenPrice } from '../../utils/get-bork-price';
import { getTokenData } from '../../utils/token-data';
import { numAbbr } from '../../utils/unit-convert-utils';
import ConnectMenu from '../connect-button';
import NotistakeLoaderButton from '../NotistakeLoaderButton';
import StakePopUpModel from '../tokenlaunch/StakingPoolPopUpModel';

export default function PoolRow(props: { farm: StakingData }) {
  const stakePopupRef = useRef<any>();
  const { provider, address } = useWeb3Context();
  const [updateRow, setUpdateRow] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [rewardPrice, setRewardPrice] = React.useState(0);
  const withNotistake = useAsyncFnWithNotistake();

  const {
    farm: { address: contractAddress, endTimeStamp, startTimeStamp },
  } = props;

  useEffect(() => {
    const getPrice = async () => {
      let price;
      const priceBork = await GetTokenPrice(provider);
      setTokenPrice(priceBork);
      if (props.farm.rewardToken !== '') {
        price = (await getTokenData(props.farm.rewardToken)).price;
      } else if (props.farm.LPwithEthw !== '') {
        price = await getTokenPrice(props.farm.LPwithEthw, provider);
      }
      setRewardPrice(price);
    };
    getPrice();
  }, [provider]);

  // contract will be generated again when value of updateRow is changed
  const contract = useMemo(() => new StakingPoolHelper(contractAddress, provider), [contractAddress, provider, updateRow]);

  const liquidityAPI = useAsyncRender(() => contract?.lpTokenAmount(props.farm.pid, props.farm.tokenAddress, provider), [contract]);

  const { data: userStaked, render: userStakedAmountAPI } = useDataApi(() => contract?.userStakedAmount(address), [contract, address]);

  const rewardDebtAPI = useAsyncRender(() => contract?.rewardDebt(address), [contract, address]);

  const getTokenDataCall = useCallback(() => getTokenData(props.farm.stakeToken), [props]);
  const { data: token } = useApi(getTokenDataCall);

  const aprAPI = useAsyncRender(() => contract?.apr(props.farm), [contract, address]);
  const endInAPI = useAsyncRender(() => contract?.endInTimeStamp(), [contract, address]);
  const startinAPI = useAsyncRender(() => contract?.startInTimeStamp(), [contract, address]);

  const onStake = async (amount: number) => {
    const contract = new StakingPoolHelper(contractAddress, provider.getSigner());
    await withNotistake('Staking', async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const tx = await contract.stake(amount);
      setUpdateRow((prev) => prev + 1);
    });
  };

  const onUnstake = async (amount: number, msg: string = 'Unstaking') => {
    const contract = new StakingPoolHelper(contractAddress, provider.getSigner());
    await withNotistake(msg, async () => {
      const tx = await contract.unstake(amount);
      await tx.wait();
      setUpdateRow((prev) => prev + 1);
    });
  };

  // const openStakePopup = () => {
  //   if (stakePopupRef.current) {
  //     stakePopupRef.current?.openPopup();
  //   }
  // };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Box className="frax_bx">
          {/* <Box component="img" className="lock_img_table" src="/img/locked_farm.svg" /> */}
          {/* <Box
            component="img"
            height={16}
            width={10}
            borderRadius={100}
            src={props.farm.image ? props.farm.image : 'img/locked_farm.svg'}
            alt=""
          /> */}
          <Box
            component="img"
            height={50}
            width={50}
            borderRadius={100}
            src={props.farm.image ? props.farm.image : 'img/frax_ic.svg'}
            alt=""
          />
          <Box>
            <Typography>Earn {props.farm.name}</Typography>
            <Typography className="subtitle">Stake {props.farm.stakeToken}</Typography>
          </Box>
        </Box>
      </TableCell>
      {/* <TableCell align="center">
        {aprAPI(
          <Skeleton variant="rectangular" height={34} width={48} />,
          (aprValue) => (
            <Typography className="c_primary">{numAbbr(aprValue)}%</Typography>
          ),
          (err) => (
            <Typography className="c_primary">Not Started</Typography>
          )
        )}
      </TableCell> */}
      <TableCell align="center">
        {startinAPI(<></>, (startDate: any) => (
          <>
            <Typography className="c_primary">
              {startTimeStamp > Date.now() ? 'Not Yet Started' : endTimeStamp < Date.now() ? 'Ended' : <Countdown date={endTimeStamp} />}
            </Typography>
            {/* {endInAPI(
              <Skeleton variant="rectangular" height={34} width={48} />,
              (data) => (
                <Typography className="c_primary">
                  {startDate === 0 ? 'Not Yet Started' : data < Date.now() ? 'Ended' : <Countdown date={data} />}
                </Typography>
              ),
              (err) => (
                <Typography>Error...</Typography>
              )
            )} */}
          </>
        ))}
      </TableCell>
      <TableCell align="center">
        {liquidityAPI(
          <Skeleton variant="rectangular" height={34} width={48} />,
          (data) => (
            <Typography>
              {numAbbr(+convertFromWei(data.toString()), false)} <span className="lght_txt">{props.farm.stakeToken}</span>{' '}
            </Typography>
          ),
          (err) => (
            <Typography>Error...</Typography>
          )
        )}
      </TableCell>
      <TableCell align="center">
        <Box className="twice_txt">
          {userStakedAmountAPI(
            <Skeleton variant="rectangular" height={34} width={48} />,
            (data) => (
              <Typography>{numAbbr(convertFromWei(data.toString()))}</Typography>
            ),
            (err) => (
              <Typography>Error...</Typography>
            )
          )}
          {userStakedAmountAPI(
            <Skeleton variant="rectangular" height={34} width={48} />,
            (data) => (
              props.farm.stakeToken === "RDM" ? 
              <Typography className="lght_txt">${numAbbr(+convertFromWei(data.toString()) * (0))}</Typography>
              :
              <Typography className="lght_txt">${numAbbr(+convertFromWei(data.toString()) * (tokenPrice || 0))}</Typography>
            ),
            (err) => (
              <Typography>Error...</Typography>
            )
          )}
        </Box>
      </TableCell>

      <TableCell align="center">
        <Box className="twice_txt">
          {rewardDebtAPI(
            <Skeleton variant="rectangular" height={34} width={48} />,
            (rewardDebt) => (
              <Typography>{numAbbr(convertFromWei(rewardDebt.toString(), props.farm.decimalsreward))}</Typography>
            ),
            (err) => (
              <Typography>Error...</Typography>
            )
          )}
          {rewardDebtAPI(
            <Skeleton variant="rectangular" height={34} width={48} />,
            (rewardDebt) => (
              props.farm.rewardToken === "UNIA" ? 
              <Typography className="lght_txt">
                ${numAbbr(+convertFromWei(rewardDebt.toString(), props.farm.decimalsreward) * (tokenPrice || 0))}
              </Typography>
              :
              <Typography className="lght_txt">
              ${numAbbr(+convertFromWei(rewardDebt.toString(), props.farm.decimalsreward) * (rewardPrice || 0))}
            </Typography>
            ),
            (err) => (
              <Typography>Error...</Typography>
            )
          )}
        </Box>
        {/* <Box className="twice_txt">
            {rewardPerDayAPI(
              <Skeleton variant="rectangular" height={34} width={48} />,
              (data) => (
                <Typography>${toFixed6(data * (token?.price || 0))}</Typography>
              ),
              (err) => (
                <Typography>Error...</Typography>
              )
            )}
          </Box> */}
      </TableCell>

      <TableCell align="center" sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        {/* <Button
            disabled={isUserInfoLoading || userStaked === 0}
            className='unstak_btn'
          >
            Unstake
          </Button> */}
        {address ? (
          <>
            <StakePopUpModel
              ref={stakePopupRef}
              tokenAddress={props.farm.tokenAddress}
              farmName={props.farm.name}
              farmImage={props.farm.image}
              stakeName={props.farm.stakeName}
              stakeImage={props.farm.stakeImage}
              userStaked={userStaked}
              onStake={onStake}
              onUnstake={onUnstake}
              contractAddress={contractAddress}
              boosterTokenAddress={props.farm.boosterTokenAddress}
              tokenPrice={token?.price || 0}
              rewardToken={props.farm.rewardToken}
              aprAPI={aprAPI}
              farmData={props.farm}
              endInAPI={endInAPI}
            />
            <NotistakeLoaderButton className="stak_btn" onClick={() => +userStaked > 0 && onUnstake(0, 'Harvesting')}>
              Harvest
            </NotistakeLoaderButton>
          </>
        ) : (
          <ConnectMenu />
        )}
      </TableCell>
    </TableRow>
  );
}
