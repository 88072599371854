import { Box, Skeleton, Typography } from '@mui/material';
import Countdown from 'react-countdown';
import { numAbbr } from '../../utils/unit-convert-utils';

export default function UserData({ balance, stakedBalance, unlockTime, boosterRate }) {
  return (
    <Box className="roung_bx_tab">
      <Box className="round_up_bx">
        <Typography component="h6">YOUR BALANCE</Typography>
        <Typography component="h5">
          {+balance ? numAbbr(balance) + ' UNIW' : <Skeleton variant="rectangular" height={30} width={120} />}
        </Typography>
      </Box>
      <Box className="round_up_bx">
        <Typography component="h6">YOUR STAKED BALANCE</Typography>
        <Typography component="h5">{numAbbr(+stakedBalance + boosterRate) + ' UNIW'}</Typography>
      </Box>
      <Box className="round_up_bx">
        <Typography component="h6">LOCKING PERIOD</Typography>
        <Typography component="h5">{unlockTime && <Countdown date={unlockTime * 1000} />}</Typography>
      </Box>
    </Box>
  );
}
