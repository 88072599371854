export interface NewsDataInterface {
  id: number;

  desc: string;
  imagePath: string;
  link: string;
}

export const NewsData: NewsDataInterface[] = [
  {
    id: 1,
    desc: 'UNIW will be used as deposit collateral in order to borrow several assets on PaperDAO.',
    imagePath: 'https://farm.uniwdex.com/img/paper_mony_ic.png',
    link: 'https://paperdao.money',
  },
  {
    id: 1,
    desc: 'Algorithmic stablecoin $USDW is arriving soon on EthereumPoW.',
    imagePath: 'https://app.paperdao.money/static/media/usw.eb18e855.png',
    link: '#',
  },
];
