const ERR_MSG = 'Data Came after new Iteration called';

export default function getApiCanceller() {
  let status = { cancelled: false };
  let verifier = <T>(data: T): T => {
    if (!status.cancelled) {
      return data;
    } else {
      return Promise.reject(ERR_MSG) as T;
    }
  };
  let errorHandler = (e) => {
    if (e !== ERR_MSG) {
      console.error(e);
    }
  };
  return {
    status,
    cancel: () => {
      status.cancelled = true;
    },

    verifier,
    verify: <T>(promise: Promise<T>) => promise.then(verifier),
    errorHandler,
  };
}
