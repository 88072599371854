export function toFixed4(value: number) {
  return +value.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
}

export function toFixed2(value: number) {
  return +value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
}

export function toFixed6(value: number) {
  return +value.toString().match(/^-?\d+(?:\.\d{0,6})?/)[0];
}

export function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}