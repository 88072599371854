/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import { Networks } from '../constants';
import { useWeb3Context } from '../hooks';
import { useFooterData } from '../utils/getFooterData';
import { numAbbr } from '../utils/unit-convert-utils';

export default function Footer() {
  const { burnedToken, marketCap, maxSupply, totalSupply } = useFooterData();
  const { chainID } = useWeb3Context();

  return (
    <>
      <Box className="as_footer">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Box className="cx_pad_box">
              <Box className="tp_cntnt">
                <Box className="lft_bx">
                  <Box
                    component="img"
                    src={chainID === Networks.ETHW ? '/img/logo.png' : '/img/logoARB.png'}
                    alt=""
                    className="cronosports_ftr_ic"
                  />
                </Box>
                {chainID === Networks.ETHW ? (
                  <Box
                    className="rgt_bx"
                    component="a"
                    href="https://app.uniwdex.com/#/swap?outputCurrency=0x2a0cf46ECaaEAD92487577e9b737Ec63B0208a33"
                    target="_self"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button className="def_yylw_btn">Buy UNIW</Button>
                  </Box>
                ) : (
                  <Box className="rgt_bx" component="a" href="" target="_self" style={{ textDecoration: 'none' }}>
                    <Button className="def_yylw_btn">Buy UNIA</Button>
                  </Box>
                )}
              </Box>
              <Box className="bttm_cntnt">
                <Box>
                  <Box className="lft_side">
                    <Typography>Max supply</Typography>
                    <Typography>:</Typography>
                  </Box>
                  <Box className="lft_side">
                    <Typography>Circulating supply</Typography>
                    <Typography>:</Typography>
                  </Box>
                  <Box className="lft_side">
                    <Typography>Sell Tax</Typography>
                    <Typography>:</Typography>
                  </Box>
                  <Box className="lft_side">
                    <Typography>Total Burned</Typography>
                    <Typography>:</Typography>
                  </Box>
                  <Box className="lft_side">
                    <Typography>Market Cap</Typography>
                    <Typography>:</Typography>
                  </Box>
                </Box>
                <Box className="rght_side">
                  <Typography component="h4">{numAbbr(maxSupply)}</Typography>
                  <Typography component="h4">
                    {numAbbr(totalSupply - (burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0))}
                  </Typography>
                  <Typography component="h4">3%</Typography>
                  <Typography component="h4">
                    {numAbbr(burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0)}
                  </Typography>
                  <Typography component="h4">${numAbbr(marketCap)}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className="about_bx">
              <Accordion className="ftr_acc">
                <AccordionSummary>
                  <Typography>About</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <Box component="a" href="https://uniwdex.com">
                        Landing Page
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://docs.uniwswap.com">
                        Documents
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://forms.gle/uZeiowKVXQSzE7JY7" target="_blank">
                        Bug Bounty
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://farm.uniwdex.com/tos" target="_blank">
                        Terms and Conditions
                      </Box>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className="about_bx">
              <Accordion className="ftr_acc">
                <AccordionSummary>
                  <Typography>Products</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <Box component="a" href="https://uniwpunks.com">
                        UniWpunks
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://uniwgrant.com">
                        UniWgrants
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://uniwscan.com">
                        UniWscan
                      </Box>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className="about_bx">
              <Accordion className="ftr_acc">
                <AccordionSummary>
                  <Typography>Social</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <Box component="a" href="https://twitter.com/uniwswap">
                        Twitter
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://t.me/uniwswap">
                        Telegram
                      </Box>
                    </li>
                    <li>
                      <Box component="a" href="https://medium.com/uniwswap" target="_blank">
                        Medium
                      </Box>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className="cmmnty_box">
              <Typography component="h4">Partnership</Typography>
              <Box className="icon_bx">
                <a href="https://www.geckoterminal.com/" target="_blank" rel="noreferrer">
                  <img src="./img/partner/geckoterminal_dark.png" width={'200px'} alt="" />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <a
        className="audit-container large visible"
        href="https://github.com/Coinsult/solidity/blob/main/BorkMasterChefAudit.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <span className="txt">Audited by</span>
        <img className="logo" src="/img/audit-logo.svg" height={32} width={32} alt="logo" />

        <div className="mini-tag pending">COMPLETED</div>
      </a> */}
    </>
  );
}
