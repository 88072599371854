import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useWeb3Context } from '../hooks';
import { BigNumber, ethers } from 'ethers';
import { BoosterToken, ENS_ABI, ENS_PUBLIC_RESOLVER_ABI, platformTokenToken, ZbetMasterChef } from '../abi';
import { getAddresses, Networks } from '../constants';
import { numAbbr } from '../utils/unit-convert-utils';
import { convertFromWei } from '../utils/convert-wei';
import getApiCanceller from '../utils/ApiCanceller';
import { useFooterData } from '../utils/getFooterData';
import ZbetMasterChefHelper from '../helpers/ZbetMasterChefHelper';
import { TokenLaunchPageData } from '../data/FarmData';
import { NewsData } from '../data/NewsData';
import { IDOData } from '../data/IdoData';
import { toFixed } from '../utils/tofixed4';
import { useAsyncRender } from '../hooks/useApi';
import getTVL from '../utils/getTVL';
import { StakingPoolData } from '../data/StakingPoolData';
import KOFHelper from '../helpers/kofHelper';
import useRefresh from '../hooks/useRefresh';
import Countdown from 'react-countdown';
import getFarmPrice from '../helpers/getFarmPrice';
import ConnectMenu from '../component/connect-button';
import { isAddress } from '../utils/isAddress';
import { namehash } from 'ethers/lib/utils';
import useDebounce from '../hooks/useDebounce';
import LockedFarmHelper from '../helpers/LockedFarmHelper';

export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { address, chainID, provider } = useWeb3Context();
  const [balance, setBalance] = useState('0');
  const [totalRewardUNIW, setTotalRewardUNIW] = useState(0);
  const [stakedUNIW, setStakedUNIW] = useState(0);
  const [tokenPerDay, setTokenPerDay] = useState(0);
  const [kofBalance, setkofBalance] = useState(0);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [imagePath, setImagePath] = useState('/img/mint-img.png');
  const [maxAPR, setMaxAPR] = useState(0);
  const [totalAPR, setTotalAPR] = useState(0);
  const [ensname, setEnsName] = useState('');
  const [stakedFarmCount, setStakedFarmCount] = useState(0);
  const { platformToken, masterchef, KOF_ADDRESS, boosterTokenAddress, ENS_ADDRESS } = useMemo(() => getAddresses(chainID), [chainID]);
  const { burnedToken, marketCap, maxSupply: dmarketCap, price: tokenPrice, remainingSupply, totalSupply } = useFooterData();
  const tvlAPI = useAsyncRender(() => getTVL(provider, chainID).then((tvlPrice) => ({ tvl: numAbbr(tvlPrice) })), [provider]);
  const boosterTokenContract = new ethers.Contract(boosterTokenAddress, BoosterToken, provider);
  const tokenName = chainID === Networks.ETHW ? 'UNIW' : 'UNIA';

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    if (address) {
      const platformTokenContract = new ethers.Contract(platformToken, platformTokenToken, provider);
      verify(platformTokenContract.balanceOf(KOF_ADDRESS)).then((bal) => {
        setkofBalance(Number(bal) / 1e18);
      }, errorHandler);
      verify(platformTokenContract.balanceOf(address)).then((bal: BigNumber) => setBalance(convertFromWei(bal.toString())), errorHandler);

      verify(boosterTokenContract.balanceOf(address)).then((bal: BigNumber) => {
        setTotalNFTs(+bal.toString());
        if (+convertFromWei(bal.toString()) > 0) {
          boosterTokenContract.tokenOfOwnerByIndex(address, 0).then((token) => {
            const id = Number(token);
            setImagePath('https://uniwpunk-nfts.s3.amazonaws.com/' + id + '.png');
          });
        }
      }, errorHandler);
    }
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    const { cancel, verify, errorHandler } = getApiCanceller();
    const contract = new ethers.Contract(masterchef, ZbetMasterChef, provider);
    verify(contract.tokenPerBlock())
      .then((res: BigNumber) => convertFromWei(res.toString()))
      .then((tokenPerBlock) => {
        const blocksPerDay = (60 * 60 * 24) / 13;
        const borkPerDay = +tokenPerBlock * blocksPerDay;
        setTokenPerDay(borkPerDay);
      }, errorHandler);
    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketCap, dmarketCap, provider, remainingSupply]);

  const contract = useMemo(() => new ZbetMasterChefHelper(masterchef, provider), [masterchef, provider]);

  useEffect(() => {
    const farmdata = TokenLaunchPageData();
    if (masterchef) {
      (() => {
        for (let i = 0; i < farmdata.length; i++) {
          const farm = farmdata[i];

          // eslint-disable-next-line no-loop-func

          if (farm.isLocked) {
            const lockedFarmcontract = new LockedFarmHelper(farm.lockedFarmAddress, provider.getSigner());

            lockedFarmcontract?.pendingReward(address).then((rewardDebt) => {
              setTotalRewardUNIW((prev) => prev + +convertFromWei(rewardDebt.toString()));
            });
            lockedFarmcontract?.userStakedAmount(address).then((userStaked) => {
              console.log(Number(userStaked));
              getFarmPrice(farm, provider).then((price) => {
                setStakedUNIW((prev) => prev + +convertFromWei(userStaked.toString()) * price);
              });
              if (Number(userStaked) > 0) {
                setStakedFarmCount((prev) => +prev + 1);
              }
              contract?.apr(address, farm).then((aprValueMain) => {
                setMaxAPR((prev) => (prev < aprValueMain ? aprValueMain : prev));
                if (Number(userStaked) > 0) {
                  setTotalAPR((prev) => prev + aprValueMain);
                }
              });
            });
          } else {
            contract?.pendingToken(farm.pid, address).then((rewardDebt) => {
              setTotalRewardUNIW((prev) => prev + +convertFromWei(rewardDebt.toString()));
            });

            contract?.userStakedAmount(farm.pid, address).then((userStaked) => {
              getFarmPrice(farm, provider).then((price) => {
                setStakedUNIW((prev) => prev + +convertFromWei(userStaked.toString()) * price);
              });

              if (Number(userStaked) > 0) {
                setStakedFarmCount((prev) => +prev + 1);
              }

              contract?.apr(address, farm).then((aprValueMain) => {
                setMaxAPR((prev) => (prev < aprValueMain ? aprValueMain : prev));
                if (Number(userStaked) > 0) {
                  setTotalAPR((prev) => prev + aprValueMain);
                }
              });
            });
          }
        }
      })();
    }
    setTotalRewardUNIW(0);
    setTotalAPR(0);
    setStakedFarmCount(0);
  }, [address, masterchef, provider]);

  const harvestAll = async () => {
    for (const farm of TokenLaunchPageData()) {
      try {
        const contract = new ZbetMasterChefHelper(farm.address, provider.getSigner());
        // Note: Remove following await to make transactions work parallelly
        const tx = await contract.unstake(farm.pid, 0);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const debouncedAddress = useDebounce(address, 200);
  const ensNodeArgument = useMemo(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) return [undefined];
    try {
      return debouncedAddress ? namehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`) : undefined;
    } catch (error) {
      return undefined;
    }
  }, [debouncedAddress]);

  useEffect(() => {
    (async () => {
      if (debouncedAddress && chainID === Networks.ETHW) {
        try {
          const registrarContract = new ethers.Contract(ENS_ADDRESS, ENS_ABI, provider);
          const address = await registrarContract.resolver(ensNodeArgument);
          const ENSResolverContract = new ethers.Contract(address, ENS_PUBLIC_RESOLVER_ABI, provider);
          const name = await ENSResolverContract.name(ensNodeArgument);
          setEnsName(name);
        } catch (e) {}
      }
    })();
  }, [address, debouncedAddress, ENS_ADDRESS, provider, ensNodeArgument]);

  // ido data
  const [endTimestamp, setEndTimestamp] = useState(0);
  const [endTimestampClaim, setEndTimestampClaim] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const ido = useMemo(() => IDOData().find((ido) => ido.isActive), [IDOData]);

  useEffect(() => {
    const status = { cancelled: false };

    if (ido) {
      (async () => {
        // const currBlock = await provider.getBlockNumber();
        // let startTimestampVal;
        // if (currBlock < ido.startBlock) {
        //   const diff = ido.startBlock - currBlock;
        //   startTimestampVal = Date.now() + diff * 13 * 1000;
        // } else if (currBlock >= ido.startBlock) {
        //   const blockinfo = await provider.getBlock(ido.startBlock);
        //   startTimestampVal = blockinfo?.timestamp * 1000;
        // }

        if (status.cancelled) return;
        setStartTimestamp(ido.startTimestamp);
      })();
    }

    return () => {
      status.cancelled = true;
    };
  }, [address, ido, provider]);

  useEffect(() => {
    const status = { cancelled: false };
    if (ido) {
      (async () => {
        // const currBlock = await provider.getBlockNumber();
        // let endTimestampVal;

        // if (currBlock < ido.endBlock) {
        //   const diff = ido.endBlock - currBlock;
        //   endTimestampVal = Date.now() + diff * 13 * 1000;
        // } else if (currBlock >= ido.endBlock) {
        //   const blockinfo = await provider.getBlock(ido.endBlock);
        //   endTimestampVal = blockinfo?.timestamp * 1000;
        // }

        if (status.cancelled) return;
        setEndTimestamp(ido.endTimestamp);
      })();
    }

    return () => {
      status.cancelled = true;
    };
  }, [address, ido, provider]);

  useEffect(() => {
    const status = { cancelled: false };
    if (ido) {
      (async () => {
        // const currBlock = await provider.getBlockNumber();
        // let endTimestampCalimVal;
        // if (currBlock < ido.endBlock + ido.harvestBlock) {
        //   const diff = ido.endBlock - currBlock + ido.harvestBlock;
        //   endTimestampCalimVal = Date.now() + diff * 13 * 1000;
        // } else if (currBlock >= ido.endBlock + ido.harvestBlock) {
        //   const blockinfo = await provider.getBlock(ido.endBlock + ido.harvestBlock);
        //   endTimestampCalimVal = blockinfo?.timestamp * 1000;
        // }

        if (status.cancelled) return;
        setEndTimestampClaim(ido.endTimestamp + ido.harvestBlock);
      })();
    }
    return () => {
      status.cancelled = true;
    };
  }, [address, ido, provider]);

  //pool data

  const getFilterdData = async () => {
    let data = StakingPoolData().filter(({ ended }) => !ended);

    return data;
  };

  const rowsRender = useAsyncRender(getFilterdData, []);

  // king data
  const [nextStartTime, setnextStartTime] = useState(null);
  const [endOfAuction, setendOfAuction] = useState(null);
  const [hasWinner, sethasWinner] = useState(null);
  const [lastBidTime, setlastBidTime] = useState(null);
  const { ultraRefresh } = useRefresh();
  const isWaitingForNextGame = nextStartTime * 1000 > Date.now();
  const kofContract = React.useMemo(() => new KOFHelper(KOF_ADDRESS, provider.getSigner()), [KOF_ADDRESS, provider]);
  const isRunning = lastBidTime !== 0;

  useEffect(() => {
    const status = { cancelled: false };
    /* 
      In Case when new refresh call has started and current call is not yet completed
      Value can show older because of late update
      Following syntax resolves that issue
      if (status.cancelled) return;
    */
    if (chainID === Networks.ETHW) {
      Promise.all([kofContract.lastBidTime(), kofContract._endDelay()]).then(([lastBidTimeVal, _endDelayVal]) => {
        if (status.cancelled) return;
        setlastBidTime(lastBidTimeVal);
        setendOfAuction((lastBidTimeVal + _endDelayVal) * 1000);
      });

      kofContract.nextStartTime().then((val) => {
        if (status.cancelled) return;
        setnextStartTime(val);
      });

      kofContract.hasWinner().then((val) => {
        if (status.cancelled) return;
        sethasWinner(val);
      });
    }

    return () => {
      status.cancelled = true;
    };
  }, [KOF_ADDRESS, address, kofContract, ultraRefresh, provider]);

  return (
    <Box className="Dashboardaa_min_bx">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6}>
          <Box className="dashboard_bx">
            <Box className="price_tracker ">
              <Typography component="h2">Dashboard</Typography>
              <div className="pricetag">
                <img src={'../img/tokens/UNIW.png'} alt="" style={{ height: '20px', width: '20px', marginRight: '8px' }} />
                <Typography component="h4">
                  {' '}
                  {tokenPrice ? toFixed(tokenPrice, 2) : <Skeleton variant="rectangular" width={30} />}
                </Typography>
              </div>
            </Box>
            <Typography>The biggest DeFi Ecosystem on {chainID === Networks.ETHW ? 'Ethereum PoW' : 'Arbitrum'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className="dashboard_bx dashboard_bx_right">
            <img src={imagePath} alt="" style={{ height: '80px', width: '80px' }} />
            {address ? (
              <Box className="copy_btn_p_box">
                <Typography>{ensname !== '' ? ensname : address}</Typography>
                <Box className="ens_column">
                  <Button
                    onClick={() => {
                      window.navigator.clipboard.writeText(`${address}`);
                    }}
                  >
                    COPY
                  </Button>
                  {+chainID === Networks.ETHW && (
                    <Box component="a" href="https://uniwens.com" target="_blank" rel="noreferrer" className="ens_link">
                      Set ENS nickname ➤
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <ConnectMenu />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className="farms_staking_bx">
            <Box className="farms_staking">
              <Typography component="h3">Farms & Staking</Typography>
              <Box component="a" rel="noreferrer" href="/farm" className="ens_link">
                Explore all farms ➤
              </Box>
            </Box>
            <Box className="farms_staking_iner_bx harvish_btn_def_v2">
              <Box className="seed_harvest_bx">
                <Typography component="h2">{tokenName} TO HARVEST</Typography>
                <Typography component="h3">{numAbbr(totalRewardUNIW)}</Typography>
                <Typography>~${numAbbr(+totalRewardUNIW * tokenPrice)}</Typography>
              </Box>
              <Box className="seed_harvest_bx">
                <Typography component="h2">{tokenName} IN WALLET</Typography>
                <Typography component="h3">{numAbbr(balance)}</Typography>
                <Typography>~${numAbbr(+balance * tokenPrice)}</Typography>
              </Box>
            </Box>
            <Box className="farms_staking_iner_bx harvish_btn_def_v2">
              <Box className="seed_harvest_bx">
                <Typography component="h2">Staked in Farms</Typography>
                <Typography component="h3">$ {numAbbr(+stakedUNIW)}</Typography>
                <Typography>&nbsp;</Typography>
              </Box>
              <Box className="seed_harvest_bx">
                <Typography component="h2">Average APR</Typography>
                <Typography component="h3">
                  {stakedFarmCount === 0 ? (
                    0
                  ) : totalAPR ? (
                    numAbbr(totalAPR / stakedFarmCount) + '%'
                  ) : (
                    <Skeleton variant="rectangular" width={20} />
                  )}
                </Typography>
              </Box>
            </Box>
            <Box className="harvish_btn">
              <Button disabled={totalRewardUNIW === 0} className="harvish_btn_def" onClick={harvestAll}>
                HARVEST ALL
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className="farms_staking_bx farms_staking_bxv2">
            <Box className="farms_staking">
              <Typography component="h3">Your Lottery Winnings (Coming Soon)</Typography>
            </Box>
            <Box className="farms_staking_iner_bx harvish_btn_def_v2">
              <Box className="seed_harvest_bx">
                <Typography component="h2">{tokenName} TO COLLECT</Typography>
                <Typography component="h3">0.000</Typography>
              </Box>
              <Box className="seed_harvest_bx">
                <Typography component="h2">TOTAL JACKPOT THIS ROUND</Typography>
                <Typography component="h3">0.000</Typography>
              </Box>
            </Box>
            <Box className="farms_staking_iner_bx harvish_btn_def_v2">
              <Box className="seed_harvest_bx">
                <Typography component="h2">{tokenName} TO COLLECT</Typography>
                <Typography component="h3">0.000</Typography>
              </Box>
              <Box className="seed_harvest_bx">
                <Typography component="h2">TOTAL JACKPOT THIS ROUND</Typography>
                <Typography component="h3">0.000</Typography>
              </Box>
            </Box>
            <Box className="buy_tickts_two">
              <Button disabled className="harvish_btn_def">
                COLLECT WINNINGS
              </Button>
              <Button disabled className="harvish_btn_def">
                BUY TICKETS
              </Button>
            </Box>
          </Box>
        </Grid>
        {chainID === Networks.ETHW && (
          <>
            <Grid item xs={12} md={12} lg={6}>
              <Box className="value_box_prnt">
                <Box
                  className="value_box uniwbridge value_box_prnt_v2mrg"
                  component="a"
                  href="https://bridge.uniwswap.com/"
                  target="_blank"
                >
                  <Typography component="h2">Bridge on</Typography>
                  <Typography component="h3">UNIWBRIDGE</Typography>
                </Box>
                <Box className="value_box uniwscan value_box_prnt_v2" component="a" href="https://uniwscan.com" target="_blank">
                  <Typography component="h2">Analytics on</Typography>
                  <Typography component="h3">UNIWSCAN</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box className="value_box_prnt">
                <Box className="value_box uniwmarket value_box_prnt_v2mrg" component="a" target="_blank">
                  <Typography component="h2">Trade NFT on</Typography>
                  <Typography component="h3">UNIWMARKET</Typography>
                </Box>
                <Box className="value_box uniwstable value_box_prnt_v2 disabled" component="a" target="_blank">
                  <Typography component="h2">Swap Stable</Typography>
                  <Typography component="h3">UNIWSTABLE</Typography>
                </Box>
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} lg={IDOData().length > 0 ? 6 : 12}>
          <Box className="unv_Stats_bx">
            <Box className="unv_Stats_bx_inrer">
              <Box className="king_hours_img_box">
                <img src="/img/uniwstats_ico.png" alt="" />
                <Typography component="h3">{tokenName} Stats</Typography>
              </Box>
              <Box className="Stats_iner_bx Stats_iner_bx_v2">
                <Box className="seed_harvest_bx">
                  <Typography component="h2">TOTAL MINTED</Typography>
                  <Typography component="h3">{numAbbr(totalSupply)}</Typography>
                </Box>
                <Box className="seed_harvest_bx">
                  <Typography component="h2">TOTAL BURNED</Typography>
                  <Typography component="h3">
                    {numAbbr(burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0)}
                  </Typography>
                </Box>
              </Box>
              <Box className="Stats_iner_bx">
                <Box className="seed_harvest_bx">
                  <Typography component="h2">CIRCULATING SUPPLY</Typography>
                  <Typography component="h3">
                    {numAbbr(totalSupply - (burnedToken ? (+chainID === Networks.ETHW ? +burnedToken + 614646 : burnedToken) : 0))}
                  </Typography>
                </Box>
                <Box className="seed_harvest_bx">
                  <Typography component="h2">MARKET CAP</Typography>
                  <Typography component="h3">${numAbbr(marketCap)}</Typography>
                </Box>
              </Box>
              <Box className="Stats_iner_bx Stats_iner_bx_3">
                <Box className="seed_harvest_bx">
                  <Typography component="h2">MINTED {tokenName}/DAY</Typography>
                  <Typography component="h3">{numAbbr(tokenPerDay)}</Typography>
                </Box>
                <Box className="seed_harvest_bx">
                  <Typography component="h2">DAY SINCE LAUNCH</Typography>
                  <Typography component="h3">
                    {chainID === Networks.ETHW
                      ? Math.floor((Date.now() - 1664107200000) / (24 * 60 * 60 * 1000))
                      : Math.floor((Date.now() - 1672487032000) / (24 * 60 * 60 * 1000))}{' '}
                    {/* update time stamp here for arb */}
                    Days
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {IDOData().length > 0 && (
          <Grid item xs={12} md={12} lg={6}>
            <Box className="unv_Stats_bx unv_Stats_bx_v2">
              <a href={'/launchpad/' + ido.address} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                <Box className="unv_Stats_bx_inrer unv_Stats_bx_inrer_v2">
                  <Box className="king_hours_img_box">
                    <img src="/img/launchpad_ido.png" alt="" />
                    <Typography component="h3">Launchpad IDO</Typography>
                  </Box>
                  <Box className="Stats_iner_bx Stats_iner_bx_v2">
                    <Box className="seed_harvest_bx">
                      <Typography component="h2">TOKEN SYMBOL</Typography>
                      <Typography component="h3">{ido.tokenSymbol}</Typography>
                    </Box>
                    <Box className="seed_harvest_bx">
                      <Typography component="h2">IDO TOKEN SALE PRICE</Typography>
                      <Typography component="h3" className="contribution_text">
                        1 {ido.tokenName} = 0.5 $
                        {/* {toFixed(ido.raisingAmount / ido.offeringAmount, 3)} {tokenName}{' '}
                        <span> ≈ {toFixed((ido.raisingAmount / ido.offeringAmount) * tokenPrice, 3)}$</span> */}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="Stats_iner_bx">
                    <Box className="seed_harvest_bx">
                      <Typography component="h2">IDO SUPPLY</Typography>
                      <Typography component="h3">
                        {ido.idoSupply} {ido.tokenSymbol}
                      </Typography>
                    </Box>
                    <Box className="seed_harvest_bx">
                      <Typography component="h2">TOTAL SUPPLY</Typography>
                      <Typography component="h3">
                        {ido.totalSupply} {ido.tokenSymbol}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="next_ido_bx">
                  {startTimestamp && endTimestamp ? (
                    startTimestamp * 1000 > Date.now() ? (
                      <Typography>
                        Starts in <Countdown date={startTimestamp * 1000} />
                      </Typography>
                    ) : endTimestamp * 1000 > Date.now() ? (
                      <Typography>
                        Ends in <Countdown date={endTimestamp * 1000} />
                      </Typography>
                    ) : (
                      <Typography>Ended</Typography>
                    )
                  ) : (
                    <Typography>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={210} />
                    </Typography>
                  )}
                  <Box className="audit_kyc_btn">
                    <Button>AUDIT</Button>
                    <Button>KYC</Button>
                  </Box>
                </Box>
              </a>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={6}>
          <Box className="value_box_prnt">
            <Box className="value_box  value_box_prnt_v2 value_box_prnt_v2mrg">
              <Typography component="h4">TOTAL VALUE LOCKED</Typography>
              {tvlAPI(
                <Skeleton variant="rectangular" height={34} width={148} />,
                (data) => (
                  <Typography component="h5">${data.tvl}</Typography>
                ),
                (err) => (
                  <Typography>N/A</Typography>
                )
              )}
              <Typography component="h4">ACROSS ALL FARMS</Typography>
              <a href="/" className="open_in_new_black_ic">
                <img src="/img/open_in_new_black_ic.svg" alt="" />
              </a>
            </Box>
            <Box className="value_box value_box_prnt_v2">
              <Typography component="h4">EARN UP To</Typography>
              <Typography component="h5">{numAbbr(maxAPR)}% APR</Typography>
              <Typography component="h4">IN FARMS</Typography>
              <a href="/" className="open_in_new_black_ic">
                <img src="/img/open_in_new_black_ic.svg" alt="" />
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box className="value_box_prnt">
            <Box className="value_box value_box_prnt_v2 value_box_prnt_v2mrg">
              <Typography component="h4">EARN</Typography>
              <Typography component="h5">
                {rowsRender(<></>, (data) => (
                  <>{data.map((farm) => farm.rewardTokenName).join(', ')}</>
                ))}{' '}
              </Typography>
              <Typography component="h4">IN POOLS</Typography>
              <a href="/stakingPool" className="open_in_new_black_ic">
                <img src="/img/open_in_new_black_ic.svg" alt="" />
              </a>
            </Box>
            <Box className="value_box value_box_prnt_v2">
              <Typography component="h4">LOTTERY WITH</Typography>
              <Typography component="h5">COMING SOON</Typography>
              <Typography component="h4">UP FOR GRABS</Typography>
              <a href="/" className="open_in_new_black_ic">
                <img src="/img/open_in_new_black_ic.svg" alt="" />
              </a>
            </Box>
          </Box>
        </Grid>
        {chainID === Networks.ETHW && (
          <>
            <Grid item xs={12} md={12} lg={6}>
              <Box className="KingUniw_paerent_bx">
                <Box className="KingUniw_iner_bx">
                  <Box className="king_hours_img_box">
                    <img src="/img/uniwking_ico.png" alt="" />
                    <Typography component="h3">Uniw King</Typography>
                  </Box>
                  <Box className="Stats_iner_bx Stats_iner_bx_v2">
                    <Box className="seed_harvest_bx">
                      <>
                        {isWaitingForNextGame ? (
                          <>
                            <Typography component="h2">NEXT ROUND IN</Typography>

                            <div>
                              <h3 className="fire-text">
                                <Countdown date={nextStartTime * 1000} />
                              </h3>
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            {isRunning ? (
                              <div>
                                {hasWinner ? (
                                  <>
                                    <Typography component="h2">ROUND STATUS</Typography>
                                    <h3> Waiting For Claim</h3>
                                  </>
                                ) : endOfAuction ? (
                                  <h3 className="fire-text">
                                    <Countdown key={endOfAuction} date={endOfAuction} />
                                  </h3>
                                ) : (
                                  <Skeleton width={300} height={0} />
                                )}
                              </div>
                            ) : (
                              <div>
                                <>
                                  <Typography component="h2">ROUND STATUS</Typography>
                                  <h3>Waiting for Bids</h3>
                                </>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    </Box>
                    <Box className="seed_harvest_bx">
                      <Typography component="h5">TOTAL POT:</Typography>
                      <Typography>
                        {numAbbr(kofBalance * tokenPrice)} {tokenName}
                      </Typography>
                    </Box>
                  </Box>
                  <a href="/kingpage" className="open_in_new_black_ic">
                    <img src="/img/open_in_new_black_ic.svg" alt="" />
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box className="slid_prnt_box">
                <Typography className="new_text">News</Typography>
                <div>
                  <Slider {...settings}>
                    {NewsData.map((news) => (
                      <div>
                        <Box className="slid_inear_bx">
                          <img src={news.imagePath} className="slide_img" alt="" />
                          <Box className="lorem_img_bx">
                            <Typography>{news.desc.length > 90 ? news.desc.slice(0, 90) + '....' : news.desc}</Typography>
                            <a href={news.link} target="_blank" rel="noreferrer" className="open_in_new_black_ic">
                              KNOW MORE
                              <img src="/img/open_in_new_black_ic.svg" alt="" />
                            </a>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
// EARN UP TO 29.63% APR IN FARMS
