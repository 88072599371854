import { Button } from '@mui/material';
import { ethers } from 'ethers';
import { namehash } from 'ethers/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { ENS_ABI, ENS_PUBLIC_RESOLVER_ABI } from '../../abi';

import { DEFAULD_NETWORK, getAddresses } from '../../constants';
import { accountEllipsis } from '../../helpers/get-account-ellipsis';
import { useWeb3Context } from '../../hooks';
import useDebounce from '../../hooks/useDebounce';

import { isAddress } from '../../utils/isAddress';

function ConnectMenu() {
  const { connect, disconnect, connected, web3, providerChainID, checkWrongNetwork, address, provider, chainID } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const addresses = getAddresses(chainID);
  const debouncedAddress = useDebounce(address, 200);
  const [ensname, setEnsName] = useState('');

  const ensNodeArgument = useMemo(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) return [undefined];
    try {
      return debouncedAddress ? namehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`) : undefined;
    } catch (error) {
      return undefined;
    }
  }, [debouncedAddress]);

  useEffect(() => {
    (async () => {
      if (debouncedAddress) {
        try {
          const registrarContract = new ethers.Contract(addresses.ENS_ADDRESS, ENS_ABI, provider);
          const address = await registrarContract.resolver(ensNodeArgument);
          const ENSResolverContract = new ethers.Contract(address, ENS_PUBLIC_RESOLVER_ABI, provider);
          const name = await ENSResolverContract.name(ensNodeArgument);
          setEnsName(name);
        } catch (e) {}
      }
    })();
  }, [address, debouncedAddress, addresses.ENS_ADDRESS, provider, ensNodeArgument]);

  let buttonText = 'Connect Wallet';
  let clickFunc: any = connect;

  if (isConnected) {
    buttonText = ensname !== '' ? ensname : accountEllipsis(address);
    clickFunc = disconnect;
  }

  if (isConnected && providerChainID !== chainID) {
    buttonText = 'Wrong network';
    clickFunc = () => {
      checkWrongNetwork();
    };
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <>
      <Button className="def_btn_gray" onClick={clickFunc}>
        <span>{buttonText}</span>
      </Button>
    </>
  );
}

export default ConnectMenu;
