const reveneShareNFTRarities = (id: number) => {
  switch (id) {
    case 0:
      return {
        name: 'Common',
        discount: 1,
        percentage: '81%',
        boostRate: 100,
      };
    case 1:
      return {
        name: 'Uncommon',
        discount: 2,
        percentage: '9.5%',
        boostRate: 200,
      };
    case 2:
      return {
        name: 'Rare',
        discount: 3,
        percentage: '5%',
        boostRate: 400,
      };
    case 3:
      return {
        name: 'Mitic',
        discount: 4,
        percentage: '3%',
        boostRate: 500,
      };
    case 4:
      return {
        name: 'Epic',
        discount: 5,
        percentage: '1%',
        boostRate: 1000,
      };
    case 5:
      return {
        name: 'Legendary',
        discount: 10,
        percentage: '0.5%',
        boostRate: 2000,
      };
    default:
      return null;
  }
};

export default reveneShareNFTRarities;
