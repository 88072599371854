import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box, Grid } from '@mui/material';
import type { AccordionProps } from '@mui/material/Accordion';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import type { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';


export default function TOSPage() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box className="faq_page_box">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box className="gird_box_one">
            <div className='modl_hed'>
            <h1>Terms and Conditions</h1>
          </div>
          <div className='modl_body'>
            <h3 style={{ color: 'white', textAlign: 'center' }}>General Info</h3>
            <p>
              This notice provides general information about the risks
              associated with performing related operations to exchange,
              purchase and sell digital assets and should in no case be
              considered investment advice, a purchase offer or an invitation to
              purchase digital assets. Given the high risk associated with the
              digital asset market, this warning cannot explain all actual and
              potential risks, nor how those risks may arise. It is important to
              understand the risks associated with trading cryptocurrencies,
              market price fluctuations, operations of the AMM mechanism, any
              possible implications linked to operations on centralized markets
              and decentralized systems, as well as the functioning of slippage
              and all other systems and mechanisms related to the decentralized
              finance sector. The nature of the markets makes for an area where
              both profits and losses can be amplified, being able to incur
              losses even of the total of one&apos;s own funds. To avoid running
              into large losses, it is therefore imperative to closely monitor
              all your open positions for large loss risk management. Financial
              assets such as cryptocurrencies and similar are complex, extremely
              risky and highly speculative, involving a high risk to the
              invested capital due to the high volatility of the price and value
              of assets. We also note the additional associated risks with the
              processing and operations related to the buying and selling of
              digital assets, such as, but not limited to, order execution
              issues, industry-specific disruptive events, including, but not
              limited to, disruption, regulatory bans, and other malicious
              actors within cryptocurrency ecosystems.
            </p>
            <h3 style={{ color: 'white', textAlign: 'center' }}>Terms of Use</h3>
            <p>
              The user who agrees to carry out transactions related to digital
              assets is fully aware of the associated and connected risks and
              assumes full responsibility for the activities carried out.
              AND&apos; It is essential to understand and be fully aware of the
              risks associated with decentralized finance tools. It is also
              crucial that you invest funds where you can tolerate total
              devaluation of the underlying value, in the event of a total loss
              of capital invested. Cryptocurrency trading is not appropriate for
              all investors and therefore, any user who begins to carry out any
              operation on digital assets should have detailed knowledge, as
              well as an expertise in these specific products. Users are urged
              to be always fully aware of and understand the specific
              characteristics and risks related to such activities.
            </p>
            <h3 style={{ color: 'white', textAlign: 'center' }}>Forms of liability</h3>
            <p>
              By using one or more of the platform&apos;s services, the user
              assumes full responsibility for each activity carried out using
              the tools available. More specifically, using the services of the
              platform excludes the platform’s liability for: - loss of current,
              direct and/or potential earnings; - any loss, expense, cost or
              liability suffered and/or incurred by users; - any direct and/or
              indirect loss or damage (whether loss of future profits, loss of
              business or other), costs, expenses and/or claims for any event
              arising out of and/or for use of the tools available on the
              platform; - for any loss suffered by the user of the platform
              and/or third parties as a result of any order or instruction
              placed; - for any loss or damage, including, without limitation,
              any loss of profit, which may derive directly or indirectly from
              the use and/or reliance on: financial data, prices, leverage,
              margin and/or any other information that you may have via websites
              and/or other channels connected or not connected with the platform
              used; - for any loss or damage suffered by the user of the
              platform, due to errors, bugs, errors minor and/or technical
              problems that may or may occur while using the platform and tools
              made available; - for any loss suffered or suffered by the user of
              the platform due to the inaccuracy of the information, incorrect
              entry of data and/or information, incomplete or incorrect
              execution of procedures;  - for any change in any and all farms 
              or staking pools’ emission schedule from the team with/without 
              notice to meet the current UNIA market conditions, avoiding a negative price impact.
              The user who uses in any way a tool
              and/or a mechanism available on the platform, undertakes
              personally and without exception to waive any action or
              retaliation against the platform and/or its creators, for any
              result, gain, loss or damage, even if only potential, directly or
              indirectly caused and/or related to the use of tools directly or
              indirectly offered by the platform itself. The user who uses in
              any way a tool and/or a mechanism available on the platform,
              expressly and without exception warrants to hold the platform
              innocent and/or its creators against any consequences that may
              arise from the use of the tools and/or mechanisms present on the
              platform itself. The user who uses in any way a tool and/or a
              mechanism available on the platform, expressly waives and without
              any exception to advance any type of recourse or claims against
              the platform, developers, partners, third party collaborators,
              users, of any activity put in place using the tools and mechanisms
              available on the platform.
            </p>
            <h3 style={{ color: 'white', textAlign: 'center' }}>Misconduct</h3>
            <p>
              In the event of fraud, unlawful conduct, false statements
              implemented or assumed at any time during or on the occasion of
              the use of the services and tools connected or reconnectable to
              platform, and for any other behavior in violation of the law, the
              platform will implement in the appropriate locations all the
              measures deemed suitable for the protection of its own interests
              and those of third parties linked to and/or involved in the
              development of the platform.
            </p>
            <h3 style={{ color: 'white', textAlign: 'center' }}>Disclaimer</h3>
            <p>
              By accepting the following terms, the user who uses the services
              offered by the platform declares expressly to be fully aware of
              the risks associated with the activities connected to the
              cryptocurrency market. In no event may the user advance, in any
              location, any type of claim or action against the platform, its
              developers and third parties, for results and/or losses and/or
              damages due to the use of the platform, lack of understanding
              and/or awareness of the functionality and/or risks associated with
              directly related activities and services or indirectly to the
              platform and its extensions.
            </p>
          </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
